import React  from 'react';
import img1 from '../../resources/images/about1/aboutLink/dataCommunity/img5.png';
const Solutions = () => {


  return (
    <div className=' text-lg md:px-10'>
      <div className=' h-screen w-screen relative top-12 bg-fixed bg-cover '>
      <div>
                    <button onClick={Solutions} className=" text-black px-20 py-8 font-semibold ">
                        About
                    </button>
                </div>
        <img
          className='h-5/6 w-full bg-cover relative bottom-8'
          src={img1}
          alt=''
        />
        <div className='container mx-auto  relative bottom-56 md:left-24 px-4 text-black'>
          <h1 className='text-3xl font-semibold mb-5'>
            GROW with Data Solutions
          </h1>
          <h1>
            {' '}
            Data Solutions adoption with speed, predictability,
            <br /> and continuous innovation.
          </h1>
        </div>
      </div>
      <div className='my-10'>
        <h1 className='text-3xl font-semibold mb-5 px-10'>
          Discover the value of GROW with Data Solutions
        </h1>
      </div>
      <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10'>
        <div className='p-5 bg-white shadow rounded'>
          <h2 className='text-2xl font-semibold mb-3'>
            Simplifying everyday work
          </h2>
          <p>
            Speed up daily activities and know when to make decisions with
            embedded collaboration and AI capabilities while innovating faster.
            <li>
              Gain visibility and control over your entire business with
              real-time insights when making decisions
            </li>
            <li>
              Delight your employees with an intuitive, collaborative, and
              modern user interface
            </li>
            <li>
              Empower business users with low-code tools that require minimal IT
              training
            </li>
          </p>
        </div>
        <div className='p-5 bg-white shadow rounded'>
          <h2 className='text-2xl font-semibold mb-3'>Growing your business</h2>
          <p>
            Grow faster with a full featured ERP and ready-to-run industry best
            practices, while scaling sustainably with ESG transparency and
            critical metrics.
            <li>
              Move your business forward with access to a library of
              pre configured processes
            </li>
            <li>
              Accelerate your expansion by managing legal, tax, and industry
              regulations across the globe
            </li>
            <li>
              Grow faster with integration across HR, finance, supply chain, and
              manufacturing lines of business
            </li>
          </p>
        </div>
        <div className='p-5 bg-white shadow rounded'>
          <h2 className='text-2xl font-semibold mb-3'>Securing your success</h2>
          <p>
            Prepare for what’s next with predictability and confidence by
            accessing always-on updates to the latest innovations and continuous
            security updates.
            <li>
              Expedite rollout with guided tools and methodology through the
              Accelerate for GROW with Data Solutions approach
            </li>
            <li>
              Avoid surprises with an implementation that includes a predictable
              scope and time frame
            </li>
            <li>
              Build skills and improve adoption with flexible learning and
              training Watch the video
            </li>
          </p>
        </div>
      </section>
    </div>
  );
};
export default Solutions;
