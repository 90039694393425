import React from 'react';
const Analyst = () => {

    return (

        <div className=' text-lg p-16 '>
            <div className='h-16'></div>
            <div className='pb-4'>
                <button onClick={Analyst} className=" text-black  font-semibold">
                    Products & Products
                </button>
            </div>
            <div className=''>
                <div className='container mx-auto text-white pl-4 h-72 bg-cyan-500 md:rounded-br-full flex flex-col justify-center '>
                    <p className='md:text-6xl font-bold  '>
                        Analyst Coverage

                    </p>

                </div>
                <div className='my-10 text-center p-10'>

                    <p className=' text-2xl'>
                        The opinions of analysts and the estimates, forecasts, projections, predictions or expectations are the sole responsibility of the analysts concerned and does not represent opinions, forecasts, projections, predictions or expectations of Data Solutions or its management.



                    </p>
                </div>
            </div >
        </div>
    );
};
export default Analyst;