import React from 'react';
import image from '../../../resources/images/home/encryption/zp-management-software encryption 1.jpg';
import image1 from '../../../resources/images/home/encryption/use-encryption-when 2.avif';
import image2 from '../../../resources/images/home/encryption/symmetric-encryption 3.png';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';

const Encryption = () => {
  let Navigate= useNavigate()
  let encry=()=>{
    Navigate(-1)
  
  }
  let dat=()=>{
    
    Navigate("/homePage/DataProtection")
  }
  return (
    <div className='min-h-screen p-2 md:px-10'>
      <section className=' text-white py-20'>
        <div className='container mx-auto text-center'>
       
        <div className="flex justify-start items-center   text-base py-5 ">
        <div>
          <button onClick={encry} className=" text-black ">
            Home
            <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
          </button>
        </div>
        <div>
          <button className=" text-black">Encryption</button>
          
        </div>
      </div>
  
        <section className=' text-black pt-10 border-2 shadow-2xl rounded-3xl bg-yellow-50 py-5'>
        <div className='container mx-auto p-4 '>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='flex justify-center items-center text-5xl  '>
            What is encryption? How it works + types of encryption
          </div>
          <div className='h-96 bg-white border-2 shadow-2xl rounded-3xl grid justify-center items-center grid-flow-col '>
            <img src={image} alt='vision' className='h-72 min-w-96 border-2 shadow-2xl rounded-2xl'  />
            </div>
            <p className='grid justify-center items-start '>
               Data Solutions Tree
            </p>
        </div>
      </div>
      </section>
      <section className='grid justify-start items-start text-black py-10' >
          <div className=' font-bold text-3xl py-5'>
              What is encryption?
          </div>
          <p className=' grid justify-start items-start text-2xl py-10 '>
              Encryption converts sensitive information or data into a secret code to prevent unauthorized access.
              Encryption helps organizations stay compliant with regulatory privacy requirements and standards. It can also help protect  sensitive information. Every time we make a purchase online, use the bank ATM, text or call somebody, encryption keeps the transaction or communication private and highly secure. Encryption provides a layer of security so information is only accessed by the authorized users. It has become an essential part of our modern day lives. We should care about encryption as it provides privacy, security, integrity of data, and authentication. In many cases it is required for regulatory compliance.
          </p>
          <hr className='bg-gray-200 h-1 w-2/4 '></hr>
      </section>
      <h2 className='flex justify-center text-black text-4xl font-bold mb-4 px-3 capitalize'>
      When to use encryption.....?<button  onClick={dat} className="text-blue-900 underline px-2">
      more
    </button>
      </h2>
      <section className=' text-black py-10'>
            <div className='container mx-auto  '>
              <div className='grid grid-cols-1 md:grid-cols-2 '>
                
                <div className='flex justify-center items-center text-center text-xl '>
                 
                </div>
                <img src={image1} alt='vision' className=' flex justify-end items-end'  />
              </div>
            </div>
          </section>
          <section className= 'grid justify-start items-start text-black  ' >
              <div className='  flex justify-start items-start font-bold text-2xl py-5 capitalize '>
              It’s important to encrypt messages, files, and data exchanged online whenever they contain personal, sensitive, or classified information like:
              </div>
             <ol className='grid justify-center items-start gap-3 font-semibold'>
                <li>Encryption ensures only the intended recipient can access the information you shared.</li>
                <li>Entering your financial information to log in to your online bank account </li>
                <li>Sharing a confidential report with your employer</li>
              </ol>
              <p className='py-2 font-semibold'>Sending an email to your doctor </p>
          </section>
          <section className='grid justify-start items-start text-black py-10' >
          <div className=' font-bold text-5xl py-10 capitalize '>
          Symmetric encryption....
          </div>
          <img src={image2} alt='vision' className=' grid justify-center items-center relative left-10 p-20'  />
          <p className=' grid justify-start items-start text-2xl py-10 '>
          Every time we make a purchase online, use the bank ATM, text or call somebody, encryption keeps the transaction or communication private and highly secure. Encryption provides a layer of security so information is only accessed by the authorized users. It has become an essential part of our modern day lives. We should care about encryption as it provides privacy, security, integrity of data, and authentication. In many cases it is required for regulatory compliance.Every time we make a purchase online, use the bank ATM, text or call somebody, encryption keeps the transaction or communication private and highly secure. Encryption provides a layer of security so information is only accessed by the authorized users. It has become an essential part of our modern day lives. We should care about encryption as it provides privacy, security, integrity of data, and authentication. In many cases it is required for regulatory compliance.
          </p>
          
      </section>
        </div>
      </section>
    </div>
  );
};

export default Encryption;