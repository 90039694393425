import React from 'react';
import { useNavigate } from 'react-router-dom';
import image from '../../../../resources/images/home/homepage/thirdParty/thirdParty.webp';
import image1 from "../../../../resources/images/home/homepage/thirdParty/startPage.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const ThirdPartyRiskManagement = () => {
  let Navigate = useNavigate();
  let Third = () => {
    Navigate(-1);
  };
  return (
    <div className='min-h-screen'>
      <section className=' text-white py-20 p-5'>
        <div className='container mx-auto text-center'>
          <div className='flex justify-start items-center px-8  text-base py-5 '>
            <div>
              <button onClick={Third} className=' text-black '>
                Home
                <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
              </button>
            </div>
            <div>
              <button className='  text-black'>
                Third Party Risk Management
              </button>
            </div>
          </div>
          <p className='text-lg text-black grid justify-start items-start '>
            <img src={image} className=' border-2  rounded-3xl' />
          </p>
          <hr className='bg-gray-300 h-1 '></hr>
          <section className='grid justify-start items-start text-black p-8 py-5'>
            <span className=' grid justify-start items-start text-black text-xl font-medium  '>
              Services
            </span>
            <h1 className=' grid justify-start items-start text-black text-3xl  font-bold'>
              Third-Party Risk Management Managed Services
            </h1>
            <p className=' grid justify-start items-start text-gray-400 text-2xl py-2 '>
              {' '}
              An end-to-end managed service to help identify and manage risk
            </p>
            <p className=' grid justify-start items-start text-black text-lg  py-3'>
              {' '}
              Data Solution&apos;s managed service is designed to help organizations more
              efficiently manage their third-party relationships, providing
              executives with a broad view of risks and performance across the
              extended enterprise.
            </p>
            <hr className='bg-red-500 h-1 w-3/5  '></hr>
          </section>
          <section className='grid justify-start items-start text-black p-8 py-5'>
            <h1 className='grid justify-start items-start text-2xl py-5'>
              Growing risks in the extended enterprise{' '}
            </h1>
            <p>
              As organizations expand their third-party ecosystem, many are
              challenged with executing core activities that are critical to
              operations, risk profiles, and compliance posture without
              compromising the quality of data collection, evaluation, and
              mitigation measures.
            </p>
            <div className='grid justify-start items-start  text-xl text-black font-bold p-1'>
              The Extended enterprise risk management global 2020 survey of more
              than 1,000 executives at organizations around the world revealed
              that:
            </div>
          </section>
          <section>
            <table className='border border-collapse border-black w-full  text-black '>
              <tr>
                <th className='border border-black   font-normal px-4 py-2'>
                  84 percent of respondents said their organization had
                  experienced a third-party incident in the last three years
                </th>
              </tr>
              <tr>
                <th className='border border-black px-4 py-2 font-normal'>
                  72 percent of respondents are dissatisfied with extended
                  enterprise risk management technology
                </th>
              </tr>
              <tr>
                <th className='border border-black px-4 py-2 font-normal '>
                  45 percent of respondents have stepped up their investments in
                  due to tightening pressure from regulators
                </th>
              </tr>
              <tr>
                <th className='border border-black px-4 py-2 font-normal '>
                  52 percent of organizations believe that is turning into a
                  broader concept that includes contract management, performance
                  management, and financial management{' '}
                </th>
              </tr>
            </table>
          </section>

          <section className=' text-black pt-10   py-16'>
            <div className='container mx-auto p-4 '>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className=''>
                  <img
                    src={image1}
                    alt='vision'
                    className='h-52 min-w-52 px-28 '
                  />
                </div>
                <div className='grid justify-center items-center p-2'>
                  <div className='flex justify-center items-center text-xl   py-8 '>
                    Identifying third-party risks using advanced analytics and
                    artificial intelligence (AI) on data harvested from the
                    internet and proprietary databases.
                  </div>
                </div>
              </div>
            </div>
          </section>
          <p className='flex justify-center items-center text-xl text-black'>
            {' '}
            Working with a third party can introduce risk to your business. If
            they have access to sensitive data they could be a security risk, if
            they provide an essential component or service for your business
            they could introduce operational risk, and so on. Third party risk
            management enables organizations to monitor and assess the risk
            posed by third parties to identify where it exceeds the threshold
            set by the business. This allows organizations to make risk-informed
            decisions and reduce the risk posed by vendors to an acceptable
            level.
          </p>
        </div>
      </section>
    </div>
  );
};

export default ThirdPartyRiskManagement;
