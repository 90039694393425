import React from 'react';
import img1 from "../../resources/images/home/footerLinks/Events/im4.jpg"
import img2 from "../../resources/images/home/footerLinks/Events/events1.jpg"
import img3 from "../../resources/images/home/footerLinks/Events/events3.jpeg"


const Events = () => {
  

  return (

    <div className='  text-lg p-10'>
      <div className=' ' >
      <div>
          <button onClick={Events} className=" text-black p-10 font-semibold ">
            Home
          </button>
        </div>
        <img
          className='h-5/6 w-full '
          src={img1}
          alt='' />
        <div className='container mx-auto  relative bottom-72  px-4 text-white'>

          <h1 className='text-4xl font-bold mb-5'>Events with Data Solutions </h1>
          <h2 className='text-2xl '>
            Meet and connect with Data Solutions experts, partners, customers, <br/>and users through in-person or virtual events. Our event finder can <br/> help you explore upcoming and on-demand experiences.   </h2>
        </div>

      </div>
      <div className=''>
        <h1 className='text-5xl font-semibold  text-center my-10 ' >
          Events for everyone.
        </h1>
        <h4 className='text-2xl text-center '>
          Explore Data Solutions events and get insights into how the most successful companies transformed their business with Data Solutions.
        </h4>
        <h1 className='text-5xl font-semibold mb-5 text-center my-10 ' >
          Upcoming events          </h1>
        <h4 className='text-2xl text-center '>
          See highlights of upcoming Data Solutions events.
        </h4>
      </div>

      <section className='grid grid-cols-1 md:grid-cols-2  gap-8 my-10 '>
        <div className='p-5 bg-white shadow rounded'>
          <img
            src={img2}
            alt='Demo'
            className='w-full md:w-full rounded shadow-lg'
          />

        </div>

        <div className='p-5 bg-white shadow rounded'>
          <img
            src={img3}
            alt='Demo'
            className='w-full md:w-full rounded shadow-lg'
          />

        </div>

      </section>



    </div>

  );
};
export default Events;