import React from 'react';
import { useNavigate } from 'react-router-dom'
import image1 from '../../../../resources/images/home/homepage/encryptionData/symmetricAsymmetric.jpg';
import image2 from '../../../../resources/images/home/homepage/encryptionData/private.jpg';
import image from '../../../../resources/images/home/homepage/encryptionData/dataProtection.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';



const DataProtection = () => {
  let Navi= useNavigate()
  let dat=()=>{
    Navi(-1)
  
  }
  
  let enc=()=>{
    Navi(-2)
  }
    
  
  return (
    <div className='min-h-screen'>
      <section className=' text-white py-20 p-5 overflow-x-hidden'>
        <div className='container mx-auto text-center'>
        <div className="flex justify-start items-center px-8  text-base py-5 ">
        <div>
          <button onClick={enc} className=" text-black ">
            Home
            <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
          </button>
        </div>
        <div>
          <button onClick={dat}  className="text-black">Encryption 
          <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
          </button>
        </div>
        <div>
          <button   className="text-black">Encryption And Data Protection
          </button>
        </div>
      </div>
        <p className='text-lg text-black grid justify-start items-start py-5' >
          <img src={image } className=' border-2  rounded-3xl'/></p>
        <section className='grid justify-start items-start text-black py-10' >
        <div className=' font-bold text-3xl py-5'>
            What is encryption?
        </div>
        <p className=' grid justify-start items-start text-2xl py-10 '>
            Encryption converts sensitive information or data into a secret code to prevent unauthorized access.
            Encryption helps organizations stay compliant with regulatory privacy requirements and standards. It can also help protect  sensitive information. Every time we make a purchase online, use the bank ATM, text or call somebody, encryption keeps the transaction or communication private and highly secure. Encryption provides a layer of security so information is only accessed by the authorized users. It has become an essential part of our modern day lives. We should care about encryption as it provides privacy, security, integrity of data, and authentication. In many cases it is required for regulatory compliance.
        </p>
        <hr className='bg-gray-200 h-1 w-2/4 '></hr>
    </section>
    <h2 className='flex justify-center text-black text-4xl font-bold mb-4 px-3 capitalize'>
    When to use encryption.....?
    </h2>
    <section className=' text-black py-10'>
          <div className='container mx-auto  '>
            <div className='grid grid-cols-1 md:grid-cols-2 '>
              
              <div className='flex justify-center items-center text-center text-xl '>
               
              </div>
              <img src={image1} alt='vision' className=' flex justify-end items-end shadow-2xl rounded-2xl ' />
            </div>
          </div>
        </section>
        <section className= 'grid justify-start items-start text-black  ' >
            <div className='  flex justify-start items-start font-bold text-2xl py-5 capitalize '>
            It’s important to encrypt messages, files, and data exchanged online whenever they contain personal, sensitive, or classified information like:
            </div>
           <ol className='grid justify-center items-start gap-3 font-semibold'>
              <li>Encryption ensures only the intended recipient can access the information you shared.</li>
              <li>Entering your financial information to log in to your online bank account </li>
              <li>Sharing a confidential report with your employer</li>
            </ol>
            <p className='py-2 font-semibold'>Sending an email to your doctor </p>
        </section>
        <section className='grid justify-start items-start text-black py-10' >
        <div className=' font-bold text-5xl py-10 capitalize '>
        Symmetric encryption....
        </div>
        <img src={image2} alt='vision' className=' grid justify-center items-center relative left-10 p-20 rounded-2x1'  />
        <p className=' grid justify-start items-start text-2xl py-10 '>
        Every time we make a purchase online, use the bank ATM, text or call somebody, encryption keeps the transaction or communication private and highly secure. Encryption provides a layer of security so information is only accessed by the authorized users. It has become an essential part of our modern day lives. We should care about encryption as it provides privacy, security, integrity of data, and authentication. In many cases it is required for regulatory compliance.Every time we make a purchase online, use the bank ATM, text or call somebody, encryption keeps the transaction or communication private and highly secure. Encryption provides a layer of security so information is only accessed by the authorized users. It has become an essential part of our modern day lives. We should care about encryption as it provides privacy, security, integrity of data, and authentication. In many cases it is required for regulatory compliance.
        </p>
        
    </section>
        </div>
      </section>
    </div>
  );
};

export default DataProtection;