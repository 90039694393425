import React from 'react';
import img1 from "../../resources/images/partners/partnerLinks/management/privilege1.jpeg"
import img2 from "../../resources/images/partners/partnerLinks/management/privilege2.jpeg"


const Management = () => {

    return (

        <div className='p-12'>
            <div className='h-16'></div>
            <div className='p-1'>
                <button onClick={Management} className=" text-black  font-semibold">
                    Products & Products
                </button>
            </div>
            <div className=''>
                <div className='container mx-auto text-white pl-4 h-80 bg-[rgb(13,13,83)]  flex flex-col justify-center '>
                    <p className='md:text-6xl font-bold  '>
                        Endpoint privilege management enforce least privilege

                    </p>
                    <p className='md:text-2xl font-semibold '>
                        A critical security measure that protects your organization&apos;s endpoints from privileged attacks.
                    </p>
                </div>
                <div className=' bg-sky-400  px-5 '>
                    <section className='flex flex-col md:flex-row items-center'>
                        <div className=' mt-5 '>
                            <p className='text-4xl font-bold '>
                                Unmanaged privileges
                            </p>
                            <p className='text-xl py-5 '>
                                Uncontrolled privileges across endpoints and applications can open doors to unauthorized access. Traditional security approaches are often inadequate to address these evolving threats.


                            </p>
                        </div>
                        <img
                            src={img1}
                            alt='Demo'
                            className='shadow-lg  rounded-3xl'
                        />
                    </section>
                    <section className='flex flex-col md:flex-row items-center mt-5  '>
                        <img
                            src={img2}
                            alt='Demo'
                            className='shadow-lg  rounded-3xl'
                        />
                        <div className='md:px-5'>

                            <p className='text-4xl font-bold'>
                                Endpoint privilege management

                            </p>
                            <p className='text-xl py-5 '>
                                Providing a comprehensive answer to security issues, organizations can increase their security posture while allowing important operations to run smoothly. This can be accomplished by reducing redundant admin permissions, establishing application-specific privilege management, and providing just-in-time access.
                            </p>
                        </div>

                    </section>

                </div>

            </div>
        </div>

    );
};
export default Management;