import React from 'react';
import './partners.css';
import netwrix from '../../resources/images/partners/netwrix_logo.svg';
import synopsys from '../../resources/images/partners/synopsys_logo.png';
import gurucul from '../../resources/images/partners/gurucul-logo.svg';
import kgTiger from '../../resources/images/partners/kgtiger_logo.png';
import buzzclan from '../../resources/images/partners/buzzClan.png';
import ezynest from '../../resources/images/partners/Ezynest.png';
import faaz from '../../resources/images/partners/Faaz.webp';
import innominds from '../../resources/images/partners/innominds.svg';
import sageIT from '../../resources/images/partners/Sage-IT.svg';
import techly from '../../resources/images/partners/techly.jpeg';
import technogen from '../../resources/images/partners/Technogen.png';
import teema from '../../resources/images/partners/Teema.png';
import zillion from '../../resources/images/partners/zillion.png';
import vitaver from '../../resources/images/partners/vitaver.jpeg';
import summerLogo from '../../resources/images/partners/sumeru_logo.png';

import { Link } from "react-router-dom";

const Partners = () => {


  return (
    <div className=' min-h-screen w-full'>
      <section className='pt-28 px-12'>
        <div className="flex justify-start items-center px-20  text-base py-8 ">
        </div>
        <div className='container mx-auto text-center'>
          <h2 className='text-2xl md:text-3xl lg:text-4xl mb-10'>
            At Data Solutions, We believe in  the power of collaboration with best
            Cyber Security partners with leading technology providers to bring
            the best solutions to our clients.
          </h2>
          <h2 className='text-black text-center text-3xl font-bold mb-8'>
            Tier One
          </h2>
          <div className='flex justify-center items-center h-center'>
            {/* Add service cards here */}
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10'>
              {/* Netwrik Partner -- Reduce the size */}

              <a
                href='https://www.netwrix.com'
                target='_blank'
                className='shadow-box w-96 h-64 bg-white p-6 rounded-md shadow-xl' rel="noreferrer"
              >
                <div className='grid grid-cols-1 gap-4'>
                  <img
                    src={netwrix}
                    alt='Description of the image'
                    className='h-12 pl-12'
                  />

                  <h5 className='text-gray-600 text-xl text-center p-6 h-40'>
                    Data Solutions works with Netwrix to provide cybersecurity
                    solutions for PAM & IAM services, Data Governance.
                  </h5>
                </div>
              </a>
              {/* Synopsys Partner */}
              <a
                href='https://www.synopsys.com'
                target='_blank'
                className='shadow-box w-96 h-64 bg-white p-6 rounded-md shadow-xl' rel="noreferrer"
              >
                <div className='grid grid-cols-1 gap-4'>
                  <img
                    src={synopsys}
                    alt='Description of the image'
                    className='h-12 pl-16'
                  />

                  <div className='p-4'>
                    <h5 className='text-gray-600 text-xl text-center h-40'>
                      Data Solutions and Synopsys works to provide  the full version
                      of AI-Driven EDA for Application Security.
                    </h5>
                  </div>
                </div>
              </a>
              {/* Gurucul Partner */}
              <a
                href='https://www.gurucul.com/'
                target='_blank'
                className='shadow-box w-96 h-64 bg-white p-6 rounded-md shadow-xl' rel="noreferrer"
              >
                <div className='grid grid-cols-1 gap-4 '>
                  <img
                    src={gurucul}
                    alt='Description of the image'
                    className='h-12 pl-8'
                  />
                  <h5 className='text-gray-600 text-xl text-center p-6 h-40'>
                    Data solutions and Gurucul empowers the full potential of
                    SecOps and analytics to provide Next-Gen SIEM | UEBA | XDR.
                  </h5>
                </div>
              </a>
            </div>
          </div>
          <div className='pt-20'>
            <h2 className='text-black text-center text-3xl font-bold'>
              Tier Two
            </h2>
            <div className='container mx-auto text-center'>
              <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-5 gap-4 py-5'>
                {/* Ezynest Partner */}
                <div className='p-4'>
                  <img
                    src={ezynest}
                    alt='Description of the image'
                    className='h-30'
                  />
                </div>
                {/* KgTiger Partner */}

                <div className='p-4'>
                  <img
                    src={kgTiger}
                    alt='Description of the image'
                    className='h-30'
                  />
                </div>

                {/* Teema Partner */}

                <div className='p-4'>
                  <img
                    src={teema}
                    alt='Description of the image'
                    className='h-30'
                  />
                </div>

                {/* SageIT Partner */}

                <div className='p-4'>
                  <img
                    src={sageIT}
                    alt='Description of the image'
                    className='h-30'
                  />
                </div>

                {/* Vitaver Partner */}

                <div className='p-4 -mt-16'>
                  <img
                    src={vitaver}
                    alt='Description of the image'
                    className='h-30'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='py-5'>
        <div className='container mx-auto text-center'>
          <h3 className='text-black text-5xl font-semibold mb-4 p-1'>
            Companies from a global range who choose Data Solutions
          </h3>

          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 gap-4 py-5'>
            <div className='p-4'>
              <img
                src={buzzclan}
                alt='Description of the image'
                className='w-90 h-20'
              />
            </div>

            <div className='p-4'>
              <img src={faaz} alt='Description of the image' />
            </div>
            <div className='p-4'>
              <img src={innominds} alt='Description of the image' />
            </div>

            <div className='p-4'>
              <img src={summerLogo} alt='Description of the image' />
            </div>
            <div className='p-4'>
              <img src={techly} alt='Description of the image' />
            </div>
            <div className='p-4'>
              <img src={technogen} alt='Description of the image' />
            </div>

            <div className='px-10 '>
              <img src={zillion} alt='Description of the image' />
            </div>
          </div>
        </div>
      </section>

      <section className='flex flex-col lg:flex-row justify-center items-start  bg-gray-700 text-white py-10 px-5 lg:py-10 lg:px-10'>
        <div className='px-5 lg:px-20 mb-10 lg:mb-0'>
          <h2 className='font-bold mb-4 text-xl'>Solutions</h2>
          {/* <div>Data Access Governance</div> */}
          <ul>
            <li>
              <Link to="/partnersProducts/Governance">
                <p className="hover:underline">Data Access Governance</p>
              </Link>
            </li>
            <li>
              <Link to="/PartnersProducts/Protection">
                <p className="hover:underline">Ransomware Protection</p>
              </Link>
            </li>
            <li>
              <Link to="/PartnersProducts/Management">
                <p className="hover:underline">Privileged Access Management</p>
              </Link>
            </li>
            <li>
              <Link to="/PartnersProducts/Security">
                <p className="hover:underline">Active Directory Security</p>
              </Link>
            </li>
          </ul>
          {/* <div>Ransomware Protection</div> */}
          {/* <div>Privileged Access Management</div>
          <div>Active Directory Security</div> */}
        </div>
        <div className='px-5 lg:px-20 mb-10 lg:mb-0'>
          <h2 className='font-bold mb-4 text-xl'>Support</h2>
          {/* <div>Renew License</div>
          <div>Submit a Ticket</div>
          <div>Knowledge Center</div>
          <div>Customer Portal</div>
          <div>Supported Versions</div> */}
          <ul>
            <li>
              <Link to="/renewLicense">
                <p className="hover:underline">Renew License</p>
              </Link>
            </li>
            <li>
              <Link to="/submitTicket">
                <p className="hover:underline">Submit a Ticket</p>
              </Link>
            </li>
            <li>
              <Link to="/knowledgeCenter">
                <p className="hover:underline">Knowledge Center</p>
              </Link>
            </li>
            <li>
              <Link to="/customerPortal">
                <p className="hover:underline">Customer Portal</p>
              </Link>
            </li>
           
          </ul>
        </div>
        <div className='px-5 lg:px-20'>
          <h2 className='font-bold mb-4 text-xl'>Company</h2>
          <ul>
            <li>
              <Link to="/about">
                <p className="hover:underline">About Us</p>
              </Link>
            </li>
            <li>
              <Link to="/PartnersProducts/Careers">
                <p className="hover:underline">Careers</p>
              </Link>
            </li>
            <li>
              <Link to="/PartnersProducts/Team">
                <p className="hover:underline">Management Team</p>
              </Link>
            </li>
            <li>
              <Link to="/PartnersProducts/Customers">
                <p className="hover:underline">Customers</p>
              </Link>
            </li>
            <li>
              <Link to="/PartnersProducts/Analyst">
                <p className="hover:underline">Analyst Coverage</p>
              </Link>
            </li>

            <li>
              <Link to="/contact">
                <p className="hover:underline">Contact Us</p>
              </Link>
            </li>
          </ul>
          {/* <div>About Us</div>
          <div>Careers</div>
          <div>Management Team</div>
          <div>Customers</div>
          <div>Analyst Coverage</div>
          <div>News</div>
          <div>Events</div>
          <div>Contact Us</div> */}

        </div>
      </section>
    </div>
  );
};

export default Partners;