import React, { useState, useCallback } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Form = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    countryCode: '',
    organization: '',
    message: '',
    checked1: false,
    checked2: false,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [close, setClose] = useState(true);

  const handleReset = useCallback(() => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      countryCode: '',
      organization: '',
      message: '',
      checked1: false,
      checked2: false,
    });
    setErrors({});
    setClose(true);
  }, []);

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData({ ...formData, [id]: type === 'checkbox' ? checked : value });

    if (value.trim() !== '' || type === 'checkbox') {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[id];
        return newErrors;
      });
    }
  };

  const handlePhoneChange = (value, data) => {
    setFormData({
      ...formData,
      phoneNumber: value,
      countryCode: data.dialCode,
    });

    if (value.trim() !== '') {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.phoneNumber;
        delete newErrors.countryCode;
        return newErrors;
      });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = 'First Name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last Name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
    if (!formData.phoneNumber.trim()) newErrors.phoneNumber = 'Phone Number is required';
    if (!formData.organization.trim()) newErrors.organization = 'Organization is required';
    if (!formData.message.trim()) newErrors.message = 'Message is required';
    if (!formData.checked1) newErrors.checked1 = 'You must agree to the privacy policy';
    if (!formData.checked2) newErrors.checked2 = 'You must agree to receive marketing communications';

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSubmitted(false);
    } else {
      console.log('Form submitted successfully:', formData);
      localStorage.setItem('formData', JSON.stringify(formData));
      setIsSubmitted(true);
      handleReset();

      // Export the data to Excel after saving to localStorage
      exportToExcel(formData);
    }
  };

  const exportToExcel = (data) => {
    // Convert JSON data to CSV format
    const csvData = [
      [
        'FirstName',
        'LastName',
        'Email',
        'PhoneNumber',
        'Organization',
        'Message',
      ],
      [
        data.firstName,
        data.lastName,
        data.email,
        data.phoneNumber,
        data.organization,
        data.message,
      ],
    ]
      .map((e) => e.join(','))
      .join('\n');

    // Create a Blob from the CSV data
    // const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a URL for the Blob
    // const url = URL.createObjectURL(blob);

    // Set the download link state
    // setDownloadLink(url);
  };

  return (
    <div className='flex items-center justify-center min-h-screen py-20'>
      {isSubmitted && close && (
        <article className='absolute left-0 w-[98vw] h-[1000px] bg-[rgba(255,255,255,90%)] flex items-center justify-center z-10'>
          <section className='w-1/4 h-1/4 flex items-center flex-col justify-center border-2 border-black rounded-md'>
            <p className='text-green-500 text-3xl mb-4 text-center'>Your details have been submitted successfully!</p>
            <button className='border-2 px-5 py-1 text-md bg-orange-300 rounded-lg text-xl text-white' onClick={() => setClose(false)}>Ok</button>
          </section>
        </article>
      )}

      <div className='bg-white p-10 rounded shadow-md w-full max-w-4xl mt-10'>
        <h2 className='text-2xl font-bold mb-12 text-center'>Contact Us</h2>
        {!isSubmitted && Object.keys(errors).length > 0 && (
          <p className='text-red-500 text-lg mb-4 text-center'>Please fill the below details!</p>
        )}
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='firstName'>
              First Name
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.firstName ? 'border-red-500' : ''}`}
              id='firstName'
              type='text'
              placeholder='Your First Name'
              value={formData.firstName}
              onChange={handleChange}
            />
            {errors.firstName && (
              <p className='text-red-500 text-xs italic'>{errors.firstName}</p>
            )}
          </div>

          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='lastName'>
              Last Name
            </label>
            <input
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.lastName ? 'border-red-500' : ''}`}
              id='lastName'
              type='text'
              placeholder='Your Last Name'
              value={formData.lastName}
              onChange={handleChange}
            />
            {errors.lastName && (
              <p className='text-red-500 text-xs italic'>{errors.lastName}</p>
            )}
          </div>

          <div className='gap-5 mb-5'>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
                Email
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.email ? 'border-red-500' : ''}`}
                id='email'
                type='email'
                placeholder='Your Email'
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <p className='text-red-500 text-xs italic'>{errors.email}</p>
              )}
            </div>

            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2 z-0' htmlFor='phoneNumber'>
                Phone Number
              </label>
              <PhoneInput
                country={'us'}
                value={formData.phoneNumber}
                onChange={handlePhoneChange}
                inputProps={{
                  name: 'phoneNumber',
                  className: `shadow appearance-none border rounded w-full py-2 px-12 text-gray-700 focus:outline-none focus:shadow-outline ${errors.phoneNumber ? 'border-red-500' : ''}`,
                }}
              />
              {errors.phoneNumber && (
                <p className='text-red-500 text-xs italic'>{errors.phoneNumber}</p>
              )}
            </div>
          </div>

          <div className='gap-5 mb-5'>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='organization'>
                Organization
              </label>
              <input
                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.organization ? 'border-red-500' : ''}`}
                id='organization'
                type='text'
                placeholder='Organization'
                value={formData.organization}
                onChange={handleChange}
              />
              {errors.organization && (
                <p className='text-red-500 text-xs italic'>{errors.organization}</p>
              )}
            </div>

            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='message'>
                Message
              </label>
              <input
                className={`shadow appearance-none border rounded w-full h-20 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.message ? 'border-red-500' : ''}`}
                id='message'
                type='text'
                placeholder='Message'
                value={formData.message}
                onChange={handleChange}
              />
              {errors.message && (
                <p className='text-red-500 text-xs italic'>{errors.message}</p>
              )}
            </div>
          </div>

          {/* Checkboxes */}
          <div>
            <div className='mb-2'>
              <input
                id='checked1'
                type='checkbox'
                checked={formData.checked1}
                onChange={handleChange}
              />
              &nbsp;&nbsp;
              <label htmlFor='checked1'>
                I confirm, I have read{' '}
                <a href='#'>Data Solution&apos;s Privacy Policy</a> and consent to
                share my information
              </label>
              {errors.checked1 && (
                <p className='text-red-500 text-xs italic'>{errors.checked1}</p>
              )}
            </div>
            <div>
              <input
                id='checked2'
                type='checkbox'
                checked={formData.checked2}
                onChange={handleChange}
              />
              &nbsp;&nbsp;
              <label htmlFor='checked2'>
                I agree to receive marketing communications from Data Solutions
              </label>
              {errors.checked2 && (
                <p className='text-red-500 text-xs italic'>{errors.checked2}</p>
              )}
            </div>
          </div>

          <div className='flex items-center justify-center pt-5'>
            <button
              className='border-2 p-2 rounded-lg bg-orange-300 w-60 hover:border-red-600'
              type='submit'
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
