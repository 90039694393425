import React from 'react';
import './services.css';
import networkSecurity from '../../resources/images/Solutions&Capabilities/Network Security.webp';
import siemSoc from '../../resources/images/Solutions&Capabilities/soc-siem-header-img.jpg';
import penetrationTesting from '../../resources/images/Solutions&Capabilities/testing.webp';
import complianceAdvisory from '../../resources/images/Solutions&Capabilities/complianceImage.png.jpg';
import threatMonitioringProtection from '../../resources/images/Solutions&Capabilities/blog-52.8-Best-Practices-to-Monitor-Detect-Public-Cloud-Threats.jpg';
import video from '../../resources/images/Solutions&Capabilities/Video1.mp4';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  let Navigate = useNavigate()
  let Services = () => {

    Navigate("/services")
  }
  let nets = () => {
    Navigate("/ApplicationSecurity");
  };
  

  return (
    <div className='min-h-screen px-12'>
      <section className='text-white py-20'>
        {/* <div className="flex justify-start items-center px-20  text-base py-10 ">


        </div> */}
        <div className=' text-center p-5 container mx-auto flex justify-self-auto s relative'>
          {/* Add service cards here */}

          <div className='grid  gap-6'>
            {/* Application Network*/}

            <div
              className='container mx-auto py-6 bg-blue-50 rounded-xl'
              id='services_application_security'
            >
              <div className='flex items-center justify-center text-4xl font-bold text-black capitalize  py-5 '>
                Application Security
              </div>
              <div className='group  '>
                <div className='flex justify-start items-center flex-col md:flex-row gap-2 p-3 relative'>
                  <video
                    src={video}
                    autoPlay
                    muted
                    loop
                    className='rounded-lg  md:w-2/4 h-96 object-cover'
                  ></video>
                  <div className='text-2xl  '>
                    <div className='text-black text-2xl text-start relative top-4 md:p-10'>
                      Application security is the process of developing, adding,
                      and testing security features within applications to
                      prevent security vulnerabilities against threats such as
                      unauthorized access and modification.
                    </div>
                    <div className='text-start md:absolute bottom-2 lg:left-52 md:left-20 sm:bottom-0'>
                      <button onClick={nets} className='md:invisible  group-hover:visible'>
                        <p className='text-2xl  text-black md:text-white py-16 underline-animation w-60' >
                          Get to know more
                          <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Network Security */}

            <div className='container mx-auto py-10' id='services_network_security'>
              <div className='flex items-center justify-center text-4xl font-bold text-black capitalize  md:py-10 '>
                Network Security
              </div>
              <div className=' group   '>
                <div className='flex justify-evenly items-start flex-col md:flex-row p-4'>
                  <div>
                    <div className='text-left text-black text-2xl md:pt-[70px]  md:pr-16'>
                      Network security involves protecting a computer network
                      and its data from unauthorized access, misuse,
                      malfunction, modification, destruction, or improper
                      disclosure. It targets a variety of threats. It stops them
                      from entering or spreading on your network. Effective
                      network security manages access to the network...{' '}
                    </div>
                  </div>
                  <div className='text-center pt-5 md:pt-0'>
                    <div>
                      <img
                        src={networkSecurity}
                        className='rounded-xl h-96 w-[170em]'
                      ></img>
                    </div>
                    <div className='text-start relative md:bottom-24 left-3 '>
                      <Link
                        to='/networkSecurity1'
                        className='md:opacity-0  group-hover:opacity-100  w-96  '
                      >
                        <p className='text-2xl md:text-white text-black py-4 underline-animation w-60'>
                          Get to know more
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className='px-2'
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* SIEM & SOC */}

            <div className='container mx-auto py-20  bg-red-50 rounded-xl' id='services_siem_&_soc'>
              <div className='flex items-center justify-center text-4xl font-bold text-black capitalize'>
                SIEM & SOC
              </div>
              <div className=' group'>
                <div className='flex justify-evenly items-center flex-col md:flex-row p-4 gap-4'>
                  <img src={siemSoc} className='rounded-xl md:w-2/4 md:h-[400px] h-96' ></img>
                  <div>
                    <div className='text-black text-2xl text-start md:px-10'>
                      SIEM systems provide real-time analysis of security alerts
                      generated by applications and network hardware. SIEM tools
                      collect, normalize, and analyze security data from various
                      sources to identify and respond to potential threats.{' '}
                    </div>

                    <div className='text-start relative  lg:bottom-14 lg:top-16  md:right-64'>
                      <Link
                        to='/siemSoc'
                        className='md:invisible  group-hover:visible  w-96  '
                      >
                        <p className='text-2xl text-black md:text-white py-16 underline-animation w-60 '>
                          Get to know more
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className='px-2'
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Penetration Testing */}

            <div
              className='container mx-auto py-12'
              id='services_penetration_testing'
            >
              <div className='flex items-center justify-center text-4xl font-bold text-black capitalize md:py-10 '>
                Penetration Testing
              </div>
              <div className=' group  relative top-4  p-10'>
                <div className='flex justify-center items-center flex-col md:flex-row  gap-4'>
                  <div>
                    <div className='text-black text-2xl text-start'>
                      Penetration testing, often referred to as pen testing, is
                      a simulated cyber attack against a computer system,
                      network, or web application to identify vulnerabilities
                      that an attacker could exploit.{' '}
                    </div>
                    <div className='md:text-end text-start md:
                    relative md:left-64 lg:top-18'>
                      <Link
                        to='/penetrationTesting'
                        className='md:invisible group-hover:visible  w-96'
                      >
                        <p className='text-2xl text-black md:text-white py-4 underline-animation w-60'>
                          Get to know more
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className='px-2 '
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                  <img
                    src={penetrationTesting}
                    className='rounded-xl h-96 md:w-2/4 lg:w-[170em] '
                  ></img>
                </div>
              </div>
            </div>

            {/* Compliance and Advisory */}

            <div className='container mx-auto py-12 bg-gray-100 rounded-xl' id='compliance_and_advisory'>
              <div className='flex items-center justify-center text-4xl font-bold text-black capitalize  md:py-10   '>
                Compliance and Advisory
              </div>
              <div className=' group   '>
                <div className='flex justify-center items-center flex-col md:flex-row p-4'>
                  <img
                    src={complianceAdvisory}
                    className='rounded-xl h-[400px] md:w-2/4 w-96 pb-5 md:pb-0 '
                  ></img>
                  <div>
                    <div className='text-black text-2xl text-start md:p-10 '>
                      Compliance in cybersecurity refers to the adherence to
                      laws, regulations, standards, and policies designed to
                      protect data and ensure the integrity, confidentiality,
                      and availability of information systems.{' '}
                    </div>
                    <div className='text-start relative md:top-5 lg:top-10  md:right-64'>
                      <Link
                        to='/services'
                        className='md:invisible  group-hover:visible  w-96  '
                      >
                        <p className='text-2xl text-black md:text-white py-4 underline-animation w-60 '>
                          Get to know more
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className='px-2 '
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Threat Monitory & Protection */}

            <div
              className='container mx-auto py-12'
              id='services_threat_monitory_&_protection'
            >
              <div className='flex items-center justify-center text-4xl font-bold text-black capitalize  md:py-10 '>
                Threat Monitory & Protection
              </div>
              <div className=' group    '>
                <div className='flex justify-center items-center flex-col md:flex-row p-4'>
                  <div>
                    <div className='text-black text-2xl text-start md:pr-16'>
                      Threat monitoring and protection are critical components
                      of an organizations cybersecurity strategy, aimed at
                      detecting, responding to, and mitigating cyber threats in
                      real time.{' '}
                    </div>
                    <div className='md:text-end text-start relative md:left-64 lg:top-20'>
                      <Link
                        to='/services'
                        className='md:invisible  group-hover:visible  w-96 '
                      >
                        <p className='text-2xl text-black font-semibold md:text-white py-4 underline-animation w-60'>
                          Get to know more
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className='px-2 '
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                  <img
                    src={threatMonitioringProtection}
                    className='rounded-xl md:h-[400px] md:w-2/4'
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
