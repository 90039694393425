import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faNetworkWired,
  faFireFlameCurved,
  faMagnifyingGlass,
  faBug,
  faLock,
  faCircleCheck,
  faHospitalUser,
  faFingerprint,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Form from "../requestADemo/Form";

import "./HomePage.css";

const HomePage = () => {
  let Navigate = useNavigate();
  let nets = () => {
    Navigate("/homePage/networkSecurity");
  };
  let fire = () => {
    Navigate("/homePage/Firewall");
  };
  let Intrusion = () => {
    Navigate("/homePage/IntrusionDetectionSystem");
  };

  let Prevention = () => {
    Navigate("/homePage/IntrusionPreventionSystem");
  };

  let Virtual = () => {
    Navigate("/homePage/VirtualPrivateNetwork");
  };

  let encryption = () => {
    Navigate("/homePage/Encryption");
  };

  let Authentication = () => {
    Navigate("/homePage/Authentication");
  };

  let Denial = () => {
    Navigate("/homePage/DenialOfServiceAttack");
  };
  let HandleMe = () => {
    Navigate("/homePage/regularSecurityAssessmentsAndAudits");
  };
  let Handlesey = () => {
    Navigate("/homePage/continuousEducationAndTraining");
  };
  let patches = () => {
    Navigate("/homePage/patchManagement");
  };
  let Access = () => {
    Navigate("/homePage/accessControlAndLeastPrivilege");
  };

  let Incident = () => {
    Navigate("/homePage/incidentResponsePlan");
  };
  let Third = () => {
    Navigate("/homePage/thirdPartyRiskManagement");
  };
  let sec = () => {
    Navigate("/homePage/securityAutomationAndOrchestration");
  };
  const [isOpen, setIsOpen] = useState(false);
  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <div className="min-h-screen ">
      <section className="bg-white text-black py-32 md:py-56 px-16">
        <div className="container mx-auto text-center">
          <h2 className="text-black text-font-family text-4xl md:text-6xl font-bold mb-4 ">
            AI Innovation And Cyber Security In The Enterprise
          </h2>
          <p className="text-xl md:text-2xl text-black text-font-family py-5">
            As [AI] Adoption Accelerates, Organizations must Adapt their Cyber
            Security Strategies to address new risks and challenges.
          </p>
          <Link to="/services">
            <p className="text-xl md:text-2xl text-black py-5 underline-animation">
              Get to know more
              <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
            </p>
          </Link>
        </div>
      </section>

      {/* Services Section */}
      <section className="bg-white text-black px-12">
        <div className="container mx-auto text-center ">
          <h2 className="text-3xl font-bold mb-8">
            Security is always excessive until it&apos;s not enough.
          </h2>
          {/* Add service cards here */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-5">
            {/* Service Card Example */}

            <div className="bg-white p-10 rounded-lg shadow-box ">
              <FontAwesomeIcon icon={faNetworkWired} className="h-14 py-5" />
              <h3 className="text-black text-xl font-semibold mb-4">
                Network Security
              </h3>
              <p className="text-gray-600">
                Network security is the practice of securing a computer network
                infrastructure against unauthorized access, misuse,
                modification, or denial of service attacks.
              </p>
              <button onClick={nets}>
                <p className="text-xl text-black py-5  underline-animation ">
                  Get to know more
                  <FontAwesomeIcon icon={faArrowRight} className="px-1.5 " />
                </p>
              </button>
            </div>

            <div className="bg-white p-11 rounded-lg shadow-box">
              <FontAwesomeIcon icon={faFireFlameCurved} className="h-14 py-4" />
              <h3 className="text-black text-xl font-semibold mb-4">
                Firewall
              </h3>
              <p className="text-gray-600">
                A firewall is a network security device or software that network
                infrastructure against unauthorized access, misuse,
                modification, of a service attacks.
              </p>
              <button onClick={fire}>
                <p className="text-xl text-black py-5 underline-animation ">
                  Get to know more
                  <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
                </p>
              </button>
            </div>

            <div className="bg-white p-12 rounded-lg shadow-box">
              <FontAwesomeIcon icon={faMagnifyingGlass} className="h-14 py-5" />
              <h3 className="text-black text-xl font-semibold mb-4">
                Intrusion Detection System (IDS)
              </h3>
              <p className="text-gray-600">
                An intrusion detection system is a security tool that monitors
                network or system activities for malicious in response.
              </p>
              <button onClick={Intrusion}>
                <p className="text-xl text-black py-5 underline-animation ">
                  Get to know more
                  <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
                </p>
              </button>
            </div>

            <div className="bg-white p-10 rounded-lg shadow-box">
              <FontAwesomeIcon icon={faBug} className="h-14 py-8" />
              <h3 className="text-black text-xl font-semibold mb-4">
                Intrusion Prevention System (IPS)
              </h3>
              <p className="text-gray-600">
                An intrusion prevention system is a security tool that actively
                monitors network traffic to detect and prevent potential threats
                monitors network  in real-time.
              </p>
              <button onClick={Prevention}>
                <p className="text-xl text-black py-5  underline-animation ">
                  Get to know more
                  <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
                </p>
              </button>
            </div>

            <div className="bg-white p-10 rounded-lg shadow-box">
              <FontAwesomeIcon icon={faLock} className="h-14 py-5" />
              <h3 className="text-black text-xl font-semibold mb-4">
                Virtual Private Network (VPN)
              </h3>
              <p className="text-gray-600">
                A virtual private network is a secure encrypted connection
                established over a public network (typically the internet) to
                enable secure communication between remote users or networks.
              </p>
              <button onClick={Virtual}>
                <p className="text-xl text-black py-5 underline-animation ">
                  Get to know more
                  <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
                </p>
              </button>
            </div>

            <div className="bg-white p-10 rounded-lg shadow-box">
              <FontAwesomeIcon icon={faFingerprint} className="h-14 py-5" />
              <h3 className="text-black text-xl font-semibold mb-4">
                Encryption
              </h3>
              <p className="text-gray-600">
                Encryption is the process of converting plaintext data into
                ciphertext using an algorithm and a cryptographic key. It
                parties intercept the data ciphertext using an
                algorithmdecryption key.
              </p>
              <button onClick={encryption}>
                <p className="text-xl text-black py-5 underline-animation ">
                  Get to know more
                  <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
                </p>
              </button>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-box">
              <FontAwesomeIcon icon={faCircleCheck} className="h-14 py-5" />
              <h3 className="text-black text-xl font-semibold mb-4">
                Authentication
              </h3>
              <p className="text-gray-600">
                Authentication is the process of verifying the identity of a
                user or device attempting to access a network or system. Common
                authentication methods include passwords, biometrics,
                multi-factor authentication (MFA).
              </p>
              <button onClick={Authentication}>
                <p className="text-xl text-black py-5 underline-animation ">
                  Get to know more
                  <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
                </p>
              </button>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-box">
              <FontAwesomeIcon icon={faHospitalUser} className="h-14 py-5" />
              <h3 className="text-black text-xl font-semibold mb-4">
                Denial of Service (DoS) Attack
              </h3>
              <p className="text-gray-600">
                A denial of service attack is a malicious attempt to disrupt the
                normal functioning of a network, server, or website by
                overwhelming it with a large volume of illegitimate traffic or
                normal functioning of a network, server source requests.
              </p>
              <button onClick={Denial}>
                <p className="text-xl text-black py-5 underline-animation ">
                  Get to know more
                  <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
                </p>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white text-black ">
        <div className="container mx-auto text-center bg-white p-6 rounded-lg shadow-lg">
          <div className="text-gray-800 text-center px-4 py-2 m-2">
            <h2 className="font-semibold text-xl md:text-2xl">
              Securing for tomorrow
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-5">
              <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-white text-xl font-semibold mb-4">
                  Continuous Education and Training
                </h3>
                <p className="text-white">
                  Regularly educate and train employees on security best
                  practices, emerging threats, and how to recognize and respond
                  to security incidents.
                </p>
                <button onClick={Handlesey}>
                  <p className="text-xl text-white py-5  underline-animation ">
                    Get to know more
                    <FontAwesomeIcon icon={faArrowRight} className="px-1.5 " />
                  </p>
                </button>
              </div>

              <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-white text-xl font-semibold mb-4">
                  Regular Security Assessments and Audits
                </h3>
                <p className="text-white">
                  Conduct regular security assessments and audits to identify
                  areas for improvement in your organization&apos;s security
                  posture.
                </p>
                <button onClick={HandleMe}>
                  <p className="text-xl text-white py-5  underline-animation ">
                    Get to know more
                    <FontAwesomeIcon icon={faArrowRight} className="px-1.5 " />
                  </p>
                </button>
              </div>

              <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-white text-xl font-semibold mb-4">
                  Patch Management
                </h3>
                <p className="text-white">
                  Implement a robust patch management process to ensure that
                  software, firmware, and systems are regularly updated with the
                  latest security patches and fixes.
                </p>
                <button onClick={patches}>
                  <p className="text-xl text-white py-5  underline-animation ">
                    Get to know more
                    <FontAwesomeIcon icon={faArrowRight} className="px-1.5 " />
                  </p>
                </button>
              </div>

              <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-white text-xl font-semibold mb-4">
                  Access Control and Least Privilege
                </h3>
                <p className="text-white">
                  Enforce strong access controls and the principle of least
                  privilege to limit user access to only the resources and
                  information necessary for communications and to use their roles.
                </p>
                <button onClick={Access}>
                  <p className="text-xl text-white py-5  underline-animation ">
                    Get to know more
                    <FontAwesomeIcon icon={faArrowRight} className="px-1.5 " />
                  </p>
                </button>
              </div>

              <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-white text-xl font-semibold mb-4">
                  Incident Response Plan
                </h3>
                <p className="text-white">
                  Develop and regularly update an incident response plan that
                  times, and reduce the communications and use communications
                  and use responding to, and recovering from security incidents.
                </p>
                <button onClick={Incident}>
                  <p className="text-xl text-white py-5  underline-animation ">
                    Get to know more
                    <FontAwesomeIcon icon={faArrowRight} className="px-1.5 " />
                  </p>
                </button>
              </div>

              <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-white text-xl font-semibold mb-4">
                  Third-Party Risk Management
                </h3>
                <p className="text-white">
                  Assess and manage the security risks associated with
                  third-party vendors, suppliers, and service providers who have
                  access to your organization&apos;s systems or data.
                </p>
                <button onClick={Third}>
                  <p className="text-xl text-white py-5  underline-animation ">
                    Get to know more
                    <FontAwesomeIcon icon={faArrowRight} className="px-1.5 " />
                  </p>
                </button>
              </div>

              <div className="bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-white text-xl font-semibold mb-4">
                  Security Automation and Orchestration
                </h3>
                <p className="text-white">
                  Invest in security automation and orchestration tools to
                  streamline security operations, improve incident response
                  automation and orchestration tools to response.
                </p>
                <button onClick={sec}>
                  <p className="text-xl text-white py-5  underline-animation ">
                    Get to know more
                    <FontAwesomeIcon icon={faArrowRight} className="px-1.5 " />
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col lg:flex-row  items-start  bg-gray-700 text-white w-full py-10 px-5">
        <div className=" lg:px-44 mb-10 lg:mb-0">
          <p className="text-xl font-bold mb-3">Quick Links</p>
          <ul>
            <li>
              <Link to="/homepage/Sustainability">
                <p className="hover:underline">Sustainability Management</p>
              </Link>
            </li>

            <li>
              <Link to="/homepage/Insights">
                <p className="hover:underline">Data Solutions Insights</p>
              </Link>
            </li>
          </ul>
        </div>
        <div className="">
          <h2 className="text-xl font-bold mb-3">Site Information</h2>
          <ul>
            <li>
              <Link to="/homepage/Events">
                <p className="hover:underline">Events</p>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-5 rounded shadow-lg w-11/12 md:w-1/2">
            <Form onClose={closeDialog} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
