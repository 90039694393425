import React, { useState } from 'react';
import Form from './Form';
import img1 from '../../resources/images/requestADemo/requestademoimg1.jpg';

const RequestADemo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => {
    setIsOpen(true);
  };
  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <div className='  p-20 '>
      <div className='min-h-screen'>
        <div className='container mx-auto '>
          <div className='text-center'>
            <p className='text-6xl font-bold mb-5'>Data Solutions</p>
            <p className='text-2xl md:text-3xl lg:text-4xl mb-5'>
              Deploy industry-tailored customer experience (Data Solutions)
              <br /> that are connected, insightful, and adaptive
            </p>
            <button
              className='bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700'
              onClick={openDialog}
            >
              Request A Demo
            </button>
          </div>

          <div className='my-10 text-center '>
            <p className='text-3xl font-semibold mb-5'>
              Data Solutions technologies powered by industry expertise
            </p>
            <p className=' text-2xl'>
              Industry-tailored to meet critical business needs, Data Solutions
              supports
              end-to-end processes to help you get the most from your Data
              Solutions Software.
            </p>
          </div>
        </div>
        <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10  '>
          <div className='p-5 bg-white shadow rounded '>
            <p className='text-2xl font-semibold mb-3'>
              One platform Cybersecurity
            </p>
            <p>
              Data Solutions is a holistic Security-as-a-Service platform that
              provides comprehensive coverage at every step of the way: from
              early risk discovery and compliance to incident response
              automation.
            </p>
          </div>
          <div className='p-5  bg-white shadow rounded '>
            <p className='text-2xl font-semibold mb-3'>
              Experience & Success You Can Trust
            </p>
            <p>
              Why sign up with Data Solutions? Because we build long-lasting
              business relationships, work as an extension of our customers’
              team, bring expertise and knowledge, and take care of security,
              every second of every day.
            </p>
          </div>
          <div className='p-5  bg-white shadow rounded'>
            <p className='text-2xl font-semibold mb-3'>Malware Protection</p>
            <p>
              Behavior-based Advanced Active Protection not only blocks zero-day
              threats, it helps you understand what’s there and make a quick,
              informed decision on how to respond.
            </p>
          </div>
        </section>

        <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10'>
          <div className='p-5  bg-white shadow rounded '>
            <p className='text-2xl font-semibold mb-3'>
              A Portfolio of protection services
            </p>
            <p>
              Protects at the file, application, and network layer with network
              and application agnostic DNS protection. Full-featured endpoint
              security management portal for MSPs to protect clients more
              efficiently.
            </p>
          </div>
          <div className='p-5  bg-white shadow rounded'>
            <p className='text-2xl font-semibold mb-3'>
              Encryption and Machine Learning
            </p>
            <p>
              Empowering Cyber Security with Machine learning and AI-powered
              tools to protect the last byte of data. Keeping your organization
              safe from phishing and business emails with Security Awareness
              Training.
            </p>
          </div>
          <div className='p-5  bg-white shadow rounded'>
            <p className='text-2xl font-semibold mb-3'>
              Artificial intelligence
            </p>
            <p>
              Give your AI the confidence approach for learning from the data,
              identifying patterns, and making decisions with minimal human
              intervention and the ability to predict, detect, and respond to
              the threats. Ensuring tasks, providing valuable customer insights,
              and helping work more effieciently.
            </p>
          </div>
        </section>

        <div className=' text-center '>
          <p className='text-3xl font-semibold mb-5'>
            Artificial intelligence
          </p>
        </div>
        <div className=''>
          <section className='flex flex-col md:flex-row items-center  '>
            <img
              src={img1}
              alt='Demo'
              className='w-full md:w-1/2 rounded shadow-lg'
            />
            <div className=' p-5'>
              <p className='text-2xl font-bold mb-3'>
                AI for customer experience management
              </p>
              <p className='text-lg'>
                Relevant, reliable, and responsible AI is built into our Data
                Solutions – helping you predict, manage, and understand your
                customers to build relationships that last. AI solutions for sales
                and service AI Solutions for marketing and e-commerce.
              </p>
            </div>
          </section>
        </div>



        {isOpen && (
          <div className='fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50'>
            <div className='bg-white p-5 rounded shadow-lg w-11/12 md:w-1/2'>
              <Form onClose={closeDialog} />
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default RequestADemo;
