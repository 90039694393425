import React from 'react';

//import video from '../../resources/images/Video11.mp4';
import img1 from '../../resources/images/about1/aboutLink/information/company1.jpg';
import img3 from '../../resources/images/about1/aboutLink/information/Company3.jpeg';
import img2 from '../../resources/images/about1/aboutLink/information/compnay4.jpg';
import img4 from '../../resources/images/about1/aboutLink/information/company2.jpeg';

const Company = () => {
  return (
    <div className='text-lg p-10'>
      <div className='relative top-12'>
        <div>
          <button
            onClick={Company}
            className=' text-black  p-10 font-semibold '
          >
            About
          </button>
        </div>
        <img className='h-5/6 w-full ' src={img1} alt='' />

        <div className='container mx-auto  relative bottom-96  px-4 text-black'>
          <p className='text-3xl font-semibold mb-5'>
            Data Solutions Company Information
          </p>
        </div>
      </div>
      <div className=''>
        <p className='text-3xl font-bold mb-5'>
          Data Solutions Company Information
        </p>
        <p className='text-xl '>
          As a global leader in enterprise applications and business AI, Data
          Solutions stands at the nexus of business and technology. For over 50
          years, organizations have trusted Data Solutions to bring out their
          best by uniting business-critical operations spanning finance,
          procurement, HR, supply chain, and customer experience.
        </p>
      </div>
      <p className='text-2xl font-bold'>Our Strategy</p>
      <p className='text-xl '>
        We are committed to helping the world run better and improving
        people&apos;s lives. <br />
        Our vision is to bring out the best in every business.
      </p>

      <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10'>
        <div className='p-5 bg-white shadow rounded'>
          <img
            src={img2}
            alt='Demo'
            className='w-full md:w-full rounded shadow-lg'
          />
          <p className='text-2xl font-semibold mb-3'>Agility at scale</p>
          <p>
            Navigate dynamic markets confidently with purpose-built innovations
            designed for quick implementation, swift scaling, and rapid
            response.
          </p>
        </div>

        <div className='p-5 bg-white shadow rounded'>
          <img
            src={img3}
            alt='Demo'
            className='w-full md:w-full rounded shadow-lg'
          />
          <p className='text-2xl font-semibold mb-3'>
            Achieve more across the value chain
          </p>
          <p>
            Tap into the trusted and scalable business ecosystem, harnessing the
            intelligence across your internal systems and external partnerships
            to optimize performance.
          </p>
        </div>

        <div className='p-5 bg-white shadow rounded'>
          <img
            src={img4}
            alt='Demo'
            className='w-full md:w-full rounded shadow-lg'
          />
          <p className='text-2xl font-semibold mb-3'>
            Sustainability at your core
          </p>
          <p>
            Transform operations with next-generation solutions that drive more
            accurate and actionable sustainability management across your entire
            enterprise.
          </p>
        </div>
      </section>
    </div>
  );
};
export default Company;
