import React from "react";
import img1 from "../../resources/images/partners/partnerLinks/customers/costumer2.jpg";
import img2 from "../../resources/images/partners/partnerLinks/customers/customer1.jpeg";
import img3 from "../../resources/images/partners/partnerLinks/customers/center5.jpeg";
import img4 from "../../resources/images/partners/partnerLinks/customers/center4.jpg";

const Customers = () => {
  return (
    <div className="px-12  pt-24 ">
      <div className=" text-lg ">
        <div className="">
          <button
            onClick={Customers}
            className=" text-black  font-semibold px-12 pb-6 "
          >
            Products & products
          </button>
        </div>
        <section className="flex flex-col md:flex-row items-center bg-blue-950 h-[500px] text-white px-5 p-5">
          <div className=" p-5 ">
            <p className="text-5xl font-semibold py-5 ">Customer</p>
            <p>
              In today’s world of empowered customers, competitive advantage
              comes through building trust.
            </p>
            <p>
              {" "}
              Everywhere you look, technology is transforming customer
              experiences and expectations, and it’s happening faster all the
              time. In this fast-paced environment, you need to put customer
              trust at the center of the entire organization, enabling you to
              create customer relationships that lead to unwavering loyalty and
              sustainable profits.
            </p>
            <p>
              {" "}
              Data Solutions helps companies transition to customer-centric
              business models and become trusted organizations to their
              customers. Our diverse teams of insights, experience, marketing,
              sales and service specialists, combined with our global
              connectivity and understanding of your industry issues, inspire us
              to ask better questions. We work with you to find innovative
              solutions that help create transparent and authentic customer
              experiences.
            </p>
          </div>
        </section>

        <p className="text-4xl pt-10 px-10">Our latest thinking</p>
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10 px-10">
          <div className="p-5 bg-white shadow rounded">
            <img
              src={img2}
              alt="Demo"
              className="w-full md:w-full rounded shadow-lg"
            />
            <h2 className="text-2xl font-semibold mb-3">
              How AI can transform consumer experience and business efficiency
              in India
            </h2>
            <p className="text-base">
              Consumers are using AI to enhance their shopping experience and
              influence decision-making processes, while companies adopt it for
              valuable insights into logistics and supply chain management,
              leading to cost savings and operational efficiencies.
            </p>
          </div>

          <div className="p-5 bg-white shadow rounded">
            <img
              src={img3}
              alt="Demo"
              className="w-full md:w-full rounded shadow-lg "
            />
            <h2 className="text-2xl font-semibold mb-3">
              How can GCCs enable organizations to be more customer-centric?
            </h2>
            <p className="text-base">
              As Global Capability Centers (GCCs) continue to flex their
              capabilities, they must now be leveraged as a key pivot in
              building organizational customer centrality.
            </p>
          </div>

          <div className="p-5 bg-white shadow rounded">
            <img
              src={img4}
              alt="Demo"
              className="w-full md:w-full rounded shadow-lg"
            />
            <h2 className="text-2xl font-semibold mb-3">
              Unlocking Growth: AI-Driven Pricing Analytics in Net Revenue
              Management
            </h2>
            <p className="text-base">
              AI-powered pricing analytics in Net Revenue Management (NRM)
              utilizes advanced AI techniques to provide a decision support
              mechanism to optimize revenue, increase market share, and
              formulate effective pricing strategies that can be tailored to
              current market needs.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Customers;
