import React from 'react';
import { useNavigate } from 'react-router-dom'
import image from '../../../../resources/images/home/homepage/incidentResponse/incidentResponse.avif';
import image1 from '../../../../resources/images/home/homepage/incidentResponse/spirt.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';



const IncidentResponsePlan = () => {
  let Navi= useNavigate()
  let  Incident=()=>{
    Navi(-1)
  
  }
  return (
    <div className='min-h-screen'>
      <section className=' text-white py-20'>
        <div className='container mx-auto text-center'>
        <div className="flex justify-start items-center px-2  text-base py-5 ">
        <div>
          <button onClick={Incident} className=" text-black ">
            Home
            <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
          </button>
        </div>
        <div>
          <button className="  text-black">Incident Response Plan
          </button>
        </div>
      </div>
        <section className='text-center text-black shadow-lg rounded-2xl  bg-slate-300 p-5'>
        <h1 className='text-4xl font-bold mb-5'>What is incident response?</h1>
        <h2 className='text-xl md:text-2xl lg:text-2xl mb-5 mt-5'>
        Incident response (sometimes called cybersecurity incident response) refers to an organization’s processes and technologies for detecting and responding to cyber threats, security breaches or cyber attacks. A formal incident response plan enables cybersecurity teams to limit or prevent damage.
        </h2>
      </section>
      <section className=' text-black pt-10'>
      <div className='container mx-auto p-4 '>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          
          <div className='grid justify-center items-center text-center text-xl '>
          <div  className='flex justify-start items-center text-3xl font-bold'>
          What is incident response?
          
          </div>
          <p className=' text-base'>
          A security incident, or security event, is any digital or physical breach that threatens the confidentiality, integrity, or availability or an organization’s information systems or sensitive data. Security incidents can range from intentional cyber attacks by hackers or unauthorized users, to unintentional violations of security policy by legitimate authorized users.
          </p>
          
          </div>
          <img src={image} alt='vision' className=' rounded-3xl'  />
        </div>
      </div>
    </section>
    <img src={image1} alt='vision' className='  '  />
    <div className='font-bold text-5xl py-4 text-black italic' > Incident response steps</div>
    <hr className='bg-red-500 h-1 w-3/5 '></hr>
    <section className= ' grid  justify-start  items-start text-black text-xl px-6  p-5 shadow-lg rounded-3xl bg-slate-50 ' >
     <div className='text-black text-xl flex justify-start items-start  '>
    There’s more than one way to approach incident response, and many organizations rely on a security standards organization to guide their approach. SysAdmin Audit Network Security (SANS) is a private organization that offers a six-step response framework, which is outlined below. Many organizations also adopt the National Institute of Standards and Technology (NIST) incident recovery framework.
    </div>
                <div className='font-bold text-xl py-6 grid  justify-start  items-start'> Preparation -</div>
                <div className='text-xl text-black'>
                Before an incident occurs, it’s important to reduce vulnerabilities and define security policies and procedures. In the preparation phase, organizations conduct a risk assessment to determine where they have weaknesses and prioritize assets. This phase includes writing and refining security procedures, defining roles and responsibilities, and updating systems to reduce risk. Most organizations regularly revisit this stage and make improvements to policies, procedures, and systems as they learn lessons or technologies change.
                </div>
                <div className='font-bold text-xl py-8 grid  justify-start  items-start'>
                Threat identification -
                </div>
                <div className='relative bottom-4 text-xl text-black'>
                In any given day, a security team may receive thousands of alerts that indicate suspicious activity. Some of them are false positives or may not rise to the level of an incident. Once an incident has been identified, the team digs into the nature of the breach and documents findings, including the source of the breach, the type of attack, and attacker goals. In this stage, the team also needs to inform stakeholders and communicate next steps
                </div>
                <div className='font-bold text-xl py-8 grid  justify-start  items-start'>
                Threat containment - 
                </div>
                <div className='relative bottom-4 text-xl text-black'>
                Containing a threat as quickly as possible is the next priority. The longer bad actors are allowed access, the greater damage they can do. The security team works to rapidly isolate applications or systems that are under attack from the rest of the networks. This helps prevent the attackers from accessing other parts of the business.
                </div>
                <div className='font-bold text-xl py-8 grid  justify-start  items-start'>
                Threat elimination -
                </div>
                <div className='relative bottom-4 text-xl text-black'>
                Once containment is complete, the team removes the attacker and any malware from affected systems and resources. This may involve taking systems offline. The team also continues to keep stakeholders informed of progress.
                </div>
                <div className='font-bold text-xl py-8 grid  justify-start  items-start'>
                Recovery and restoration - 
                </div>
                <div className='relative bottom-4 text-xl text-black'>
                Recovering from an incident may take several hours. Once the threat is gone, the team restores systems, recovers data from backup, and monitors affected areas to ensure the attacker doesn’t return.
                </div>
                
   
      </section>
        </div>
      </section>
    </div>
  );
};

export default IncidentResponsePlan;