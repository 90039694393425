import React from "react";
import img1 from "../../resources/images/home/footerLinks/sustainability/radSustainability.png";
import img2 from "../../resources/images/home/footerLinks/sustainability/nature1.jpg";
import img3 from "../../resources/images/home/footerLinks/sustainability/Sustainability5.webp";
import img4 from "../../resources/images/home/footerLinks/sustainability/Company20.jpg";
import img5 from "../../resources/images/home/footerLinks/sustainability/Sustainability6.jpeg";

const Sustainability = () => {
  return (
    <div className=" text-lg p-16">
      <div className="mt-4">
        <button
          onClick={Sustainability}
          className=" text-black  font-semibold "
        >
          Home
        </button>
        <div className=" relative">
          <img className="md:h-full sm:h-96 w-full " src={img1} alt="" />
          <div className="container mx-auto absolute md:top-1/2 top-10  md:w-1/2 md:p-4  text-white w-full text-[10px]  ">
            <p className="md:text-4xl font-semibold sm:text-[5px] ">
              Sustainability Solutions From Data Solutions
            </p>
            <p className="md:text-xl leading-3">
              Let&apos;s turn ambition into action. See how Data Solutions is
              using Envies to collect data from more than 6,500 utility bills a
              year, calculate emissions and track progress toward sustainability
              goals.
            </p>
          </div>
        </div>
      </div>

      <div className="">
        <h1 className="text-3xl font-semibold ">Sustainability & Climate</h1>
        <h4 className="text-lg ">
          The decisions we make today can impact the quality of life for
          generations to come. A better future is entirely possible, but it will
          require a profound and lasting change in attitudes and
          behavior&apos;s. And it will depend on sharing knowledge and
          experience more broadly than ever before. Data Solutions helps
          organization&apos;s make progress on their journey toward long-term
          sustainability and value creation. Together, let&apos;s reimagine the
          way we work – and build vibrant economies that are sustainable well
          into the future.
        </h4>

        <div className="mt-5">
          <h1 className="text-3xl font-semibold">
            Building a sustainable world. Together.
          </h1>
          <h4 className="text-lg ">
            Sustainability in business is more relevant than ever. You&apos;re
            under pressure to be transparent with your data and processes.
            We&apos;ve got you covered with sustainability management solutions
            for ESG reporting, climate action, circular economy, and social
            responsibility. Data Solution Sustainability solutions deliver
            company-wide functionality and industry-specific features that can
            help you incorporate sustainability in business at scale by
            embedding operations, experience, and financial insights into your
            core business processes.
          </h4>
        </div>
        <section className="flex flex-col md:flex-row items-center my-5 ">
          <img
            src={img2}
            alt="Demo"
            className="w-full md:w-1/2 rounded shadow-lg animate-pulse"
          />
          <div className=" px-5">
            <h1 className="text-3xl font-semibold ">
              Our approach to a sustainable world
            </h1>
            <p className="text-xl ">
              <h4>
                {" "}
                We owe it to you to lead by example. Data Solutions aspires to a
                world with zero emissions, zero waste, and zero inequality. Our
                corporate sustainability approach is to create positive
                economic, social, and environmental impact within planetary
                boundaries – with a special focus on climate action, circular
                economy, and social responsibility.
              </h4>
            </p>
          </div>
        </section>
        <div className="">
          <h1 className="text-3xl font-bold mb-5 ">
            How Data Solutions helps organization&apos;s towards
            <br /> a more sustainable future
          </h1>
          <h4 className="text-lg ">
            Data Solutions has helped organization&apos;s make progress toward
            delivering sustainable value for their stakeholders – from
            investors, customers, and business partners, to regulators,
            governments, and communities. Data Solutions serves
            organization&apos;s as they redefine their strategies, embed
            sustainability into their operations, meet disclosure and regulatory
            requirements and helps them accelerate the transformation of their
            organization&apos;s. Through innovative approaches, digital
            solutions, deep industry experience, and lessons learned from our
            own journey, Data Solutions works with organization&apos;s to
            meaningfully embed sustainability into decision-making and
            operations.
          </h4>
        </div>
        <div className="">
          <h1 className="text-3xl font-semibold">
            Sustainability and climate change technology
          </h1>
          <h4 className="text-lg ">
            By combining leading sustainability technology with our proven
            business capabilities, Data Solutions can help drive real and rapid
            progress on climate-led transformation. Tap into the depth and
            breadth of our sustainability technology solutions such as ESG
            Trace, GreenSpace Tech by Data Solutions and GreenLight Solution by
            Data Solutions, and collaborative alliance relationships with some
            of the largest technology firms to help solve your most complex
            challenges.
          </h4>
        </div>
        <h1 className="text-3xl font-bold ">Resources</h1>

        <section className="grid grid-cols-1 md:grid-cols-2 ">
          <div className="p-5 bg-white shadow rounded ">
            <img
              src={img3}
              alt="Demo"
              className="w-full md:w-full rounded shadow-lg"
            />
            <h2 className="text-2xl font-semibold mb-3 ">
              Get the latest sustainability insights
            </h2>
            <p className="">
              Sign up for our weekly newsletter to receive exclusive research,
              curated articles, and real-world examples on achieving
              sustainability
            </p>
          </div>

          <div className="p-5 bg-white shadow rounded">
            <img
              src={img4}
              alt="Demo"
              className="w-full md:w-full rounded shadow-lg"
            />
            <h2 className="text-2xl font-semibold mb-3 ">
              Our sustainability community
            </h2>
            <p>
              Sustainable communities are designed to achieve a balance between
              economic and social growth while considering the limits of natural
              resources. The main idea is to reduce the impact on the
              environment while also guaranteeing a good quality of life for its
              residents. The concept may seem a little complicated at first, but
              in this article I will explain what sustainable communities are
              and how they work. You will also find out about a cheap, immersive
              way to learn all about them.
            </p>
          </div>
        </section>
      </div>

      <div className=" ">
        <img className="h-[300px] w-full blur-sm" src={img5} alt="" />
        <div className="container mx-auto  relative bottom-48  text-white px-10">
          <h1 className="text-4xl font-semibold  text-center">
            {" "}
            Reach out to our experts and get started today
          </h1>
          <h2 className="text-2xl ">
            Industrial sustainability affects all companies, regardless of their
            size or market. Contact our experts and learn how to accelerate your
            digital transformation and become more sustainable!
          </h2>
        </div>
      </div>
    </div>
  );
};
export default Sustainability;
