import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar } from './components';
import About from './containers/about/About';
import Contact from './containers/contact/Contact';
import Partners from './containers/PartnersProducts/PartnersProducts';
import RequestADemo from './containers/requestADemo/RequestADemo';
import Services from './containers/Solutions&Capabilities/Solutions&Capability';
import ApplicationSecurity from './containers/Solutions&Capabilities/ApplicationSecurity';
import NetworkSecurity1 from './containers/Solutions&Capabilities/networkSecurity1';
import HomePage from './containers/homepage/HomePage';
import OurVision from './containers/ourvision/OurVision';
import Footer from './components/footer/footer';
import NetworkSecurity from './containers/homepage/homePagesTabs/NetworkSecurity';
import Firewall from './containers/homepage/homePagesTabs/Firewall';
import IntrusionDectionSystem from './containers/homepage/homePagesTabs/IntrusionDetectionSystem';
import IntrusionPreventionSystem from './containers/homepage/homePagesTabs/IntrusionPreventionSystem';
import VirtualPrivateNetwork from './containers/homepage/homePagesTabs/VirtualPrivateNetwork';
import Encryption from './containers/homepage/homePagesTabs/Encryption';
import Authentication from './containers/homepage/homePagesTabs/Authentication';
import DenialOfServiceAttack from './containers/homepage/homePagesTabs/DenialOfServiceAttack';
import ContinuousEducationAndTraining from './containers/homepage/homePagesTabs/securingForTomorrowPagesTabs/ContinuousEducationAndTraining';
import RegularSecurityAssessmentsAndAudits from './containers/homepage/homePagesTabs/securingForTomorrowPagesTabs/RegularSecurityAssessmentsAndAudits';
import PatchManagement from './containers/homepage/homePagesTabs/securingForTomorrowPagesTabs/PatchManagement';
import AccessControlAndLeastPrivilege from './containers/homepage/homePagesTabs/securingForTomorrowPagesTabs/AccessControlAndLeastPrivilege';
import NetworkSegmentation from './containers/homepage/homePagesTabs/securingForTomorrowPagesTabs/NetworkSegmentation';
import EncryptionAndDataProtection from './containers/homepage/homePagesTabs/securingForTomorrowPagesTabs/EncryptionAndDataProtection';
import IncidentResponsePlan from './containers/homepage/homePagesTabs/securingForTomorrowPagesTabs/IncidentResponsePlan';
import ThirdPartyRiskManagement from './containers/homepage/homePagesTabs/securingForTomorrowPagesTabs/ThirdPartyRiskManagement';
import SecurityAutomationAndOrchestration from './containers/homepage/homePagesTabs/securingForTomorrowPagesTabs/SecurityAutomationAndOrchestration';
import ExecutiveLeadershipAndSupport from './containers/about/ExecutiveLeadershipAndSupport';
import Network1Seg from './containers/homepage/homePagesTabs/Network1Seg';
import DataProtection from './containers/homepage/homePagesTabs/securingForTomorrowPagesTabs/DataProtection';
import ScrollToTop from './ScrollToTop';
import Sustainability from './containers/homepage/SustainabilityManagement';
import Center from './containers/about/DataSolutionsTrustCenter'
import Insights from './containers/homepage/DataSolutionsInsights';
import Community from './containers/about/DataSolutionsCommunity'
import Developer from './containers/about/Developer'
import Artificial from './containers/about/ArtificialIntelligence'
import Company from './containers/about/CompanyInformation' 
import Directory from './containers/about/WorldwideDirectory'
import Solutions from './containers/about/Solutions'
import Events from './containers/homepage/Events'
import Privacy from './containers/about/Privacy'
import RenewLicense from './containers/PartnersProducts/renewLicense'
import SubmitTicket from './containers/PartnersProducts/submitTicket'
import KnowledgeCenter  from './containers/PartnersProducts/knowledgeCenter'
import CustomerPortal  from './containers/PartnersProducts/customerPortal'
import Governance from './containers/PartnersProducts/Governance'
import Protection from './containers/PartnersProducts/Protection'
import Management from './containers/PartnersProducts/Management'
import Security from './containers/PartnersProducts/Security'
import Careers  from './containers/PartnersProducts/Careers'
import Team from './containers/PartnersProducts/Team'
import Customers from './containers/PartnersProducts/Customers'
import Analyst from './containers/PartnersProducts/Analyst'
import Legal from './containers/about/Legal'
import Copyright from './containers/about/Copyright'

import Penetration from './containers/Solutions&Capabilities/penetrationTesting'
//import Trademark from './containers/about/Trademark'
//import Services from './containers/About/Services'

import SiemSoc from './containers/Solutions&Capabilities/siemSoc'

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
      </div>
      <ScrollToTop />
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route path='/homepage' element={<HomePage />} />
        <Route path='/about' element={<About />} />
        <Route path='/OurVision' element={<OurVision />} />
        <Route path='/partners' element={<Partners />} />
        <Route path='/renewLicense' element={<RenewLicense/>} />
        <Route path='/submitTicket' element={<SubmitTicket/>} />
        <Route path='/knowledgeCenter' element={<KnowledgeCenter/>} />
        <Route path='/customerPortal' element={<CustomerPortal/>} />
        <Route path='/requestADemo' element={<RequestADemo />} />
        <Route path='/services' element={<Services />} />
        <Route path='/ApplicationSecurity' element={<ApplicationSecurity />} />
        <Route path='/networkSecurity1' element={<NetworkSecurity1 />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/homePage/networkSecurity' element={<NetworkSecurity />} />
        <Route path='/homePage/Network1Seg' element={<Network1Seg />} />
        <Route path='/homePage/firewall' element={<Firewall />} />
        <Route
          path='/homePage/intrusionDetectionSystem'
          element={<IntrusionDectionSystem />}
        />
        <Route
          path='/homePage/intrusionPreventionSystem'
          element={<IntrusionPreventionSystem />}
        />
        <Route
          path='/homePage/virtualPrivateNetwork'
          element={<VirtualPrivateNetwork />}
        />
        <Route path='/homePage/encryption' element={<Encryption />} />
        <Route path='/homePage/DataProtection' element={<DataProtection />} />
        <Route path='/homePage/authentication' element={<Authentication />} />
        <Route
          path='/homePage/denialOfServiceAttack'
          element={<DenialOfServiceAttack />}
        />
        <Route
          path='/homePage/continuousEducationAndTraining'
          element={<ContinuousEducationAndTraining />}
        />
        <Route
          path='/homePage/regularSecurityAssessmentsAndAudits'
          element={<RegularSecurityAssessmentsAndAudits />}
        />
        <Route path='/homePage/patchManagement' element={<PatchManagement />} />
        <Route
          path='/homePage/accessControlAndLeastPrivilege'
          element={<AccessControlAndLeastPrivilege />}
        />
        <Route
          path='/homePage/networkSegmentation'
          element={<NetworkSegmentation />}
        />
        <Route
          path='/homePage/encryptionAndDataProtection'
          element={<EncryptionAndDataProtection />}
        />
        <Route
          path='/homePage/incidentResponsePlan'
          element={<IncidentResponsePlan />}
        />
        <Route
          path='/homePage/thirdPartyRiskManagement'
          element={<ThirdPartyRiskManagement />}
        />
        <Route
          path='/homePage/securityAutomationAndOrchestration'
          element={<SecurityAutomationAndOrchestration />}
        />
        <Route
          path='/about/executiveLeadershipAndSupport'
          element={<ExecutiveLeadershipAndSupport />}
        />
           <Route
          path='/homepage/Sustainability'
          element={<Sustainability />} 
        /> 
        
       <Route
          path='/about/Center'
          element={<Center/>}
          />  

        <Route
          path='/homepage/Insights'
          element={<Insights/>}
        />
        <Route
          path='/about/Community'
          element={<Community />}
        />
        <Route
          path='/about/Developer'
          element={<Developer />}
        />
        <Route
          path='/about/Artificial'
          element={<Artificial />}
        />
        <Route
          path='/about/Company'
          element={<Company />}
        />
        <Route
          path='/about/Directory'
          element={<Directory />}
        />
        <Route
          path='/about/Solutions'
          element={<Solutions />}
        />
        <Route
          path='/homepage/Events'
          element={<Events />}
        />
        <Route
          path='/about/Privacy'
          element={<Privacy />}
        />
        <Route
          path='/about/Legal'
          element={<Legal />}
        />
        <Route
          path='/about/Copyright'
          element={<Copyright />}
        />

      </Routes>

      <Routes>
        <Route path='/about/Legal' 
        element={<Legal />} />
        <Route path='/about/Copyright' element={<Copyright />} />
        <Route path='/PartnersProducts/Governance' element={<Governance />} />

        <Route path='/PartnersProducts/Protection' element={<Protection />} />
        <Route path='/PartnersProducts/Management' element={<Management />} />
        <Route path='/PartnersProducts/Security' element={<Security />} />

           <Route
          path='/PartnersProducts/Careers'
          element={<Careers/>}
          />   
           <Route
          path='/PartnersProducts/Team'
          element={<Team/>}
          />    
           <Route
          path='/PartnersProducts/Customers'
          element={<Customers/>}
          />   
           <Route
          path='/PartnersProducts/Analyst'
          element={<Analyst/>}
          />   

          <Route
          path='/penetrationTesting'
          element={<Penetration />}
        />
           

           <Route
          path='/siemSoc'
          element={<SiemSoc/>}
          />   

        </Routes>

       <div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
