import React  from "react";
import image1 from "../../resources/images/partners/partnerLinks/customerPortal/portalDs.webp";
import image2 from "../../resources/images/partners/partnerLinks/customerPortal/customerPortalsAP.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faIdCard } from "@fortawesome/free-solid-svg-icons";
import { Link} from "react-router-dom";

const CustomerPortal = () => {
  return (
    <div className="min-h-screen md:px-10">
      <section className=" text-white py-20 p-6">
        <div className="flex justify-start items-center px-2  text-base py-5 ">
          <Link
            to="/Partners"
            className={` text-black  px-3 py-10
                }`}
          >
            Partners
            <FontAwesomeIcon icon={faArrowRight} className="px-2  text-black" />
          </Link>

          <div>
            <button className="  text-black">Customer Portal</button>
          </div>
        </div>
        <div className="container mx-auto  ">
          <section className=" text-black pt-10 bg-cyan-950  rounded-3xl py-16 ">
            <div className="container mx-auto   ">
              <div className="grid grid-cols-1 md:grid-cols-2 ">
                <div className="grid justify-center items-start px-5    ">
                  <h1 className="text-white text-4xl py-5">
                    What is a Customer Portal?
                  </h1>
                  <p className="text-white py-2">
                    A customer portal is a personalized website offering
                    customers a single point of access to relevant company
                    information and self-service options.
                  </p>
                </div>
                <img src={image1} alt="vision" className="h-72" />
              </div>
              <div className="flex justify-start px-12 py-2">
                <button className="  bg-green-600 rounded-xl text-white ">
                  <a
                    href="/requestADemo"
                    className="flex justify-center items-center p-2 w-28   "
                  >
                    Demo{" "}
                  </a>
                </button>
              </div>
            </div>
          </section>

          <section className="grid justify-center items-center py-5">
            <p className="text-gray-500 flex justify-center items-center ">
              When problems occur, questions arise, or issues related to your
              products or services need to be addressed, your customers depend
              on you to provide reliable service and support options. Contact
              centers and support agents can help ensure fast and effective
              remediation. However, sometimes the effort of picking up a phone,
              sending off an email, or even reaching out through social media
              can end up negatively impacting the customer experience. After
              all, customers want low-effort solutions, and often, that means
              handling things on their own. Customer portals place the customer
              in the driver’s seat, offering a single, personalized location
              where they can access the entirety of your business’ self-service
              functions. Through the customer portal, users can view and update
              account information, submit and review cases, access your
              knowledge base, interact within your community, and submit common
              requests from a catalog of service and support options.
            </p>
          </section>
          <section className="text-black">
            <h1 className="grid justify-start items-start text-3xl p-5 text-black font-bold border-l-4 border-red-500 relative bg-transparent before:rounded-r-xl transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-gray-300 before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100">
              Why are customer portals important?
            </h1>

            <p className="p-5">
              Gone are the days of the passive customer; modern customers are
              capable, informed, and tech-savvy, and they want answers on their
              time and are comfortable using self-service options. In many
              cases, customers who reach out to support agents only do so after
              attempting their own troubleshooting and online research. These
              customers want fast answers and straightforward solutions that
              will not require them to stay on the telephone or wait for an
              email response. Today’s customers expect a certain level of
              self-service availability. At the same time, they expect
              consistency and ease-of-access, regardless of the channel (or
              channels) they use. Successful businesses are those that are able
              to consistently meet or surpass customer expectations, making good
              customer self-service a competitive necessity.
            </p>
          </section>
          <section className=" text-black pt-10 bg-cyan-950  rounded-3xl py-16 p-8 ">
            <div className="container mx-auto   ">
              <div className="grid grid-cols-1 md:grid-cols-2 ">
                <img
                  src={image2}
                  alt="vision"
                  className=" h-52 px-5 rounded-3xl"
                />
                <div className="grid justify-center items-start px-5    ">
                  <h1 className="text-white text-2xl font-bold py-5 ">
                    Think beyond traditional CRM
                  </h1>
                  <p className="text-white py-2">
                    Discover the advantages of end-to-end solutions for seamless
                    quality customer experiences that go beyond traditional CRM
                    capabilities. Get Demo
                  </p>
                  <div className="flex justify-start px-12 py-5">
                    <button className="  bg-green-600 rounded-xl text-white ">
                      <a
                        href="/requestADemo"
                        className="flex justify-center items-center p-2 w-28   "
                      >
                        Demo{" "}
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="text-black py-3 z-10">
            <h1 className="grid justify-start items-start text-4xl p-2 py-3 text-black font-bold  border-l-4 border-red-500 relative bg-transparent before:rounded-r-xl transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-gray-300 before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100">
              ServiceNow self-service for customer portals
            </h1>
            <p className="p-5">
              ServiceNow provides the essential tools and infrastructure your
              business needs to create satisfying self-service options and
              effective customer portals. This best-in-breed solution
              encompasses a range of anytime, anywhere capabilities Service
              Portal allows customers to personalize the self-service experience
              across every channel. They can keep track of products and cases,
              check statuses, and easily and directly access all other available
              self-service options.AI-enhanced search provides customers with
              intelligent search suggestions and results summaries. Knowledge
              management also helps organizations identify gaps in their
              knowledge base, triggering workflows for new content curation.
            </p>
            <h1 className="grid justify-start items-start text-2xl  text-black font-bold px-2">
              More effective automation
            </h1>
            <p className="p-5">
              Customers who pursue their own research away from your platform
              can offer you no additional insight into their needs or
              expectations. By providing a secure customer portal website, you
              can track their journey and analyze how effectively you are
              meeting their needs, allowing for ongoing improvement of your
              essential business processes.
            </p>
            <h1 className="grid justify-start items-start text-2xl px-2 text-black font-bold">
              Enhanced customer satisfaction
            </h1>
            <p className="p-5">
              The end goal of employing a customer portal is simple: increasing
              customer satisfaction. By meeting customer expectations and
              allowing them to address issues of how, when, and where they are
              most comfortable, you enhance the customer experience.
            </p>
            <h1 className="grid justify-start items-start text-4xl p-2 py-3 text-black font-bold border-l-4 border-red-500 relative bg-transparent before:rounded-r-xl transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-gray-300 before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100">
              What are the basic elements of customer portals?
            </h1>

            <p className="p-5">
              There are many kinds of customer portals across nearly every
              industry, each with their own focus, functionality, and processes.
              That said, there are certain elements that are common throughout
              the most effective customer portals.Comprehensive and easily
              searchable, knowledge bases provide clear access to content to
              assist customers and agents in resolving service issues. Knowledge
              base articles could include guides, FAQs, troubleshooting tips,
              manuals, tutorials, downloads, and other helpful information. A
              service catalog provides customers with a virtual storefront where
              they can submit common requests for products or services. This
              allows customers to engage directly with relevant departments with
              workflow-driven solutions, empowering them to do even more through
              self-service.
            </p>
            <h1 className="grid justify-start items-start text-2xl px-2 text-black font-bold">
              Pre-defined service solutions
            </h1>
            <p className="p-5">
              A service catalog provides customers with a virtual storefront
              where they can submit common requests for products or services.
              This allows customers to engage directly with relevant departments
              with workflow-driven solutions, empowering them to do even more
              through self-service.
            </p>
          </section>
          <section className=" text-white pt-10  grid justify-start items-center   p-8 bg-gray-800">
            <h1 className=" flex  justify-start items-start text-xl capitalize py-3 ">
              Make customer service flow
            </h1>

            <p className=" flex justify-center items-center px-2 ">
              Results-driven solutions to help your company reach its full
              potential.
            </p>
            <div className="flex justify-start px-12 py-5 ">
              <button className="  border-green-800 border-2  rounded-xl text-white  ">
                <a
                  href="/requestADemo"
                  className="flex justify-center items-center p-2 w-32  "
                >
                  Demo{" "}
                </a>
              </button>
              <div className="px-5">
                <button className="     rounded-xl text-white ">
                  <a
                    href="/contact"
                    className="flex justify-center items-center p-2 w-28   "
                  >
                    contact{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="px-1.5  text-white"
                    />
                  </a>
                </button>
              </div>
            </div>
          </section>

          <section className="grid justify-end p-5   ">
            <button className="  bg-green-600 rounded-3xl text-black  hover:text-gray-700 fixed bottom-4 right-4 ">
              <a
                href="/contact"
                className="flex justify-center items-center  p-2 w-60 rounded-3xl  "
              >
                <FontAwesomeIcon icon={faIdCard} className="p-2" />
                How can I Help you ?{" "}
              </a>
            </button>
          </section>
        </div>
      </section>
    </div>
  );
};

export default CustomerPortal;
