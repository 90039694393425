import React from 'react';
import { useNavigate } from 'react-router-dom'
import image from '../../resources/images/about1/executiveLeader/Executive-Leadership (1).webp';
import image1 from '../../resources/images/about1/executiveLeader/Leadership-Framework-Graphic-Final.png';
import image2 from '../../resources/images/about1/executiveLeader/Executive-leadership-4123.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from "react-router-dom";


const ExecutiveLeadershipAndSupport = () => {
  const location = useLocation();
  return (
    <div className='min-h-screen'>
      <section className=' text-white py-20 p-5'>
        <div className='container mx-auto text-center'>
        <div className="flex justify-start items-center px-2  text-base py-5 ">
        <Link
                  to="/about"
                  className={` text-black  px-3 py-10
                  }`}
                >
                  About
                  <FontAwesomeIcon icon={faArrowRight} className='px-2  text-black' />
                </Link>
                
        <div>
          <button className="  text-black">Executive Leadership And Support
          </button>
        </div>
      </div>
        <section className='grid justify-center items-center  py-5 text-black '>
        <h1 className='grid justify-center items-center text-5xl py-5 text-black font-bold'>What is leadership?</h1>
        <p className='py-5'><span className='text-5xl'>A</span> <b className='text-xl'>ll leaders </b>,to a certain degree, do the same thing. Whether you’re talking about an executive, manager, sports coach, or schoolteacher, leadership is about guiding and impacting outcomes, enabling groups of people to work together to accomplish what they couldn’t do working individually. In this sense, leadership is something you do, not something you are. Some people in formal leadership positions are poor leaders, and many people exercising leadership have no formal authority. It is their actions, not their words, that inspire trust and energy.</p>
        <p className='py-5'>What’s more, leadership is not something people are born with—it is a skill you can learn. At the core are mindsets, which are expressed through observable behaviors, which then lead to measurable outcomes. Is a leader communicating effectively or engaging others by being a good listener? Focusing on behaviors lets us be more objective when assessing leadership effectiveness. The key to unlocking shifts in behavior is focusing on mindsets, becoming more conscious about our thoughts and beliefs, and showing up with integrity as our full authentic selves.</p>
        <img src={image2 } className='  px-56 rounded-3xl py-5'/>
        <p className='py-5'>There are many contexts and ways in which leadership is exercised. But, according to McKinsey analysis of academic literature as well as a survey of nearly 200,000 people in 81 organizations all over the world, there are four types of behavior that account for 89 percent of leadership effectiveness:</p>
        <ul className='grid justify-center items-center text-xl text-black py-3 capitalize'>
            <li className="p-2">being supportive</li>
           <li className="p-2">seeking different perspectives</li>
            <li className="p-2">solving problems effectively</li>
            <li className="p-2">operating with a strong results orientation</li>
        </ul>
        <p className='p-2'>Effective leaders know that what works in one situation will not necessarily work every time. Leadership strategies must reflect each organization’s context and stage of evolution. One important lens is organizational health, a holistic set of factors that enable organizations to grow and succeed over time. A situational approach enables leaders to focus on the behaviors that are most relevant as an organization becomes healthier.</p>
        </section>
      <h1 className=' text-4xl text-black  font-bold py-5' >How is leadership evolving?</h1>
      <p className='text-black '>In the past, leadership was called “management,” with an emphasis on providing technical expertise and direction. The context was the traditional industrial economy command-and-control organization, where leaders focused exclusively on maximizing value for shareholders. In these organizations, leaders had three roles: planners (who develop strategy, then translate that strategy into concrete steps), directors (who assign responsibilities), or controllers (who ensure people do what they’ve been assigned and plans are adhered to).</p>
      <img src={image } className='  rounded-3xl py-5'/>
      <h1 className=' text-4xl text-black  font-bold py-5' > What are the limits of traditional management styles?</h1>
      <p  className='text-black  py-5'>Traditional management was revolutionary in its day and enormously effective in building large-scale global enterprises that have materially   improved lives over the past 200 years. However, with the advent of the 21st century, this approach is reaching its limits. Traditional management was revolutionary in its day and enormously effective in building large-scale global enterprises that have materially improved lives over the past 200 years.</p>
      <img src={image1 } className='  rounded-3xl py-5'/>
        </div>
      </section>
    </div>
  );
};

export default ExecutiveLeadershipAndSupport;