import React from 'react';
import img1 from "../../resources/images/about1/aboutLink/developer/developer.png"
import img2 from "../../resources/images/about1/aboutLink/developer/aiDATA.png"


const Developer = () => {

    return (

        <div className='  text-lg p-10'>

            <div>
                <button onClick={Developer} className=" text-black p-10 font-semibold ">
                    About
                </button>
            </div>
            <img
                className='h-full w-full bg-cover relative bottom-8 '
                src={img1}
                alt='' />
            <div className='container mx-auto  relative bottom-72  px-4 text-back'>

                <p className='text-4xl font-semibold mb-5'>Data Solutions Developer Center
                </p>
                <p className='text-2xl'>
                    Here, you&apos;ll find learning journeys, blog posts, <br />videos and event listings, all curated exclusively <br />for Data Solutions developers.
                </p>
            </div>


            <div className=''>

                <p className='text-3xl font-semibold  text-center'>

                    Check out the Data Solutions Developer&apos;s Guide
                </p>
                <p className='text-2xl  text-center'>
                    In this informative Data Solutions CodeTalk, you&apos;ll learn all about the Data Solutions Developer&apos;s Guide -- what it is, how to use it, and how it can simplify your application development tasks.
                </p>

            </div>
            <div className=''>
                <p className='text-4xl font-bold  text-center'>
                    Benefits for developers
                </p>

            </div>
            <div className=''>
                <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10 '>
                    <div className='p-5 bg-white shadow rounded'>
                        <p className='text-2xl font-semibold '>Data Solutions with AI

                        </p>
                        <p>Improve customer experiences with personalized content and interactions.
                        </p>
                    </div>
                    <div className='p-5 bg-white shadow rounded'>
                        <p className='text-2xl font-semibold'>Leverage extensive libraries

                        </p>
                        <p>Accelerate time to production with extensions, AI workflows and pre trained models.


                        </p>
                    </div>
                    <div className='p-5 bg-white shadow rounded'>
                        <p className='text-2xl font-semibold mb-3'>Explore what&apos;s possible

                        </p>
                        <p>Lower the burden of risk and aid in new discoveries for organizations.


                        </p>
                    </div>
                </section>
                <section className='grid grid-cols-1 md:grid-cols-2  gap-8 my-10 '>
                    <div className='p-5 bg-white shadow rounded'>
                        <p className='text-2xl font-semibold mb-3'>Build securely


                        </p>
                        <p>Build with confidence provided by the security, support, and stability of the Azure and Data Solutions AI for Enterprise platforms.


                        </p>
                    </div>
                    <div className='p-5 bg-white shadow rounded'>
                        <p className='text-2xl font-semibold mb-3'>Unlock your potential


                        </p>
                        <p>
                            Enhance productivity while lowering costs with accelerated computing.</p>
                    </div>

                </section>
            </div>
            <section className='flex flex-col md:flex-row items-center my-10 '>

                <div className='' >
                    <p className='text-5xl font-bold '>
                        Build with a complete toolchain

                    </p>
                    <p className='text-3xl font-semibold mb-3 '>
                        Development tools and Platform

                    </p>
                    <p className='text-xl '>
                        Start building intelligent applications with trusted tools and platforms,with easy access to AI tools and services.

                    </p>

                </div>
                <img
                    src={img2}
                    alt='Demo'
                    className='w-full md:w-4/5 '
                />
            </section>


        </div>

    );
};
export default Developer;