import React  from 'react';
import { useNavigate } from "react-router-dom";
const Privacy = () => {
    let Navigate = useNavigate();


    let Privacy = () => {
        Navigate(-1);
    };

    return (

        <div className='  text-lg p-10'>
                <div>
                    <button onClick={Privacy} className=" text-black p-10 font-semibold ">
                        About
                    </button>
                </div>
                {/* <img
                    className='h-[400px] w-full '
                    src={img1}
                    alt='' /> */}
                <div className='container mx-auto bg-violet-950 h-[400px]  px-4 text-white'>

                    <h1 className='text-3xl font-bold p-20 '> Data Solutions Privacy Statement</h1>

                </div>

            

            <div className='text-2xl'>
                <p >Your privacy is important to us. This privacy statement explains the personal data Data Solutions processes, how Data Solutions processes it, and for what purposes.<br />

                    Data Solutions offers a wide range of products, including server products used to help operate enterprises worldwide, devices you use in your home, software that students use at school, and services developers use to create and host what’s next. References to Data Solutions products in this statement include Data Solutions services, websites, apps, software, servers, and devices.<br />

                    Please read the product-specific details in this privacy statement, which provide additional relevant information. This statement applies to the interactions Data Solutions has with you and the Data Solutions products listed below, as well as other Data Solutions products that display this statement.<br />

                    Young person may prefer starting with the Privacy for young person  page. That page highlights information that may be helpful for young people.<br />

                    For individuals in the United States, please refer to our U.S. State Data Privacy Notice and the Washington State Consumer Health Data Privacy Policy for additional information about the processing of your personal data, and your rights under applicable U.S. State data privacy laws.</p>
            </div>

            <p className='text-3xl font-bold'>
                Welcome!
            </p>

            <div className='text-xl'>
                <p>
                    Protecting the individual&apos;s privacy is crucial to the future of business. We have created this privacy statement to demonstrate our firm commitment to the individual’s right to data protection and privacy. It outlines how we handle information that can directly or indirectly identify an individual (“personal data”).
                </p>
                <p >
                    Respecting your time and with the goal of providing you with the quickest possible access to the relevant passages of the privacy statement, we offer you the following summary.
                </p>
            </div>

            <p className='text-3xl font-bold '>
                Summary
            </p>

            <div className='text-xl'>
                <p>
                    The privacy statement aims to explain what personal data is processed by Data Solutions, who uses your personal data, for what purpose, for how long, and explains what rights you have in this context.
                </p>
                <p>
                    Who collects and processes your personal data? Data Solutions does, in the form of the respective legal entity, being either Data Solutions in Austin, Tx or any another entity of the global group of Data Solutions companies. The Data Solutions.com Privacy Statement describes in detail which processing activities of which Data Solutions Group Entities apply.
                </p>
            </div>
        </div>



    );
};
export default Privacy;