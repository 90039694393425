import React from "react";
import "./services.css";
import applicationSecurity from "../../resources/images/home/denial/denial.webp";
import image from "../../resources/images/home/denial/computerSoftware.jpg";
import image1 from "../../resources/images/Solutions&Capabilities/applicationSecurity/insights1.jpg";
import { Link,useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import video from "../../resources/images/home/homepage/encryptionData/symmetricAsymmetric.jpg";


const ApplicationSecurity = () => {
  
 const location = useLocation();
  
  return (
    <div className="min-h-screen">
      <section className="text-white p-8 ">
      <div className='container mx-auto '>
      <div className="flex justify-start items-start px-16  text-base py-20 ">
      <Link
      to="/services"
      className={` text-black  px-3 
      }`}
    >
    Solutions & Capabilities
      <FontAwesomeIcon icon={faArrowRight} className='px-2  text-black' />
    </Link>
      <div>
        <button className="  text-black "> Application Security</button>
      </div>
    </div>
      <section className=" font-bold mb-5 text-black grid justify-center py-10 ">
      <h1 className="text-4xl font-bold mb-5 text-black grid justify-center  ">
        Application Security Work..?
        </h1>
        <p className=" px-5  text-slate-500">
        Application security works through a combination of security controls and best practices.

        Best practices include secure development practices so security holes aren’t inadvertently introduced into applications, along with API security and configuration issues too.
        
        Controls can be anything from good password hygiene to web application firewalls and internal network segmentation, a layered approach that reduces risk at each step.
        
        DevSecOps , code security and debugging tools can help with developer issues in general, but we’ll cover many more controls and best practices in the next section.
        </p>
        </section>
        <section>
        <div className="flex justify-center items-center flex-col md:flex-row ">
        <img src={video} className='  rounded-3xl'/>
        </div>
        </section>
        <section className="text-black">
        <h1 className='grid justify-start items-start text-3xl p-5 text-black font-bold' >What Are the Types of Application Security? </h1>
        <hr className='bg-red-500 h-1 w-3/5 '></hr>
        <p className="p-5">The security measures that AppSec requires depends on the type of application and risks involved. Public-facing web applications without important or sensitive information may not need as much security as, say, a customer database containing personally identifiable information (PII), but they nonetheless should be secured with proper dev and security practices to avoid security issues that could escalate.

        Here are the most common types of application software that AppSec teams will need to secure:</p>
        </section>
      </div>
      <h1 className='grid justify-start items-start text-2xl p-5  font-bold  text-slate-500' >
      Web Application Security </h1>
      <p className="px-8 text-black">Web application information is typically stored in various locations, depending on the application and its uses. These include configuration files, databases used to store sensitive information, log files used to monitor activity, certificates and keys used to establish and secure connections between web applications and users, and more.</p>
      <h4 className="  text-gray-700 font-bold p-5">Web application security checklist</h4>
      <p className=" text-black px-10">Web applications can be secured in a number of ways; here are nine of them.</p>
      <p className=" text-black py-5 px-5"><span className="text-base font-bold">Web application scanners </span>test your websites and web-facing apps for vulnerabilities. These tests typically use vulnerability scanners.</p>
      <p className='text-lg text-black grid justify-center items-start px-10' >
          <img src={applicationSecurity } className='  border-2  rounded-3xl'/></p>
       <h1 className="text-2xl text-black font-bold py-5 ">Top 7 Application Security Solutions</h1>
       <hr className='bg-red-500 h-1 w-2/4 '></hr>
      <section className='text-black text-xl  ' >
      <p className='text-black  flex justify-start items-center py-2  '>
      There are many application security products and services available to help organizations keep their applications safe. They range from scanners and code analysis tools to mobile security, API security, threat modeling tools and more. Here are eight application security vendors to consider:</p>
    
       <ol className='grid justify-start items-start px-8 list-disc gap-3 '>
         <li>Acunetix</li>
         <li>CheckMarx</li>
         <li>Ender Labs</li>
         <li>Rapid7 InsightAppSec</li>
         <li>Micro Focus Fortify</li>
         <li>Synopsys</li>
         <li>Veracode</li>
         
       </ol>
   </section>
      </section>
      <section className=" text-black  ">
            <div className="container mx-auto  ">
              <div className="grid grid-cols-1 md:grid-cols-2  p-8">
                <img src={image} alt="vision" className="h-96 p-1" />
                <div className="flex justify-center items-center text-center text-xl  ">
                <img src={image1} alt="vision" className="h-96 p-1"/>
                </div>
              </div>
            </div>
          </section>
          <section className=" font-bold mb-5 text-black grid justify-center items-start py-10 p-5 ">
          <h1 className="text-4xl font-bold mb-5 text-black grid justify-start  p-5 ">
          API Security
            </h1>
            <p className=" px-5  text-slate-500">
            Application Programming Interfaces (API) are growing in importance. They are the basis of modern microservices applications, and an entire API economy has emerged, which allows organizations to share data and access software functionality created by others. This means API security is critical for modern organizations.
            APIs that suffer from security vulnerabilities are the cause of major data breaches. They can expose sensitive data and result in disruption of critical business operations. Common security weaknesses of APIs are weak authentication, unwanted exposure of data, and failure to perform rate limiting, which enables API abuse.
            Like web application security, the need for API security has led to the development of specialized tools that can identify vulnerabilities in APIs and secure APIs in production.
            </p>
            </section>

    </div>
  );
};

export default ApplicationSecurity;
