import React from 'react';
import img1 from '../../resources/images/about1/aboutLink/artificial/cyber1.jpg';
import img2 from '../../resources/images/about1/aboutLink/artificial/AI2.jpg';


const Artificial = () => {
  return (
    <div className='  text-lg p-10'>
      <div className='  '>
        <button
          onClick={Artificial}
          className=' text-black p-10 font-semibold '
        >
          About
        </button>
        <div className="relative w-full h-full">
          <img className='h-full w-full  object-cover' src={img1} alt='' />
          <div className='absolute inset-0 flex flex-col justify-center items-center text-center px-5 text-white'>
            <p className='text-2xl sm:text-4xl font-bold mb-5 '>Data Solutions Business AI</p>
            <p className='text-lg sm:text-2xl font-semibold'>
              Revolutionary technology. Real-world results.
            </p>
          </div>
        </div>
      </div>
      <div className=''>
        <p className='text-5xl font-semibold   text-center '>
          Data Solutions AI 360
        </p>
        <p className='text-2xl  text-center '>
          We&apos;re fueling the next wave of AI-driven innovation by infusing
          AI across our ecosystem—into every tool, platform, and solution,
          across every business function, process, and practice. Data Solutions
          brings together all our AI capabilities, talent, and technology to
          help clients connect AI throughout their organizations. Because at
          Data Solutions we believe that combining human ingenuity with
          AI-powered technology is the key to unlocking the true value of AI.
        </p>
        <div className=''>
          <p className='text-4xl font-semibold  text-center '>
            Artificial intelligent into action
          </p>
          <p className='text-xl mb-5'>
            Artificial intelligence (AI) is the transformation technology of our
            times. It helps all of us make our businesses faster, stronger and
            more effective. But to trust AI technology, you cant&apos; achieve it by
            tossing money at the largest or flashiest AI models. You can achieve
            it by:{' '}
          </p>
          <li>
            {' '}
            Using the right AI and training it on the right data sets at the
            right time.
          </li>
          <li>
            Running AI models from anywhere with secure access to your data
            wherever it is stored.
          </li>
          <li>
            {' '}
            Prioritizing safety, control and compliance with a holistic AI
            governance approach.
          </li>
          <li>
            {' '}
            Using AI built on ethical principles, which are designed for the
            many, not the few.
          </li>
          <li>
            {' '}
            Extending your team&apos;s capabilities to reach new heights of
            productivity and performance.
          </li>

          <p className='text-xl '>
            With unparalleled experience in solving the world’s biggest business
            problems, Data Solutions can assist you wherever you are on your AI
            journey.
          </p>
        </div>

        <section className='flex flex-col md:flex-row items-center  '>
          <p className='text-4xl font-semibold '>
            Building a Responsible Future : Incorporating AI Regulations in AI
            Systems
            <br />
          </p>
          <p className='text-2xl '>
            {' '}
            Our paper underscores the imperative of nuanced regulation, ensuring
            that AI systems maintain reliability, robustness, and
            trustworthiness, all while steering clear of a rigid,
            one-size-fits-all approach.
          </p>

          <img
            src={img2}
            alt='Demo'
            className='w-full md:w-3/4 rounded shadow-lg '
          />
        </section>
        <p className='text-3xl font-semibold text-center'>
          AI built for business
        </p>
        <p className='text-2xl  text-center'>
          Achieve real-world results and attain your full potential with
          embedded AI capabilities across
          <br /> your business that leverage your data responsibly.
        </p>
        <br />
        <p className='text-3xl font-semibold  text-center'>
          Explore AI across your business
        </p>
        <p className='text-2xl  text-center'>
          See how you can benefit from AI built into your core business
          processes — connecting finance, <br />
          supply chain, procurement, sales, marketing, human resources, and IT.
        </p>
        <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10 '>
          <div className='p-5 bg-white shadow rounded'>
            <p className='text-2xl font-semibold '>
              Drive intelligent HR self-service capabilities
            </p>
            <p>
              Save time and improve efficiency by streamlining and automating HR
              processes with Joule. From approving and requesting time off to
              giving and requesting feedback, Joule helps to get your everyday
              HR tasks done faster and smarter.
            </p>
          </div>

          <div className='p-5 bg-white shadow rounded'>
            <p className='text-2xl font-semibold'>
              Identify new market opportunities and growth areas
            </p>
            <p>
              Accelerate strategy and planning processes across multiple
              categories with AI. Reduce onboarding time and reliance on more
              senior category managers, and gain time per category and adapt to
              market change with insight and agility.
            </p>
          </div>

          <div className='p-5 bg-white shadow rounded'>
            <p className='text-2xl font-semibold '>
              Supercharge sales, boost e-commerce, and improve customer service
            </p>
            <p>
              Serve customers better by answering questions proactively using
              trusted company data. Use AI to generate responses, case
              overviews, and more with prompts built for customer service teams.
              Create discovery questions and emails built for sales teams.
            </p>
          </div>
        </section>

        <div className='h-[200px] text-white bg-blue-700 p-10 text-center'>
          <p className='text-4xl font-semibold '>Navigate your AI journey</p>
          <p className='text-2xl'>
            Get in touch with us to learn more about Data Solutions.ai, a
            holistic approach to AI.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Artificial;
