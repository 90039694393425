import React  from 'react';
import {  useNavigate } from 'react-router-dom';
const Copyright = () => {
    let Navigate = useNavigate();
    let Copyright = () => {
        Navigate(-1);
    };

    return (

        <div className='text-lg p-10'>
            <div>
                <button onClick={Copyright} className=" text-black p-10 font-semibold ">
                    About

                </button>
            </div>

            {/* <img
                className='h-2/3 w-full bg-cover  '
                src={img1}
                alt='' /> */}
            <div className='container mx-auto h-[300px] bg-green-950 px-4 text-white'>
                <p className='text-3xl font-bold p-20'> Copyright</p>


            </div>
            <div className='my-5'>
                <p className='text-3xl font-bold mb-5 '>
                    Copyright Information</p>
            </div>
            <div className='my-5'>
                <p className='text-2xl  font-semibold '>Use of Data Solutions Copyrighted Materials
                </p>
                <p className='text-2xl '>
                    Data Solutions has made substantial investments in its software and services.  These materials are protected by intellectual property laws worldwide, including copyright laws.  Data Solutions software products, services, web content (including videos and images) and related materials are owned by Data Solutions (hereinafter Data Solutions Materials) and/or its licensors, and may only be used in accordance with the guidelines below or as covered by an agreement between you and Data Solutions (such as a Software License Agreement, Services Agreement, EULA, Terms of Use, PartnerEdge agreement or other agreement).
                </p>
            </div>
            <div className='my-5'>
                <p className='text-2xl mb-5 font-semibold '>
                    Reporting infringement to Data Solutions

                </p>
                <p className='text-xl '>
                    Data Solutions respects the intellectual property of others, and we ask our users to do the same. Data Solutions may, in appropriate circumstances and at its discretion, terminate the access/accounts of users who infringe the intellectual property rights of others. If you believe that your work has been copied in a way that constitutes copyright infringement or any other violation of your rights on an Data Solutions website, please provide the following information in writing to Data Solutions&apos;s Copyright Agent<br />
                    An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;<br />
                    A description of the copyrighted work that you claim has been infringed or material which otherwise violates your rights (or, if you claim that multiple copyrighted works at a single online site are infringing, a representative list of such works at that site);<br />
                    A description of where the material that you claim is infringing/violating is located on the site;<br />
                    your address, telephone number, and e-mail address;
                    A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;<br />
                    A statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are, or are authorized to act on behalf of, the copyright owner or the owner of an exclusive copyright right that is allegedly being infringed.</p>
            </div>



        </div>

    );
};
export default Copyright;