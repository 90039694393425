import React from 'react';
import image from '../../../resources/images/home/denial/denial.webp';
import image1 from '../../../resources/images/home/denial/computerSoftware.jpg';
import image2 from '../../../resources/images/home/denial/attackService.png';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const DenialOfServiceAttack = () => {
  let Navigate = useNavigate();
  let Denial = () => {
    Navigate(-1);
  };
  return (
    <div className="min-h-screen md:px-10">
      <section className=" text-white py-20">
        <div className="container mx-auto text-center">
          <div className="flex justify-start items-center  text-base py-5 ">
            <div>
              <button onClick={Denial} className=" text-black ">
                Home
                <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
              </button>
            </div>
            <div>
              <button className="  text-black">
                {" "}
                Denial Of Service Attack
              </button>
            </div>
          </div>

          <section className=" text-black pt-10 bg-blue-50  rounded-3xl py-16">
            <div className="container mx-auto p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="">
                  <img
                    src={image}
                    alt="vision"
                    className="h-96 min-w-96 border-2 shadow-2xl rounded-2xl"
                  />
                </div>
                <div className="grid justify-center items-center">
                  <div className="flex justify-center items-center text-3xl font-bold  py-8 ">
                    Denial of Service (DoS) Attack
                  </div>
                  <p className="flex justify-center   font-bold text-2xl">
                    Buffer overflow attacks
                  </p>
                  <p className="flex justify-center  ">
                    A denial-of-service (DoS) attack is a malicious attempt to
                    overwhelm a web property with traffic in order to disrupt
                    its normal operations.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className=" text-black pt-10">
            <div className="container mx-auto p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="grid justify-center items-center text-center text-xl ">
                  <div className="flex justify-start items-center text-3xl font-bold">
                    What is a denial-of-service attack?
                  </div>
                  <p className=" text-base">
                    A denial-of-service (DoS) attack is a type of cyber attack
                    in which a malicious actor aims to render a computer or
                    other device unavailable to its intended users by
                    interrupting the device's normal functioning
                  </p>
                </div>
                <img src={image1} alt="vision" className=" rounded-3xl" />
              </div>
            </div>
          </section>
          <section className=" text-black pt-10 bg-blue-50  rounded-3xl py-16">
            <div className="container mx-auto p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="">
                  <img
                    src={image2}
                    alt="vision"
                    className="h-96 min-w-96 border-2 shadow-2xl rounded-2xl"
                  />
                </div>
                <div className="grid justify-center items-center p-2">
                  <div className="flex justify-center items-center text-2xl font-bold  py-8 ">
                    How can you tell if a computer is experiencing a DoS attack?
                  </div>
                  <p className="flex justify-center  ">
                    While it can be difficult to separate an attack from other
                    network connectivity errors or heavy bandwidth consumption,
                    some characteristics may indicate an attack is underway.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-5">
            <div className="bg-white p-10 rounded-lg  ">
              <h3 className="text-black text-xl font-semibold mb-4">
                System-targeted denial-of-service
              </h3>
              <p className="text-gray-600">
                These attacks focus on undermining the usability of targeted
                systems. Resource depletion is a common attack vector, where
                limited system resources are intentionally by the attach normal
                operations.
              </p>
            </div>
            <div className="bg-white p-10 rounded-lg  ">
              <h3 className="text-black text-xl font-semibold mb-4">
                Distributed denial-of-service (DDoS)
              </h3>
              <p className="text-gray-600">
                Referred to as a “bandwidth consumption attack,” the attacker
                will attempt to use up all available network bandwidth such that
                legitimate traffic can no longer pass to/from targeted systems.
              </p>
            </div>
            <div className="bg-white p-10 rounded-lg ">
              <h3 className="text-black text-xl font-semibold mb-4">
                Network-targeted denial-of-service
              </h3>
              <p className="text-gray-600">
                Originally, DoS attacks involved one single system attacking
                another. While a DoS attack could be carried out in similar
                fashion today DoS attacks involve a number of systems
              </p>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default DenialOfServiceAttack;
