import React from "react";
import image from "../../resources/images/partners/partnerLinks/submitTicket/SubmitTicket.jpg";
import image1 from "../../resources/images/partners/partnerLinks/submitTicket/ticketTypes1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const SubmitTicket = () => {
  return (
    <div className="min-h-screen md:px-10 ">
      <section className=" text-white py-20">
        <div className="flex justify-start items-center px-2  text-base py-5 ">
          <Link
            to="/Partners"
            className={` text-black  px-3 py-10
                }`}
          >
            Partners
            <FontAwesomeIcon icon={faArrowRight} className="px-2  text-black" />
          </Link>

          <div>
            <button className="  text-black">Submit a Ticket</button>
          </div>
        </div>
        <div className="container mx-auto  p-2 ">
          <section className=" text-black pt-10 bg-cyan-800 rounded-3xl py-10">
            <div className="container mx-auto p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 ">
                <div className="grid justify-start items-center">
                  <div className="flex justify-center items-center text-4xl font-bold  text-white py-8 ">
                    What is an IT ticketing system?
                  </div>
                  <p className="flex justify-center text-xl text-white ">
                    An IT ticketing system is a tool used to track IT service
                    requests, events, incidents, and alerts that might require
                    additional action from IT.
                  </p>
                </div>
                <div className="">
                  <img
                    src={image}
                    alt="vision"
                    className=" border-2 shadow-2xl rounded-2xl"
                  />
                </div>
                <div>
                  <button className=" flex justify-start  items-start  py-5 ">
                    <a
                      href="/contact"
                      className="flex justify-center items-center hover:bg-orange-300 bg-gray-100 h-12 w-28 rounded-2xl "
                    >
                      contact{" "}
                    </a>
                  </button>
                </div>
                <div>
                <button className=" flex justify-start  items-start py-5">
                  <a
                    href="/requestADemo"
                    className="flex justify-center items-center hover:bg-orange-300 bg-gray-100 h-12 w-28 rounded-2xl "
                  >
                    Demo{" "}
                  </a>
                </button>
              </div>
              </div>
             
            </div>
          </section>
          <section>
            <div className="container mx-auto text-start grid justify-start items-start  grid-col gap-5  p-10  ">
              <i>
                {" "}
                <div className="text-2xl font-extrabold text-black  ">
                  Things to know about IT ticketing system
                </div>
              </i>
              <ol className="grid justify-start items-start text-base text-black list-disc px-3">
                <li className="py-3  text-xl underline italic hover:bg-lime-100">
                  {" "}
                  What is a ticket?
                </li>
                <li className="py-3 text-xl underline italic hover:bg-lime-100">
                  Why do organizations need a ticketing system?
                </li>
                <li className="py-3 text-xl underline italic hover:bg-lime-100 ">
                  Components of IT ticketing systems
                </li>
                <li className="py-3 text-xl underline italic hover:bg-lime-100">
                  Going beyond ticketing with ITSM{" "}
                </li>
                <li className="py-3 text-xl underline italic hover:bg-lime-100">
                  ITSM vs. ticketing systems{" "}
                </li>
                <li className="py-3 text-xl underline italic hover:bg-lime-100">
                  Benefits of ServiceNow ITSM
                </li>
                <li className="py-3 text-xl underline italic hover:bg-lime-100 ">
                  Digital transformation with ServiceNow{" "}
                </li>
              </ol>
              <p className="text-black text-xl">
                Ticketing software allows organizations to resolve their
                internal IT issues by streamlining the resolution process. The
                elements they handle, called tickets, provide context about the
                issues, including details, categories, and any relevant tags.
              </p>
            </div>
          </section>
          <div className="p-4">
          <h1 className="text-black text-4xl ">What is a ticket?</h1>
          <hr className='bg-red-500 h-1 w-2/4  '></hr>
          <div className=" py-10">
                  <img
                    src={image1}
                    alt="vision"
                    className="   rounded-2xl"
                  />
                </div>
          <p className="text-black text-xl py-5">Within an employee IT ticketing system, a ticket is a special document or record that represents an incident, alert, request, or event that requires action from the IT department. It often contains additional contextual details and may also include relevant contact information of the individual who created the ticket.

          Tickets are usually employee-generated, but automated tickets may also be created when specific incidents occur and are flagged. Once a ticket is created, it is assigned to an IT agent to be resolved. Effective ticketing systems allow tickets to be submitted via a variety of methods. These include submissions through virtual agents, phone, email, service portals, live agents, walk-up experience, etc.</p>
        </div>
        
        </div>
      </section>
    </div>
  );
};

export default SubmitTicket;
