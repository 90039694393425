import React from 'react';


const Security = () => {

  return (

    <div className=' text-lg p-16 '>
      <div className='h-16'></div>
      <div className='pb-4'>
        <button onClick={Security} className=" text-black  font-semibold">
          Products & Products
        </button>
      </div>
      <div className=''>
        <div className='container mx-auto text-white text-center h-72 bg-purple-800 md:rounded-b-full flex flex-col justify-center '>
          <p className='md:text-6xl font-bold  '>
            Active Directory Security </p>

        </div>

        <div className='my-10'>
          <p className='text-5xl font-bold'>
            What is Active Directory?
          </p>
          <p className='  text-2xl py-3'>
            Active Directory (AD) is a Data Solutions directory service that allows IT administrators to manage users, applications, data, and various other aspects of their organization&apos;s network. Active Directory security is vital to protect user credentials, company systems, sensitive data, software applications, and more from unauthorized access. A security compromise of AD can essentially undermine the integrity of your identity management infrastructure, leading to catastrophic levels of data leakage and/or system corruption/destruction.
          </p>
        </div>
        <div className='my-10'>
          <p className='text-5xl font-bold'>
            Why It Is Critical to Secure the Active Directory System

          </p>
          <p className='  text-2xl py-3'>
            Since AD is central to authorizing users, access, and applications throughout an organization, it is a prime target for attackers. If a cyber attacker is able to access the AD system, they can potentially access all connected user accounts, databases, applications, and all types of information. Therefore, an Active Security compromise, particularly those that are not caught early, can lead to widespread fallout from which it may be difficult to recover.

          </p>
        </div>
        <div className='my-10'>
          <p className='text-5xl font-bold'>
            What is a security group in Active Directory?

          </p>
          <p className='  text-2xl py-3'>
            Active Directory has two forms of common security principals: user accounts and computer accounts. These accounts represent a physical entity that is either a person or a computer. A user account also can be used as a dedicated service account for some applications.
          </p>
          <p className='text-xl  '>
            Security groups are a way to collect user accounts, computer accounts, and other groups into manageable units.<br />

            In the Windows Server operating system, several built-in accounts and security groups are pre configured with the appropriate rights and permissions to perform specific tasks. In Active Directory, administrative responsibilities are separated into two types of administrators:
          </p>
          <div className='mt-5 '>
          <li > <b>Service administrators: </b>Responsible for maintaining and delivering Active Directory Domain Services (AD DS), including managing domain controllers and configuring AD DS.</li>
          <li ><b>Data administrators:</b>Responsible for maintaining the data that&apos;s stored in AD DS and on domain member servers and workstations.</li>
          </div>
        </div>

        <div className='my-10'>
          <p className='text-5xl font-bold'>
            How Active Directory security groups work

          </p>
          <p className='  text-2xl py-3'>
            Use groups to collect user accounts, computer accounts, and other groups into manageable units. Working with groups instead of with individual users helps you simplify network maintenance and administration.

          </p>
          <p className='text-xl'>
            Active Directory has two types of groups:
          </p>
          <div className='pt-5 '>
            <li > <b>Security groups:  </b>
              Used to assign permissions to shared resources.
            </li>
            <li><b>Distribution groups: </b>
              Used to create email distribution lists.
            
          </li>
          </div>
        </div>

      </div>
    </div>

  );
};
export default Security;