import React from 'react';
import img1 from "../../resources/images/about1/aboutLink/dataTrust/trust1.jpg"
import img2 from "../../resources/images/about1/aboutLink/dataTrust/trust2.jpeg"


const Center = () => {


  return (

    <div className='text-lg p-10'>
      <div className='relative' >
        <div >
          <button onClick={Center} className=" text-black font-semibold p-10 ">
            About
          </button>
        </div>
        <img
          className='h-full w-full '
          src={img1}
          alt='' />
        <div className='container mx-auto  absolute  md:bottom-96 top-24 px-10  py-8   text-black'>

          <p className='md:text-4xl font-semibold md:mb-5'>Data Solutions Trust Center</p>
          <p className='md:text-2xl  ' >
            Find the information you need on security, compliance, <br />privacy, and Data Solutions service performance.</p>
        </div>

      </div>


      <div className=''>
        <p className='text-3xl font-semibold mb-5 '>
          Every day, organizations around the world trust Data Solutions with their data
        </p>
        <p className='text-lg '>
          Data Solutions fosters trust through responsible actions in the context of security, privacy, compliance and transparency. We build secure-by-design solutions, help you meet regulatory and compliance requirements, collect and process personal data lawfully, and share information about our processes with anyone who wants to understand how we protect their data.
        </p>

        <p className='text-3xl font-bold mb-5 '>
          Fostering trust with you and your customers
        </p>
      </div>
      <section className='flex flex-col md:flex-row items-center my-10 '>
        <img
          src={img2}
          alt='Demo'
          className='w-full md:w-1/2 rounded shadow-lg'
        />
        <div className='p-5'>
          <p className='text-3xl font-semibold '>
            The secure software development lifecycle at Data Solutions
          </p>
          <p className='text-2xl'>
            Data Solutions has implemented a secure software development lifecycle for software development projects. Discover how secure SDL provides a framework for training, tools, and processes.

          </p>

        </div>
      </section>
      <div className=''>
        <p className='text-4xl font-bold mb-3'>
          Purpose
        </p>
        <p className='text-2xl'>

          When you choose Data Solutions, you gain the strength, experience, expertise, and reliability of a recognized global IT security and privacy leader. The Data Solutions Trust Center will provide you with information about our commitment to protect your data, and maintain the security and privacy for our enterprise, products and services. You will find information on Data Solution&apos;s internal IT security management program, privacy framework, and adherence to industry standards of our products and services.

        </p>

      </div>

      <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10'>
        <div className='p-5 bg-white shadow rounded'>
          <p className='text-2xl font-semibold mb-3'>
            About  Data Solutions
          </p>
          <p>
            Data Solutions is committed to being a responsible technology innovator and a force for good in the world.
          </p>
        </div>
        <div className='p-5 bg-white shadow rounded'>
          <p className='text-2xl font-semibold mb-3'>
            Data Solutions Business Conduct Guidelines

          </p>
          <p>
            Principles of business ethics and lawful conduct for all employees.


          </p>
        </div>
        <div className='p-5 bg-white shadow rounded'>
          <p className='text-2xl font-semibold mb-3'>
            Data Solutions Corporate Responsibility
          </p>
          <p>
            Pursuit of the highest standards supporting and empowering employees, working with customers and suppliers, and governing our company.
          </p>
        </div>
      </section>
      <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10'>
        <div className='p-5 bg-white shadow rounded'>
          <p className='text-2xl font-semibold mb-3'>
            Data Solutions Security Vulnerability Management (PSIRT)
          </p>
          <p>
            Comprehensively addressing security vulnerabilities in Data Solutions products and websites.
          </p>
        </div>
        <div className='p-5 bg-white shadow rounded'>
          <p className='text-2xl font-semibold mb-3'>
            Data Solutions Compliance Request Tool
          </p>
          <p>
            Search for and download some of  Data Solutions&apos;s most commonly requested compliance reports.
          </p>
        </div>
        <div className='p-5 bg-white shadow rounded'>
          <p className='text-2xl font-semibold mb-3'>
            Data Solutions Cloud Compliance Programs
          </p>
          <p>
            Compliance and trust programs to help meet your security and privacy requirements for data and application protection.
          </p>
        </div>
      </section>

    </div>

  );
};
export default Center;