import React from 'react';
import image from '../../../../resources/images/home/homepage/continuous/earthShare.jpg'
import image1 from '../../../../resources/images/home/denial/computerSoftware.jpg'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';


const ContinuousEducationAndTraining = () => {
  let Navi= useNavigate()
  let Handlesay=()=>{
    Navi(-1)
  
  }
  return (
    <div className='min-h-screen'>
    <section className=' text-white  py-16 p-8'>
 
    <div className="flex justify-start items-center px-8  text-base py-5 ">
    <div>
      <button onClick={Handlesay} className=" text-black ">
        Home
        <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
      </button>
    </div>
    <div>
      <button className="  text-black">Continuous Education And Training </button>
    </div>
  </div>
    
      <h2 className='flex justify-center text-black text-5xl font-bold mb-4 px-3'>
      Continuous Education And Training
      </h2>
     <div className='container mx-auto grid justify-center '>
          <section className=' text-black pt-10'>
            <div className='container mx-auto p-4 '>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <img src={image} alt='vision'  />
                <div className='flex justify-center items-center text-center text-xl '>
                 Network security is defined as the activity created to protect the integrity of your network and data. Every company or organization that handles a large amount of data, has a degree of solutions against many cyber threats. 
                 Any action intended to safeguard the integrity and usefulness of your data and network is known as network security. This is a broad, all-encompassing phrase that covers software and hardware solutions, as well as procedures, guidelines, and setups for network usage, accessibility, and general threat protection.
                </div>
              </div>
            </div>
          </section>
          <section className='text-black text-xl p-3 ' >
              <p className='text-black text-xl flex justify-start items-center py-5 '>
              The most basic example of Network Security is password protection which the user of the network chooses. In recent times, Network Security has become the central topic of cyber security with many organizations inviting applications from people who have skills in this area. The network security solutions protect various vulnerabilities of the computer systems such as:</p>
              <ol className='grid justify-start items-start px-8 list-decimal gap-3'>
                <li>Users</li>
                <li>Locations</li>
                <li>Data</li>
                <li>Devices</li>
                <li>Applications</li>
              </ol>
          </section>
              <h2 className='flex justify-start text-black text-4xl font-bold mb-4 p-3'>
               Types of Training
              </h2>
              <div className='text-black text-xl flex justify-start items-start  '>
              There are several types of network security through which we can make our network more secure, Your network and data are shielded from breaches, invasions, and other dangers by network security. Here below are some important types of network security:
            </div>
            <section  className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-5'>
  
            <div className='bg-white p-10 rounded-lg  '>
              <h3 className='text-black text-xl font-semibold mb-4'>
              System-targeted
              </h3>
              <p className='text-gray-600'>
              These attacks focus on undermining the usability of targeted systems. Resource depletion is a common attack vector, where limited system resources are intentionally  by the attach normal operations.
              </p>
                
            </div>
            <div className='bg-white p-10 rounded-lg  '>
            <h3 className='text-black text-xl font-semibold mb-4'>
            Distributed 
            </h3>
            <p className='text-gray-600'>
            Referred to as a “bandwidth consumption attack,” the attacker will attempt to use up all available network bandwidth such that legitimate traffic can no longer pass to/from targeted systems.
            </p>
              
          </div>
          <div className='bg-white p-10 rounded-lg '>
          <h3 className='text-black text-xl font-semibold mb-4'>
          Network-targeted 
          </h3>
          <p className='text-gray-600'>
          Originally, DoS attacks involved one single system attacking another. While a DoS attack could be carried out in similar fashion today DoS attacks involve a number of systems
          </p>
          </div>
      </section>
       <section>
       <p className='text-lg text-black grid justify-start items-start py-5' >
       <img src={image1 } className=' border-2  rounded-3xl'/></p>
       
       </section>
       <h2 className='flex justify-start text-black text-5xl font-bold mb-4 p-'>
      What is Continuous Education?
      </h2>
      <hr className='bg-red-500 h-1 w-2/4 '></hr>
       <section  className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-5'>
  
       <div className='bg-white p-10  rounded-lg shadow-2xl  '>
         <h3 className='text-black text-xl font-semibold mb-4'>
         System-targeted
         </h3>
         <p className='text-gray-600'>
         These attacks focus on undermining the usability of targeted systems. Resource depletion is a common attack vector, where limited system resources are intentionally  by the attach normal operations.
         </p>
           
       </div>
       <div className='bg-white p-10  rounded-lg shadow-2xl '>
       <h3 className='text-black text-xl font-semibold mb-4'>
       Distributed 
       </h3>
       <p className='text-gray-600'>
       Referred to as a “bandwidth consumption attack,” the attacker will attempt to use up all available network bandwidth such that legitimate traffic can no longer pass to/from targeted systems.
       </p>
         
     </div>
     <div className='bg-white p-10  rounded-lg shadow-2xl'>
     <h3 className='text-black text-xl font-semibold mb-4 '>
     Network-targeted 
     </h3>
     <p className='text-gray-600'>
     Originally, DoS attacks involved one single system attacking another. While a DoS attack could be carried out in similar fashion today DoS attacks involve a number of systems
     </p>
     </div>
 </section>
        </div>
      </section>
    </div>
  );
};

export default ContinuousEducationAndTraining;