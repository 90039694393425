import React from 'react';
import img1 from '../../resources/images/about1/aboutLink/dataCommunity/img5.png';
import { useNavigate } from 'react-router-dom';
const Community = () => {
  let Navigate = useNavigate();

  let Community = () => {
    Navigate(-1);
  };
  return (
    <div className='  text-lg p-10'>
      <div>
        <button onClick={Community} className=' text-black p-10 font-semibold '>
          About
        </button>
      </div>
      <img className='h-full w-full object-cover' src={img1} alt='' />
      <div className='container mx-auto  md:relative bottom-64 md:left-24 px-4 text-black'>
        <h1 className=' text-3xl md:text-5xl font-semibold mb-5'>
          Data Solutions Community
        </h1>
        <h1 className='text-xl md:text-2xl'>
          Connect and engage with our community to get answers, <br />
          discuss best practices, and continually learn more about Data
          Solutions.
        </h1>
      </div>

      <div className=''>
        <div className='my-10'>
          <h1 className='text-2xl  mb-5  text-center'>
            Data Solutions Developer exists to bring coders together as a
            community, to tap into our collective innovative power. As part of
            the Data Solutions Developer community you can meet us at upcoming
            events, connect with a developer expert, become an Data Solutions
            technology advocate, or keep up with the news in our quickly
            evolving technology landscape. The choice is yours, and we are
            excited to have you with us!
          </h1>
        </div>

        <div className='my-10'>
          <h1 className='text-3xl font-semibold mb-5 '>Community Resources </h1>
          <h2 className=' text-2xl'>
            These materials will help you participate and connect with other
            members.
          </h2>
        </div>
        <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10'>
          <div className='p-5 bg-white shadow rounded'>
            <h2 className='text-2xl font-semibold mb-3'>Rules of Engagement</h2>
            <p>
              Data Solutions Community participants should be respectful,
              supportive, and professional. Get to know the rules that all
              members should follow, as well as the actions that could lead to
              the removal of a member&apos;s content.
            </p>
          </div>
          <div className='p-5 bg-white shadow rounded'>
            <h2 className='text-2xl font-semibold mb-3'>
              Data Solutions Community Tutorial
            </h2>
            <p>
              The Tour the Community tutorial provides valuable insight into
              navigating and participating in the community, covering everything
              from the basics to blogging.
            </p>
          </div>
          <div className='p-5 bg-white shadow rounded'>
            <h2 className='text-2xl font-semibold mb-3'>
              The Data Solutions Community Points of Contact
            </h2>
            <p>
              The Data Solutions Community team is ready to answer your
              questions about our platform. Our team includes moderators who
              ensure that Data Solutions Community remains a professional source
              of information, helping mediate conflicts related to moderation
              activities.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Community;
