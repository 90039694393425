import React, { useState } from 'react';

const Form = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    organization: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    // Validate the field and remove error if valid
    if (value.trim() !== '') {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[id];
        return newErrors;
      });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = 'First Name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last Name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    if (!formData.phoneNumber.trim())
      newErrors.phoneNumber = 'Phone Number is required';
    if (!formData.organization.trim())
      newErrors.organization = 'Organization is required';
    if (!formData.message.trim()) newErrors.message = 'Message is required';

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      console.log('Form submitted successfully:', formData);
      setErrors({});
      onClose();
    }
  };

  return (
    
    <div className='p-5 '>
      <h2 className='text-2xl font-bold text-center text-black p-5 '>Request a Demo</h2>
      <form onSubmit={handleSubmit}>
        <div className='mb-4'>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='firstName'
          >
            First Name
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.firstName ? 'border-red-500 ' : ''
            }`}
            id='firstName '
            type='text'
            placeholder='Your First Name'
            value={formData.firstName}
            onChange={handleChange}
          />
          {errors.firstName && (
            <p className='text-red-500 text-xs italic'>{errors.firstName}</p>
          )}
        </div>

        <div className='mb-4'>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='lastName'
          >
            Last Name
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.lastName ? 'border-red-500' : ''
            }`}
            id='lastName'
            type='text'
            placeholder='Your Last Name'
            value={formData.lastName}
            onChange={handleChange}
          />
          {errors.lastName && (
            <p className='text-red-500 text-xs italic'>{errors.lastName}</p>
          )}
        </div>

        <div className='mb-4'>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='email'
          >
            Email
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.email ? 'border-red-500' : ''
            }`}
            id='email'
            type='email'
            placeholder='Your Email'
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && (
            <p className='text-red-500 text-xs italic'>{errors.email}</p>
          )}
        </div>

        <div className='mb-4'>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='phoneNumber'
          >
            Phone Number
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.phoneNumber ? 'border-red-500' : ''
            }`}
            id='phoneNumber'
            type='tel'
            placeholder='Your Phone Number'
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          {errors.phoneNumber && (
            <p className='text-red-500 text-xs italic'>{errors.phoneNumber}</p>
          )}
        </div>

        <div className='mb-4'>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='organization'
          >
            Organization
          </label>
          <input
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.organization ? 'border-red-500' : ''
            }`}
            id='organization'
            type='text'
            placeholder='Your Organization'
            value={formData.organization}
            onChange={handleChange}
          />
          {errors.organization && (
            <p className='text-red-500 text-xs italic'>{errors.organization}</p>
          )}
        </div>

        <div className='mb-4'>
          <label
            className='block text-gray-700 text-sm font-bold mb-2'
            htmlFor='message'
          >
            Message
          </label>
          <textarea
            className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
              errors.message ? 'border-red-500' : ''
            }`}
            id='message'
            placeholder='Your Message'
            value={formData.message}
            onChange={handleChange}
          />
          {errors.message && (
            <p className='text-red-500 text-xs italic'>{errors.message}</p>
          )}
        </div>

        <div className='flex items-center justify-between'>
          <button
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='button'
            onClick={onClose}
          >
            Close
          </button>
          <button
            className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            type='submit'
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form;
