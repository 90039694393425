import image from "../../resources/images/about1/cyber5.jpg";
import image1 from "../../resources/images/about1/cyber9.jpeg";
import image2 from "../../resources/images/about1/treeHistory.png";
import image5 from "../../resources/images/about1/AI images.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import Form from "../requestADemo/Form";

const About = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDialog = () => {
    setIsOpen(false);
  };
  let Navigate = useNavigate();
  let exe = () => {
    Navigate("/about/executiveLeadershipAndSupport");
  };
  return (
    <div id="About" className="min-h-screen w-full ">
      <section className=" text-white py-20 px-12 ">
        <div className="flex justify-start items-center px-8  text-base py-3 ">

        </div>
        <div className="container mx-auto text-center">
          <h2 className="text-black text-4xl font-bold mb-4">
            We Provide Coherent Enterprise Cyber Security Solutions
          </h2>
        </div>
        <section className="bg-white text-black">
          <div className="container mx-auto p-5 pt-20" id="about_who">
            <div className="text-center">
              {/* Who Section */}
              <div className="mb-16">
                <h3 className="text-black text-3xl font-semibold mb-4">Who</h3>
                <h2 className="text-xl md:text-xl lg:text-xl mb-5 py-3">
                  Data Solutions is a Cybersecurity Platform Company providing solutions to clients in tailoring, perfection designing just meant for your org. We are dedicated to safeguarding your Cyber environment with the latest technologies and unique solutions
                  <div className="py-3" >
                    We are present internationally with operations in more than
                     three countries.
                  </div>
                  <div>
                    <strong>Headquartered in Austin, Texas, USA.</strong>
                  </div>
                </h2>
              </div>

              {/* How Section */}
              <div className="mb-8">
                <h3 className="text-black text-3xl font-semibold mb-4">How</h3>
                <p className="text-black text-xl">
                  Highly focused on process transparency and clear communication
                  while assisting product development.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className=" text-white pt-10">
          <div className="container mx-auto text-center">
            <h2 className="text-black text-4xl font-bold mb-4">
              Leading with vision and experience
            </h2>
          </div>
        </section>

        <section className=" text-black pt-10">
          <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <img src={image} alt="vision" />
              <div className="flex justify-center items-center text-center text-xl">
              &quot;All strategies lead to technology—companies reinvent all parts
                of the enterprise with data, AI and ways of working to build
                resilience and find paths to growth.&quot;
              </div>
            </div>
          </div>
        </section>
        <section className=" grid justify-center items-center text-white pt-10">
          <div className="container mx-auto text-center flex justify-center items-center flex-col">
            <h2 className="text-black text-4xl font-bold mb-4">
              One Industry of Security
            </h2>
            <h4 className="text-black  font-bold">
              Across industries, we bring together the capabilities needed to
              help clients change and grow quickly.
            </h4>
          </div>
        </section>
        <section className="  text-black pt-10">
          <div className="container mx-auto text-center ">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center ">
              <div className="flex justify-center items-center flex-col">
                <div className="hover:font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl p-5 ">
                  Technology
                </div>
                <div className="hover:font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl p-5 ">
                  Operations
                </div>
                <div className="hover:font-bold text-lg sm:text-2xl md:text-2xl lg:text-3xl p-4 ">
                  Strategy
                </div>
                <div className="hover:font-bold text-lg sm:text-2xl md:text-2xl lg:text-3xl p-4 ">
                  Industries
                </div>
              </div>
              <img src={image1} alt="cities" />
            </div>
          </div>
        </section>
        <section className=" text-white pt-10">
          <div className="container mx-auto text-center">
            <h2 className="text-black text-4xl font-bold mb-4">
              History Of Data Solutions
            </h2>
          </div>
        </section>
        <section className="bg-white text-black pt-10">
          <div className="container mx-auto text-center flex justify-center">
            <img src={image2} className="h-96" id="about_tree"></img>
          </div>
        </section>

       
        <section className=" text-white pt-10">
          <div className="container mx-auto text-center">
            <h2 className="text-black text-4xl font-bold mb-4">
              Community Service by Data Solutions Leadership.
            </h2>
          </div>
        </section>

        <section className="bg-white text-black pt-10">
          <div className="container mx-auto text-center">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <img src={image5}></img>
              <div className="flex justify-center items-center text-center text-xl">
                Data Solutions is always partnered with AI for best security
                service and understanding Threat pattern.
                In this article, you’ll learn more about artificial intelligence, what it actually does, and different types of it.
              </div>
            </div>
          </div>
        </section>
        <div className="grid gap-8 py-10 p-5">
          <div className=" p-6 rounded-lg shadow-lg">
            <h3 className="text-black text-3xl font-semibold mb-4">
              Executive Leadership and Support
            </h3>
            <p className="text-black text-lg py-5">
              What’s more, leadership is not something people are born with—it
              is a skill you can learn. At the core are mindsets, which are
              expressed through observable behaviors, which then lead to
              measurable outcomes.
            </p>
            <button onClick={exe}>
              <p className="text-xl text-black py-5  underline-animation ">
                Get to know more
                <FontAwesomeIcon icon={faArrowRight} className="px-1.5 " />
              </p>
            </button>
          </div>
        </div>

        <section className=" text-white pt-10">
          <div className="container mx-auto text-center">
            <h2 className="text-black text-4xl font-bold mb-4">
              Office and operations in countries
            </h2>
          </div>
        </section>
        <section className="bg-white text-black pt-10">
          <div className="container mx-auto text-center">
            <div className="flex justify-center items-center">
              <div>
                <span className=" font-bold text-7xl  gap-3">USA, </span>
                <span className="font-bold text-7xl  ">CANADA,</span>
                <br />
                <span className=" font-bold text-7xl  ">INDIA</span>
                <br />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white text-black pt-10">
          <div className="container mx-auto text-center">
            <h2 className="text-black text-4xl font-bold mb-4">
              Have a project in mind? Schedule a free consultation today.
            </h2>
            <button className="mb-4">
              <a href="/contact" className="hover:bg-gray-300 bg-gray-100 p-2">
                contact{" "}
              </a>
            </button>
          </div>
        </section>
      </section>
      <section className="flex flex-col lg:flex-row justify-center items-start   bg-gray-700 text-white py-10 px-5 lg:py-10 lg:px-10">
        <div className="px-5 lg:px-20 mb-10 lg:mb-0">
          <h2 className="text-xl font-bold mb-3">Quick Links</h2>
          <ul>
           
             <li>
              <Link to="/about/services">
                <p className="hover:underline">Find services</p>
              </Link>
            </li>
            <li>
              <Link to="/about/Center">
                <p className="hover:underline">Data Solutions Trust Center</p>
              </Link>
            </li>
          
            <li>
              <Link to="/about/Community ">
                <p className="hover:underline">Data Solutions Community</p>
              </Link>

            </li>

          </ul>
        </div>
        <div className="px-5 lg:px-20 mb-10 lg:mb-0">
          <h2 className="text-xl font-bold mb-3">About Data Solutions</h2>
          <ul>
            <li>
              <Link to="/about/Developer">
                <p className="hover:underline">Developer</p>
              </Link>
            </li>
            <li>
              <Link to="/about/Company">
                <p className="hover:underline">Company Information</p>
              </Link>
            </li>
            <li>
              <Link to="/about/Directory">
                <p className="hover:underline">Worldwide Directory</p>
              </Link>
            </li>

            <li>
              <Link to="/about/Artificial">
                <p className="hover:underline">Artificial Intelligence</p>
              </Link>
            </li>

              
          </ul>
        </div>
        <div className="px-5 lg:px-20">
          <h2 className="text-xl font-bold mb-3">Site Information</h2>
          <ul>
            <li>
              <Link to="/about/Privacy">
                <p className="hover:underline">Privacy</p>
              </Link>
            </li>

            <li>
              <Link to="/about/Legal">
                <p className="hover:underline">Legal Disclosure</p>
              </Link>
            </li>
            <li>
              <Link to="/about/Copyright">
                <p className="hover:underline">Copyright</p>
              </Link>

            </li>

          </ul>
        </div>
      </section>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-5 rounded shadow-lg w-11/12 md:w-1/2">
            <Form onClose={closeDialog} />
          </div>
        </div>
      )}
    </div>

  );
};

export default About;