import React from 'react';
import { useNavigate } from 'react-router-dom'
import image from '../../../../resources/images/home/homepage/patchManagement/VulnerabilityManagement.webp';
import image1 from '../../../../resources/images/home/homepage/patchManagement/computer.1.png';
import image2 from '../../../../resources/images/home/homepage/patchManagement/wifiSignal.png';
import image3 from '../../../../resources/images/home/homepage/patchManagement/lap.png';
import image4 from '../../../../resources/images/home/homepage/patchManagement/dataTechnology.webp';
import image5 from '../../../../resources/images/home/homepage/patchManagement/message.png';
import image6 from '../../../../resources/images/home/homepage/patchManagement/settingsIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';



const PatchManagement = () => {
  let Navi= useNavigate()
  let patch=()=>{
    Navi(-1)
  
  }
  return (
    <div className='min-h-screen'>
      <section className=' text-white py-20 p-5'>
        <div className='container mx-auto text-center border-2 shadow-2xl rounded-3xl bg-white'>
        <div className="flex justify-start items-center px-2  text-base py-5 ">
        <div>
          <button onClick={patch} className=" text-black ">
            Home
            <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
          </button>
        </div>
        <div>
          <button className="  text-black">Patch Management   </button>
        </div>
      </div>
        <section className=' text-black pt-10 '>
        <div className='container mx-auto p-4 '>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='flex justify-start items-start text-5xl  capitalize '>
            The only enterprise patch management software you will ever need!
            </div>
          <img src={image} alt='vision' className='h-72 rounded-3xl'  />
            <p >
            Achieve maximum patch compliance and bolster your network security with automated scanning, detection, testing and deployment of missing patches.
            </p>
        </div>

      </div>
      </section>
      <section className='   bg-white p-5'>
        <div className='container mx-auto text-center'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-8 py-5'>
            
            
            <div className=' bg-green-50 p-6  rounded-3xl'>
            <img src={ image1} className=' h-24 px-15 '/>
            
            <p className='text-black  text-base'>
            Deploy patches and updates to Windows, macOS, Linux, Android, iOS and more.
            </p>
            
           
          
          </div>
                
          <div className=' bg-green-50 p-6  rounded-3xl'>
          <img src={ image2} className=' h-20 '/>
          
          <p className='text-black  text-base'>
          Keep your endpoints secure with patching support for 850+ third-party apps, BIOS, and driver updates.
          </p>
          
         
        
        </div>
              
        <div className=' bg-green-50 p-6  rounded-3xl'>
        <img src={ image3} className=' h-20 '/>
        <p className='text-black text-base'>
        Patch and manage endpoints in LAN, WAN, DMZs, and for WFH employees without a VPN.
        </p>
        
       
      
       </div>
       <div className=' bg-green-50 p-6  rounded-3xl'>
       <img src={ image4} className=' h-20 p-2'/>
       <p className='text-black text-base'>
       Perform patch and vulnerability management, all from a single console by integrating with Tenable.
       </p>
       
      
     
      </div>
      <div className=' bg-green-50 p-6  rounded-3xl'>
      <img src={ image5} className=' h-20 '/>
      <p className='text-black text-base'>
      User-centric deployments to keep your patch compliance & employee productivity high at all times.
      </p>
      
     
    
     </div>

     <div className=' bg-green-50 p-6 rounded-3xl '>
     <img src={ image6} className=' h-20 '/>
     <p className='text-black text-base'>
     Achieve compliance standards including PCI DSS, GDPR, HIPAA, NIST, and more, across your enterprise.
    </p>
     
    
   
    </div>
     </div>
        </div>
      </section>
      <section className='text-black  p-5' >
 <i><h1  className='py-5 text-3xl font-bold'>Automatic Patching for Faster Remediation</h1></i>
    
     <p  className='py-8'>
     Automate patch management from detection, prioritization, and testing to scheduling deployment. SanerNow patch manager eliminates the need for manual efforts and allows the creation of rules for automation.. These audits play a crucial role in ensuring that organizations are operating within the legal and regulatory frameworks relevant to their industry. By conducting regular security audits, organizations can identify any non-compliance issues and take proactive steps to address them, avoiding potential penalties or legal consequences.During a security audit, organizations assess their current security practices and compare them against established regulations and standards. This evaluation helps identify any gaps or weaknesses that need to be addressed. By aligning their practices with regulatory requirements, organizations can demonstrate their commitment to maintaining high levels of security and safeguarding sensitive information.
     </p>
</section>

        </div>
      </section>
    </div>
  );
};

export default PatchManagement;