import React from 'react';
import img1 from "../../resources/images/partners/partnerLinks/careers/companySecurity.jpg"
import img2 from "../../resources/images/partners/partnerLinks/careers/access.jpeg"
import img3 from "../../resources/images/partners/partnerLinks/careers/company13.jpg"

const Careers = () => {

  return (

    <div className=' text-lg p-16 '>
      <div className='h-16'></div>
      <div className='pb-4'>
        <button onClick={Careers} className=" text-black  font-semibold">
          Products & Products
        </button>
      </div>
       
      <div className=''>
        <div className= 'container mx-auto text-white pl-4 h-72 bg-slate-900 md:rounded-br-md flex flex-col justify-center '>
          <p className='md:text-6xl font-bold  '>
            Data Access Security
          </p>
          
        </div>

        <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-8 mt-5 px-5 bg-slate-800 '>
        <div className='p-5 bg-white shadow rounded  '>
          <img
            src={img1}
            alt='Demo'
            className='w-full md:w-full  '
          />
          <h2 className='text-2xl font-semibold ' >For freshers
          </h2>
          <p>
          Kick-start your professional journey with opportunities that allow you to use your unique skills and acquire many new ones.
          </p>
        </div>

        <div className='p-5 bg-white shadow rounded'>
          <img
            src={img2}
            alt='Demo'
            className='w-full md:w-full rounded shadow-lg'
          />
          <h2 className='text-2xl font-semibold '>For experienced professionals
          </h2>
          <p>
          Take a leap towards new career horizons in our multidisciplinary, diverse, global organization&apos;s.
          </p>
        </div>

        <div className='p-5 bg-white shadow rounded'>
          <img
            src={img3}
            alt='Demo'
            className='w-full md:w-full rounded shadow-lg'
          />
          <h2 className='text-2xl font-semibold '>About Data Solutions careers
          </h2>
          <p>
          The world is changing with accelerating scale and complexity. This is your opportunity to explore a future with a multidisciplinary professional services organization that leads with purpose, solving complex issues for our clients and communities.
          </p>
        </div>
      </section>
      </div>
    </div>

  );
};
export default Careers;