import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import penetration from "../../resources/images/Solutions&Capabilities/Penetration Testing/Penetration.jpg";
import testing from "../../resources/images/Solutions&Capabilities/Penetration Testing/Testing.jpg";
import testings from "../../resources/images/Solutions&Capabilities/Penetration Testing/PenetrationTesting1.png";
const penetrationTesting = () => {
  return (
    <div className="min-h-screen">
      <section className="text-white p-5 ">
        <div className="container mx-auto ">
          <div className="flex justify-start items-start px-10  text-sm py-16 ">
            <Link
              to="/services"
              className={` text-black  px-3 
      }`}
            >
              Solutions & Capabilities
              <FontAwesomeIcon
                icon={faArrowRight}
                className="  text-black"
              />
            </Link>
            <div>
              <button className="  text-black ">Penetration testing</button>
            </div>
          </div>
          <section></section>
          <section className=" font-bold mb-5 text-black grid justify-center ">
            <h1 className="text-4xl font-bold mb-5 text-black grid justify-center  ">
              What is penetration testing..?
            </h1>
            <div className="flex justify-center items-center flex-col md:flex-row  py-5 ">
              <img src={penetration} className="  rounded-3xl" />
            </div>
            <p className=" px-5  text-black">
              Penetration testing (or pen testing) is a security exercise where
              a cyber-security expert attempts to find and exploit
              vulnerabilities in a computer system. The purpose of this
              simulated attack is to identify any weak spots in a system’s
              defenses which attackers could take advantage of.This is like a
              bank hiring someone to dress as a burglar and try to break into
              their building and gain access to the vault. If the ‘burglar’
              succeeds and gets into the bank or the vault, the bank will gain
              valuable information on how they need to tighten their security
              measures.
            </p>
          </section>
          <section className=" text-black pt-10 bg-blue-50 rounded-xl">
            <div className="container mx-auto p-4 ">
              <h1 className="text-4xl font-bold mb-5 text-black grid justify-center  ">
                Who performs pen tests?
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <img src={testing} alt="vision" className="h-80 " />
                <div className="flex justify-center items-center   ">
                  It is best to have a pen test performed by someone with
                  little-to-no prior knowledge of how the system is secured
                  because they may be able to expose blind spots missed by the
                  developers who built the system. For this reason, outside
                  contractors are usually brought in to perform the tests. These
                  contractors are often referred to as ‘ethical hackers’ since
                  they are being hired to hack into a system with permission and
                  for the purpose of increasing security. Many ethical hackers
                  are experienced developers with advanced degrees and a
                  certification for pen testing. On the other hand, some of the
                  best ethical hackers are self-taught. In fact, some are
                  reformed criminal hackers who now use their expertise to help
                  fix security flaws rather than exploit them. The best
                  candidate to carry out a pen test can vary greatly depending
                  on the target company and what type of pen test they want to
                  initiate
                </div>
              </div>
            </div>
          </section>
          <div className="grid justify-center">
          <img src={ testings} alt='vision' className='   '  />
          </div>
        </div>
      </section>
    </div>
  );
};

export default penetrationTesting;
