import React from 'react';
import img1 from "../../resources/images/partners/partnerLinks/protection/protection1.jpg"
import img2 from "../../resources/images/partners/partnerLinks/protection/protection2.jpg"
import img3 from "../../resources/images/partners/partnerLinks/protection/protection3.jpg"
import img4 from "../../resources/images/partners/partnerLinks/protection/protection4.jpg"

const Protection = () => {

  return (
    <div className=' text-lg p-16 '>
      <div className='h-16'></div>
      <div className='pb-4'>
        <button onClick={Protection} className=" text-black  font-semibold">
          Products & Products
        </button>
      </div>
      <div className=' bg-indigo-950 px-5 text-white'>
        <section className='flex flex-col md:flex-row items-center mt-5  '>
          <div>
            <p className='text-4xl font-bold'>
              Ransomware Protection for an Impenetrable Business

            </p>
            <p className='text-xl py-5 '>
              A proactive, multi-layered approach prevents, protects, and immunizes backup data from ransomware and other cyber attacks. Immutable backup storage—on-premises with One appliances or in the cloud with AWS Object Lock—safeguards data from malicious exploits. Threats are neutralized while data loss and downtime are eliminated. You carry on business as usual.
            </p>
          </div>
          <img
            src={img1}
            alt='Demo'
            className='shadow-lg md:w-1/2 rounded-3xl'
          />
        </section>
        <section className='flex flex-col md:flex-row items-center mt-5  '>
        <img
            src={img2}
            alt='Demo'
            className='shadow-lg md:w-1/2 rounded-3xl'
          />
          <div className='px-4'>
            
            <p className='text-4xl font-bold'>
              Advanced Ransomware Protection for the Modern Infrastructure

            </p>
            <p className='text-xl py-5 '>
              New research reveals that nearly 60 percent of consumers will likely avoid doing business with your organization if you experience a cyber attacks this year. With malicious threats reaching epidemic proportions, its time to fight back.

              Reserve helps you eliminate the complexity of discrete data security and protection plans with total integration of ransomware and malware prevention, backup, and disaster recovery for a unified first and last line of defense against cyber attacks.

            </p>
          </div>
          
        </section>
        <section className='flex flex-col md:flex-row items-center mt-5 '>
          <div>
            <p className='text-4xl font-bold'>
            Ransomware protection services

            </p>
            <p className='text-xl py-5 '>
            Transform your business and manage risk with a global industry leader in cybersecurity consulting, cloud and managed security services. 

            </p>
          </div>
          <img
            src={img3}
            alt='Demo'
            className='shadow-lg md:w-1/2 rounded-3xl'
          />
        </section>
        <section className='flex flex-col md:flex-row items-center mt-5  '>
        <img
            src={img4}
            alt='Demo'
            className='shadow-lg md:w-1/2 rounded-3xl '
          />
          <div className='px-5'>
            
            <p className='text-4xl font-bold'>
            Disrupting a Ransomware Attack

            </p>
            <p className='text-xl '>
            Learn about the four stages of a ransomware attack and how Data Solutions prevents attacks at each stage.

            </p>
          </div>
          
        </section>
      </div>
    </div>
  )
};
export default Protection;