import React from "react";
import image from "../../resources/images/partners/partnerLinks/renewLicense/renewal2.png";
import image1 from "../../resources/images/partners/partnerLinks/renewLicense/renewal fee 1123.jpg";
import image2 from "../../resources/images/partners/partnerLinks/renewLicense/renewLicense.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const RenewLicense = () => {
  return (
    <div className="min-h-screen md:px-10">
      <section className=" text-white py-20">
      <div className="flex justify-start items-center px-2  text-base py-5 ">
      <Link
                to="/Partners"
                className={` text-black  px-3 py-10
                }`}
              >
              Partners
                <FontAwesomeIcon icon={faArrowRight} className='px-2  text-black' />
              </Link>
              
      <div>
        <button className="  text-black">RenewLicense
        </button>
      </div>
    </div>
        <div className="container mx-auto  ">
          <section className=" text-black pt-10 bg-blue-50  rounded-3xl py-16">
            <div className="container mx-auto p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="">
                  <img
                    src={image}
                    alt="vision"
                    className="h-96 min-w-96 border-2 shadow-2xl rounded-2xl"
                  />
                </div>
                <div className="grid justify-center items-center">
                  <div className="flex justify-center items-center text-4xl font-bold  py-8 ">
                    How often do I need to renew my cybersecurity
                    certifications?
                  </div>
                  <p className="flex justify-center ">
                    Renew your cybersecurity certifications every three years to
                    stay current in the evolving field. Certifications like
                    CompTIA Security+ signal dedication to cybersecurity.
                    Renewal ensures ongoing education, job eligibility, security
                    awareness, and professional credibility. Maintaining current
                    certifications is essential for career growth and protection
                    against cyber threats.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 py-5">
            <div className="bg-white p-10 rounded-lg  ">
              <h3 className="text-black text-xl font-semibold mb-4">
                Job Opportunities
              </h3>
              <p className="text-gray-600">
                Employers love to see those valid certs. Without them, you might
                as well say goodbye to that dream job or even risk getting
                booted from your current gig. Renewing certs like Security+, as
                the ONLC homies suggest, keeps you up-to-date with the latest
                security tech and makes you a total catch in the job market.
              </p>
            </div>
            <div className="bg-white p-10 rounded-lg  ">
              <h3 className="text-black text-xl font-semibold mb-4">
                Security Risks
              </h3>
              <p className="text-gray-600">
                If your certs are expired, you might be missing out on some
                serious knowledge about the latest threats and defenses. That&apos;s
                like leaving your digital fortress wide open for any hacker to
                waltz right in. Mary Lopez, a cloud security pro and AWS Hero,
                knows what&apos;s up. She says some attacks you learn in cert classes
                might not even apply to environments like AWS anymore. Crazy,
                right?
              </p>
            </div>
            <div className="bg-white p-10 rounded-lg ">
              <h3 className="text-black text-xl font-semibold mb-4">
                Professional Cred:
              </h3>
              <p className="text-gray-600">
                Keeping those certs current is like a badge of honor. It shows
                you&apos;re committed to your craft and boosts your rep.
                Cybersecurity certs detail your knowledge and validate your
                skills, making you a total catch for employers, as the folks at
                Altimeter Solutions can attest.
              </p>
            </div>
          </section>
          <section className=" text-black pt-10 bg-orange-100  rounded-3xl ">
            <div className="container mx-auto p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="grid justify-center items-center  text-xl ">
                  <div className="flex justify-start text-start text-3xl font-bold">
                    The Importance of Renewing Cybersecurity Certifications
                  </div>
                  <p className=" text-base py-20">
                    These cybersecurity certs ain&apos;t just some fancy badges to
                    flex on your resume. They&apos;re like a lifetime pass to stay
                    ahead of the game in this ever-changing digital world.
                    Keeping them renewed is crucial, not just to show off your
                    mad skills, but because cybersecurity is like a never-ending
                    battle royal with new threats popping up every day.
                  </p>
                </div>
                <img
                  src={image1}
                  alt="vision"
                  className=" rounded-3xl grid justify-center   "
                />
              </div>
            </div>
          </section>
          <section className=" text-black pt-10 py-20 ">
            <div className="container mx-auto p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="">
                  <img
                    src={image2}
                    alt="vision"
                    className="h-96 min-w-96 border-2 shadow-2xl rounded-2xl "
                  />
                </div>
                <div className="grid justify-center items-center p-3">
                  <div className="flex text-3xl font-bold   ">
                    Common Renewal Periods for Cybersecurity Certifications
                  </div>
                  <p className="flex    ">
                    The world of cyber sec certs is a wild one, with each one
                    having its own timeline for keeping that valid and fresh.
                    For most of the big names, a three-year renewal period is
                    the norm. The classic CISSP, CISM, and CEH certs all rock
                    that schedule. But Comptia&apos;s Security+ cert wants you to
                    renew every three years, though you can skip the retake and
                    just keep learning instead.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default RenewLicense;
