import React from "react";
import image from "../../../resources/images/home/intrusionDetection/Intrusion Detection System 1.avif";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';

const IntrusionDetectionSystem = () => {
  let Navigate = useNavigate();

  let Intrusion = () => {
    Navigate(-1);
  };
  return (
    <div className="min-h-screen md:px-10">
      <section className=" text-white py-20">
        <div className="container mx-auto    ">
          <div className="flex justify-start items-center  text-base py-5 ">
            <div>
              <button onClick={Intrusion} className=" text-black ">
                Home 
                <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
              </button>
            </div>
            <div>
              <button className="text-black">Intrusion Detection System</button>
            </div>
          </div>

          <section className=" text-black pt-10 border-2 shadow-2xl rounded-3xl bg-gray-300">
            <div className="container mx-auto p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex justify-start items-start text-6xl  ">
                  How Intrusion Detection Systems (IDS) Work: One Part of Your
                  Security Arsenal
                </div>
                <img src={image} alt="vision" className="h-72" />
              </div>
            </div>
          </section>
          <section className="text-black p-5">
            <p>
              All cyber attacks that violate the confidentiality, integrity and
              availability (CIA) of the network and data demonstrate some form
              of anomalous behavior. The starting point of this behavior may be
              an unauthorized intrusion into the network and, then, unauthorized
              use of the resources contained within the network.If you can
              identify an unauthorized network intrusion attempt, you can
              maintain the CIA of your data assets and network resources. This
              is precisely the goal of an Intrusion Detection System (IDS).
            </p>
          </section>
          <h2 className="flex justify-start text-black text-5xl font-bold mb-4 p-5">
            What are intrusion detection systems?
          </h2>
          <section className="text-black  p-5">
            <p className="py-2 ">
              An Intrusion Detection System refers to the technology that
              monitors network traffic for anomalous behavior such as
              cyber attacks or security policy violations.
            </p>
            <p className="py-2">
              Once the event is identified as an anomaly, it reports the
              administrators or issues an automation control action to the
              integrated Security Information and Event Management (SIEM) tool.
              The SIEM tool then uses advanced filtering techniques and
              protocols to distinguish a legitimate intrusion attempt from false
              alarms when raising an alert.
            </p>
            <i>
              <h1 className="py-2 text-3xl font-bold">IDS vs firewall</h1>
            </i>
            <hr className="bg-red-500 h-1 w-2/4 "></hr>
            <p className="py-6">
              This process is different from a firewall mechanism, which simply
              filters and implicitly prevents a possible network intrusion. So
              why not just a firewall system instead? Modern enterprise IT
              networks are complex. The networks include thousands of network
              endpoints and nodes communicating between each other — no fixed
              set of rules can encompass a holistic and uniform security policy
              for the entire network. Therefore, IDS systems are deployed at
              various network nodes to determine potential violation of a
              network security policy.The positives of AD systems are that they
              are less dependent on the underlying technology stack and OS. New
              vulnerabilities can be easily detected as long the model is
              sufficiently trained to classify a legitimate traffic request from
              an unauthorized intrusion attempt. New vulnerabilities such as
              Zero-Days exploits are less concerning, as explicit signature
              knowledge is not required. However, the drawbacks of AD systems
              include:
            </p>
            <i>
              <h1 className="py-2 text-3xl font-bold">
                IDS = one part of your security arsenal
              </h1>
            </i>
            <hr className="bg-red-500 h-1 w-4/5 "></hr>
            <p className="py-6">
              These IDS systems don’t provide an actual defense against
              malicious intrusion attempts — they’re not firewall systems, but a
              piece of the large security puzzle. An example would be that the
              intrusion attempt initiates an unexpected sequence of attempts
              without issuing prerequisite commands. The SPA system would check
              for the protocol profile characteristics, such as length of the
              command and order sequence, to determine a potentially
              unauthorized network intrusion attempt.
            </p>

            <p className="py-2">
              The decision of rejecting network traffic requests may be
              difficult to represent as a single policy or rules that are
              enforced by a firewall system. Instead, Intrusion Detection
              Systems help InfoSec teams understand traffic behavior and make
              well-informed decisions based on true contextual knowledge,
              instead of relying on fixed and predefined policies.
            </p>
          </section>
        </div>
      </section>
    </div>
  );
};

export default IntrusionDetectionSystem;
