import React from "react";
import { useNavigate } from "react-router-dom";
import image from "../../../resources/images/home/networkSecurity/network1Seg/bestPractices.webp";
import image1 from "../../../resources/images/home/networkSecurity/network1Seg/Network-Segmentation-01 (1).jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Network1Seg = () => {
  let Navigate = useNavigate();
  let se = () => {
    Navigate(-1);
  };
  let seg = () => {
    Navigate(-2);
  };

  return (
    <div className="min-h-screen md:px-10">
      <section className=" text-white py-20 p-5">
        <div className="container mx-auto text-center">
          <div className="flex justify-start items-center px-8  text-base py-5 ">
            <div>
              <button onClick={seg} className=" text-black ">
                Home
                <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
              </button>
            </div>
            <div>
              <button onClick={se} className="  text-black">
                Network
                <FontAwesomeIcon icon={faArrowRight} className="px-2 " />
              </button>
            </div>
            <div>
              <button className="  text-black">Network Segmentation</button>
            </div>
          </div>
          <div className="flex justify-center items-center text-6xl  text-black  ">
            7 Network Segmentation Best Practices to Level-up Your Security
          </div>
          <section className=" text-black pt-10 ">
            <div className="container mx-auto p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="h-96  rounded-3xl grid justify-center items-end grid-flow-col px-2">
                  <img
                    src={image}
                    alt="vision"
                    className=" grids justify-center  items-end h-72 min-w-96 border-2 shadow-2xl rounded-2xl"
                  />
                </div>
              </div>
              <p className="gird  justify-center items-center py-8 ">
                In this article, we’ll discuss network segmentation, including
                what it is, why it’s important, and the benefits of implementing
                a robust network segmentation strategy. Plus, we’ll help you
                improve security across your entire network by reviewing the
                following network segmentation best practices:
              </p>
            </div>
          </section>
          <section className="grid justify-start items-start text-black">
            <ol className="grid justify-start items-start px-8 list-disc gap-3 text-xl">
              <li>Limit third-party access</li>
              <li>Audit and monitor your network</li>
              <li>
                Make legitimate paths to access easier than illegitimate paths
              </li>
              <li>Combine similar network resources</li>
              <li>Don’t over segment</li>
              <li>Visualize your network</li>
            </ol>
          </section>
          <h1 className="text-4xl text-black py-10">
            What is Network Segmentation?
          </h1>
          <section className=" text-black  border-2 shadow-2xl rounded-3xl bg-blue-100">
            <div className="container mx-auto p-8 grid justify-center  ">
              <div className=" grid-cols-1 md:grid-cols-1  justify-center">
                <img
                  src={image1}
                  alt="vision"
                  className=" flex justify-center  items-start h-72 min-w-96  rounded-2xl p-8 "
                />
                <p className="grid justify-start items-start p-6  text-2xl ">
                  Network segmentation (also known as network partitioning or
                  network isolation) is the practice of dividing a computer
                  network into multiple sub networks in order to improve
                  performance and security.
                </p>
              </div>
            </div>
          </section>
          <section className="text-black text-xl px-4 p-5 ">
            <div className="text-black text-xl flex justify-start items-start p-5 ">
              For example, if a bad actor gains access to your network, they
              will attempt to move around the network to access and exploit
              sensitive data. If you have a flat network (an architecture where
              all systems connect without going through intermediary devices
              such as a bridge or router), it is relatively easy for a bad actor
              to gain access to the entire system through one access point.
              While flat networks provide fast and reliable connectivity, this
              lateral access between systems makes them especially vulnerable
              within today’s modern and complex interconnected organizations.
            </div>
            <div className="text-black text-xl flex justify-start items-start p-5 ">
              However, when the network is segmented, malicious traffic won’t
              have immediate access to the entire ecosystem. Attackers will only
              be able to access the initial section they breached, giving IT
              time to locate the breach and minimize the impact of the
              intrusion.
            </div>
            <div className="font-bold text-xl py-4"></div>
          </section>
          <section className=" grid  justify-start  items-start text-black text-xl px-6  p-5">
            <div className="font-bold text-4xl py-8">
              {" "}
              7 Network Segmentation & Segregation Best Practices
            </div>
            <div className="text-black text-xl flex justify-start items-start  ">
              Network segmentation has become a key strategy for organizations
              looking to secure complex networks. And for good reason.So what
              are the benefits of network segmentation? A few include:
            </div>
            <div className="font-bold text-xl py-6 grid  justify-start  items-start">
              {" "}
              1. Follow Least Privilege
            </div>
            <div className="text-xl text-black">
              When segmenting your network, it’s important to minimize who and
              what has access within and across systems according to actual
              need. In other words, not everyone needs access to every part of
              the network. By following the principle of least privilege and
              role-based access, you can limit hosts, services, users, and
              networks from accessing data and functions that are outside their
              immediate responsibility. This strengthens your overall network
              security posture while also making it easier to monitor and track
              traffic throughout your network.
            </div>
            <div className="font-bold text-xl py-8 grid  justify-start  items-start">
              2. Limit third-party access
            </div>
            <div className="relative bottom-4 text-xl text-black">
              Similarly, it’s important to limit third-party access to your
              network to minimize exploitable entry points. Third-party remote
              access risks remain a key vulnerability for organizations. In
              fact, a recent report found that 44% of organizations experienced
              a breach in the last 12 months, with 74% saying it was the result
              of giving too much privileged access to third parties.
            </div>
            <section className=" text-black pt-10 border-2 shadow-2xl rounded-3xl bg-blue-100">
              <div className="container mx-auto p-4 ">
                <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                  <p className="grid justify-start items-start p-5  text-3xl italic ">
                    “Providing remote access to third parties without
                    implementing the appropriate security safeguards is almost
                    guaranteeing a security incident and a data breach involving
                    sensitive and confidential information,” said Dr. Larry
                    Ponemon, Chairman and Founder of Ponemon Institute. “It is
                    important that organizations assess the security and privacy
                    practices of the third parties that have access to their
                    networks and ensure that they have just enough access to
                    perform their designated responsibilities and nothing
                    more.....”
                  </p>
                </div>
              </div>
            </section>
            <div className="font-bold text-xl py-6 grid  justify-start  items-start">
              3. Audit and monitor your network
            </div>
            <div className="relative bottom-4 text-xl text-black">
              Segmenting your network is just the first step to a strong
              segmentation strategy. The next step is continually monitoring and
              auditing your network to ensure the architecture is secure and
              identify gaps in your sub networks that could be exploited.
            </div>
            <div className="font-bold text-xl py-6 grid  justify-start  items-start">
              4. Make legitimate paths to access easier than illegitimate paths
            </div>
            <div className="relative bottom-4 text-xl text-black">
              When evaluating and planning your architecture design, pay
              attention to how you plot access and what paths users will take to
              connect to your network. While it is important to create secure
              access points for your users, pay attention to how bad actors
              might try to access those same sub networks illegitimately.
            </div>
            <div className="font-bold text-xl py-6 grid  justify-start  items-start">
              5. Combine similar network resources
            </div>
            <div className="relative bottom-4 text-xl text-black">
              Save time and reduce security overhead by combining similar
              network resources into individual databases. As you review your
              network, categorize data by type and degree of sensitivity.
              Segmenting your network this way allows you to quickly apply
              security policies while protecting data more efficiently.
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default Network1Seg;
