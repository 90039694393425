import React from 'react';
import { useNavigate } from 'react-router-dom'
import image from '../../../../resources/images/home/homepage/securityAutomation/securityOrchestration.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';

const SecurityAutomationAndOrchestration = () => {
  let Navigate= useNavigate()
  let sec=()=>{
    Navigate(-1)
  
  }
  return (
    <div className='min-h-screen'>
      <section className=' text-white py-20 p-5'>
        <div className='container mx-auto text-center'>
        <div className="flex justify-start items-center px-2  text-base py-5 ">
        <div>
          <button onClick={sec} className=" text-black ">
            Home
            <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
          </button>
        </div>
        <div>
          <button className="  text-black"> Security Automation And Orchestration</button>
        </div>
      </div>
        <p className='grid justify-start items-start text-2xl py-5 text-black'>As organizations expand their third-party ecosystem, many are challenged with executing core activities that are critical to operations, risk profiles, and compliance posture without compromising the quality of data collection, evaluation, and mitigation measures.</p>
        <p className='text-lg text-black grid justify-start items-start ' >
        <img src={image } className=' border-2  rounded-3xl'/></p>
        </div>
        <h1 className='grid justify-start items-start text-2xl p-5 text-black font-bold' >Demystifying Security Orchestration, Automation, and Response (SOAR)</h1>
        <hr className='bg-red-500 h-1 w-3/5 '></hr>
        <section className='text-black text-xl px-6 py-3 ' >
              <div className='text-black text-xl flex justify-start items-start  '>
              SOAR is an innovative security strategy that integrates multiple security tools and processes to optimize, automate, and improve security operations. By streamlining tasks, fostering collaboration, and offering a centralized platform for managing security incidents, SOAR empowers security teams to respond to threats more effectively. The core components of SOAR include:
              </div>
              <div className='font-bold text-xl py-4 '>Security Orchestration-</div>
              <div className='text-xl text-black'>
              Security orchestration refers to coordinating and integrating diverse security tools, systems, and processes to enhance security operations. Security teams can work more effectively by consolidating data from multiple sources, facilitating collaboration, and providing a unified view of an organization’s security posture.
              </div>
              <div className='font-bold text-xl py-6 '>
              Security Automation- 
              </div>
              <div className='relative bottom-4 text-xl text-black'>
              Security automation involves leveraging technology to automate repetitive and manual security tasks, such as incident detection, threat hunting, and remediation. By enabling quicker and more accurate responses to threats, automation minimizes the risk of human error and frees up resources for strategic initiatives.
              </div>
              <div className='font-bold text-xl py-6 ' >
              Security Response-
             </div>
             <div className='relative bottom-4 text-xl text-black'>
             Security response encompasses the actions taken by security teams to contain, remediate, and recover from security incidents. SOAR solutions equip security teams with the tools and processes to respond to threats promptly and efficiently, mitigating potential damage caused by cyber-attacks.
            </div>
          
         
          
      </section>
      <h1 className='grid justify-start items-start text-2xl p-5 text-black font-bold' >The Advantages of Adopting SOAR</h1>
      <hr className='bg-red-500 h-1 w-3/5 '></hr>
      <section className='text-black text-xl px-6 py-3 ' >
              <div className='text-black text-xl flex justify-start items-start  '>
              SOAR offers a range of benefits to organizations, such as:
              </div>
              <div className='font-bold text-xl py-4'>Enhanced Efficiency – </div>
              <div className='text-xl text-black'>
              SOAR solutions automate routine tasks and streamline security processes, enabling security teams to work more efficiently and reduce the time spent on detecting, investigating, and remediating security incidents.
              </div>
              <div className='font-bold text-xl py-6'>
              Improved Collaboration – 
              </div>
              <div className='relative bottom-4 text-xl text-black'>
              By providing a centralized platform for security teams to collaborate, share information, and coordinate their efforts, SOAR improves collaboration and helps security teams respond to threats more effectively.
              </div>
              <div className='font-bold text-xl py-6'>
              Minimized Human Error –
             </div>
             <div className='relative bottom-4 text-xl text-black'>
             Automation decreases the likelihood of human error in security operations, ensuring tasks are completed accurately and consistently. This helps organizations avoid costly mistakes and bolster their overall security posture.
            </div>
            <div className='font-bold text-xl py-6'>
            Scalability –
             </div>
             <div className='relative bottom-4 text-xl text-black'>
             SOAR solutions are highly scalable, allowing organizations to adapt and grow their security operations in line with business needs. This flexibility ensures the continuous protection of digital assets as organizations expand and evolve.
            </div>
          
         
          
      </section>
      </section>
    </div>
  );
};

export default SecurityAutomationAndOrchestration;