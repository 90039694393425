
import React from 'react';
import img1 from '../../resources/images/about1/aboutLink/worldWide/Directory.jpg';
const Directoty = () => {
  return (
    <div className=' text-lg p-10'>
      <div className='  '>
        <div>
          <button
            onClick={Directoty}
            className=' text-black p-10 font-semibold '
          >
            About
          </button>
        </div>
        <img className='h-5/6 w-full bg-cover' src={img1} alt='' />
        <div className='container mx-auto  absolute  md:bottom-96  top-36  text-black'>
          <h1 className='text-3xl font-bold mb-5 p-10'>Worldwide Directory</h1>
        </div>
      </div>
      <div className=''>
        <h1 className='text-3xl font-semibold mb-5 '>
          Worldwide Office Directory and Regional Websites
        </h1>
        <h4 className='text-xl '>
          No matter where your business operates in the world, Data Solutions is
          there to help you take advantage of our wide range of software and
          services designed to fit your needs. Find one of our office locations
          in more than 3 countries and on most continents.
        </h4>
      </div>
    </div>
  );
};
export default Directoty;

