import React  from 'react';
import { useNavigate } from 'react-router-dom';
const Legal = () => {

  let Navigate = useNavigate();


  let Legal = () => {
    Navigate(-1);
  };

  return (

    <div className=' text-lg p-10'>

      <div>
        <button onClick={Legal} className=" text-black p-10 font-semibold ">
          About

        </button>
      </div>
      <div className='container mx-auto  px-4 text-white bg-sky-950 h-[300px]'>

        <p className='text-3xl font-bold p-20 '> Legal Disclosure</p>

      </div>


      <div className=''>
        <p className='text-4xl font-bold mb-5'>
          Impressum information
        </p>
      </div>
      <div className=''>
        <p className='text-3xl mb-5  '>
          DATA SOLUTIONS
        </p>
        <p className='text-2xl '>
          Respecting your time and with the goal of providing you with the quickest possible access to the relevant passages of the privacy statement, we offer you the following summary.
        </p>
      </div>
    </div>

  );
};
export default Legal;