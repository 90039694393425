import React from 'react';
import { useNavigate } from 'react-router-dom'
import image from '../../../../resources/images/home/homepage/accessControl/principlePrivilege.png';
import image1 from '../../../../resources/images/home/homepage/accessControl/privilegeManagement.png';
import image2 from '../../../../resources/images/home/homepage/accessControl/leastPrivilege.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';



const AccessControlAndLeastPrivilege = () => {
  let Navi= useNavigate()
  let Access=()=>{
    Navi(-1)
  
  }
  return (
    <div className='min-h-screen'>
      <section className='  text-white py-20 p-5'>
        <div className='container mx-auto text-center'>
        <div className="flex justify-start items-center px-2  text-base py-5 ">
        <div>
          <button onClick={Access} className=" text-black ">
            Home
            <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
          </button>
        </div>
        <div>
          <button className="  text-black">Access Control And Least 
          Privilege </button>
        </div>
      </div>

        <section className=' text-black pt-10 border-2 shadow-2xl rounded-3xl bg-yellow-50'>
        <div className='container mx-auto p-4 '>
          <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
            <div className='flex justify-center items-center text-4xl  py-2  '>
            What is the principle of least privilege?
          </div>
          <section className=' text-black pt-10   rounded-3xl py-1'>
        <div className='container mx-auto p-4 '>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <div className=''>
            <img src={image2} alt='vision' className=' border-2 shadow-2xl rounded-2xl'  />
            </div>
            
        </div>
      </div>
      </section>
          
            <p className='grid justify-center items-start py-5  '>
            The principle of least privilege ensures that users only have the access they truly need, reducing the potential negative impact of account takeover and insider threats.
            </p>
        </div>
      </div>
      </section>
      <section className='grid  justify-center items-start py-10'>
      <h1 className='text-black text-3xl  px-8'>What is the principle of least privilege?</h1>
      <img src={ image} className='p-10' />
      <p  className='text-black flex justify-start items-start py-5'> The principle of least privilege, also called &quot;least privilege access&quot; is the concept that a user should only have access to what they absolutely need in order to perform their responsibilities, and no more. The more a given user has access to, the greater the negative impact if their account is compromised or if they become an insider threatWhile the principle of least privilege applies in a wide variety of settings, this article focuses on how it applies to corporate networks, systems, and data. This principle has become a crucial aspect of enterprise security.As an example: A marketer needs access to their organizations website CMS in order to add and update content on the website. But if they are also given access to the codebase — which is not necessary for them to update content — the negative impact if their account is compromised could be much larger.</p>
      </section>
      <section className='text-black  ' >
 <i><h1  className='py-5 text-3xl font-bold'>How does the principle of least privilege increase security?</h1></i>
     
     <p  className='py-8'>
     Suppose Dave moves into a new house. Dave creates two copies of his house key; he keeps one for himself and gives a backup to his friend Melissa for emergencies. But Dave does not create 20 copies of his key and give one out to each of his neighbors. Dave knows this is much less secure: one of his neighbors might lose the key, accidentally give it to an untrustworthy person, or have the key taken from them, with the result that someone uses the lost key to sneak into his house and steal his expensive television.

     Similarly, while a company may not have an expensive television, it certainly has valuable data that it wants to keep safe. The more access the company allows to that data — the more &quot;keys&quot; it gives away — the greater the odds that some malicious party will steal a legitimate user&apos;s credentials and use them to steal that dat
     </p>
     
</section>
<section>
<h1 className='text-black text-4xl font-bold'>How to implement least privilege access</h1>
<div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <img src={image1} alt='vision'  />
                <div className='flex justify-center items-center text-center text-xl text-black '>
                Setting up a Zero Trust network enables organizations to put the principle of least privilege into practice. One of the core technical implementations of Zero Trust is called Zero Trust Network Access (ZTNA) — learn more about the nuts and bolts of how ZTNA works.
                Cloudflare Zero Trust is a platform that enables companies to quickly implement a Zero Trust approach for network security. Learn more about network security solutions..
                </div>
              </div>
</section>
        </div>
      </section>
    </div>
  );
};

export default AccessControlAndLeastPrivilege;