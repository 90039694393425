import React from "react";
import Links from "../src/importLinks"


const App = () => {
  return (
    <Links></Links>
  )
}

export default App