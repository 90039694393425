import React from 'react';
import Standard from '../../resources/images/ourVision/Standard.jpeg';
import Usable from '../../resources/images/ourVision/Usable.jpeg';
import Repeat from '../../resources/images/ourVision/Repeat.jpeg';
import commercial from '../../resources/images/ourVision/commercial.jpeg';
import Transparent from '../../resources/images/ourVision/transprent.jpeg';
import Modular from '../../resources/images/ourVision/Modular.jpeg';
import Mission from '../../resources/images/ourVision/cyber2.jpeg';
import Goals from '../../resources/images/ourVision/cyber7.jpeg';
import img1 from '../../resources/images/ourVision/cyber4.jpg'

// import { useNavigate } from 'react-router-dom';
// import img1 from '../../resources/images/cyber4.jpg';

const OurVision = () => {
  return (
    <>
      <section className='min-h-screen md:px-10' id='our_vision_mission&vision'>
        <div className='container mx-auto p-12 pt-28'>
          <div className='flex justify-start items-center px-8  text-base py-5 '></div>
          <section className='text-center'>
            <h1 className='text-4xl font-bold mb-5'>Mission & Vision</h1>
            <h2 className='text-xl md:text-2xl lg:text-2xl mb-5 mt-5'>
              Our goal at Data Solutions is to protect digital assets and
              advance confidence in the digital space. Our dedication is in
              safeguarding vital infrastructure, combating dynamic threats, and
              enabling people and institutions to securely and safely traverse
              the internet. Our goal is to lead a revolutionized digital
              ecosystem that reduces cyberthreats and maximizes digital
              potential. We work to build a world where cybersecurity is a
              cornerstone of development, allowing people and
              organization&apos;s to prosper in the digital age, via our
              devotion to quality, innovation, and cooperation.
            </h2>
          </section>
        </div>

        {/* Mission */}

        <aside
          className='p-12 container mx-auto  flex justify-self-auto  relative'
          id='our_vision_secure'
        >
          <div className='flex justify-center items-center gap-8 flex-col md:flex-row'>
            <img src={Mission} alt='' className='md:w-2/4 h-96   rounded-3xl' />
            <div className='flex justify-center items-center flex-col gap-8 relative top-6 '>
              <div className='text-4xl font-bold'>
                Our Mission: Accelerate Adoption of Secure Technologies
              </div>
              <div className='text-xl text-gray-500'>
                Collaborate with innovators to provide real-world,
                standards-based cybersecurity capabilities that address business
                needs.{' '}
              </div>
              <marquee>
                <div className='text-8xl  text-gray-100'>MISSION</div>
              </marquee>
            </div>
          </div>
        </aside>

        {/* Vision */}

        <aside
          className=' container mx-auto p-12'
          id='our_vision_cybersecurity'
        >
          <div className='flex justify-center items-center gap-12 flex-col md:flex-row'>
            <div className='flex justify-center items-center flex-col gap-12'>
              <div className='text-5xl font-bold'>
                Our Vision: Advance Cybersecurity
              </div>
              <div className='text-xl text-gray-500 md:sm '>
                A secure cyber infrastructure that inspires technological
                innovation and fosters economic growth.
                <b>Your Safety Our Legacy.</b>
              </div>
              <marquee direction='left'>
                <div className='text-9xl  text-gray-100'>VISION</div>
              </marquee>
            </div>
            <img src={img1} alt='' className='h-96 md:w-2/4 rounded-3xl' />
          </div>
        </aside>

        {/* Goals */}

        <aside className='container mx-auto grid justify-center p-12'>
          <div className='flex justify-center items-center gap-6 flex-col md:flex-row'>
            <img src={Goals} alt='' className='h-96 md:w-2/4 rounded-3xl' />
            <div className='flex justify-centre items-center flex-col gap-2'>
              <div className='text-4xl font-bold w-48 relative  top-2'>
                Our Goals
              </div>
              <div className='text-xl text-gray-500 right-36 pt-2'>
                Provide practical cybersecurity
              </div>
              <div className='md:text-lg text-gray-500'>
                Help organizations secure their data and digital infrastructure
                by equipping them with practical ways to implement
                standards-based, cost-effective, repeatable, and scalable
                cybersecurity solutions.
              </div>
              <div className='font-bold text-xl'>
                Our goals provide cybersecurity for each organization
              </div>
              <div className='text-xl text-gray-500'>
                Enable companies to rapidly adopt commercially available
                cybersecurity technologies by reducing their total cost of
                ownership.{' '}
              </div>
              <div className='font-bold text-xl'>
                Accelerate effective innovation
              </div>
              <div className='relative bottom-4 text-xl text-gray-500 pt-5'>
                Empower innovators to creatively address businesses’ most
                pressing cybersecurity challenges in a state-of-the-art,
                collaborative environment.{' '}
              </div>
            </div>
          </div>
        </aside>

        <aside className='container mx-auto  text-center  px-12 py-8'>
          <aside className='container mx-auto'>
            <div className='flex justify-center items-center gap-6 flex-col md:flex-row '>
              <article className='border-2 flex justify-center items-center flex-col gap-8 rounded-2xl p-2 h-96 w-96'>
                <img src={Standard} alt='' className='h-24 relative top-6' />
                <div className='text-3xl font-semibold'>Standards-based</div>
                <div className='relative  text-18px text-gray-500'>
                  Utilizes pertinent industry standards when implementing
                  security measures, and provide examples of how to adopt new
                  standards
                </div>
              </article>
              <article className='border-2 flex justify-center items-center gap-8 flex-col rounded-2xl p-2 h-96 w-96'>
                <img src={Modular} alt='' className='h-24 relative top-6' />
                <div className='text-3xl font-semibold '>Modular</div>
                <div className='flex justify-center items-center text-18px  text-gray-500'>
                  Develop components that can be easily substituted with
                  alternates that offer equivalent input-output specifications
                </div>
              </article>
              <article className='border-2 flex justify-center items-center flex-col gap-8 rounded-2xl p-2 h-96 w-96'>
                <img src={Repeat} alt='' className='h-24 relative top-6' />
                <div className='text-3xl font-semibold'>Repeatable</div>
                <div className='text-18px text-gray-500'>
                  Provide detailed guidance including a reference design, list
                  of components, configuration files and relevant code{' '}
                </div>
              </article>
            </div>
          </aside>
          <aside className=' container relative top-2 mx-auto'>
            <div className='flex justify-center items-center gap-6 flex-col md:flex-row'>
              <article className='border-2 flex justify-center items-center flex-col gap-8 rounded-2xl p-2 h-96 w-96'>
                <img src={commercial} alt='' className='h-24 relative top-6' />
                <div className='text-3xl font-semibold'>
                  Commercially available
                </div>
                <div className='relative left-2 text-18px text-gray-500'>
                  Work with the technology community to identify commercially
                  available products that can be brought together
                </div>
              </article>
              <article className='border-2 flex justify-center items-center flex-col gap-8 rounded-2xl p-2 h-96 w-96'>
                <img src={Usable} alt='' className='h-24 relative top-6 ' />
                <div className='text-3xl font-semibold'>Usable</div>
                <div className='relative left-2 text-18px text-gray-500'>
                  Design blueprints that end users can easily and
                  cost-effectively adopt and integrate into their businesses
                  without disrupting day-to-day operations
                </div>
              </article>
              <article className='border-2 flex justify-center items-center flex-col gap-8 rounded-2xl p-2 h-96 w-96'>
                <img src={Transparent} alt='' className='h-24 relative top-6' />
                <div className='text-3xl font-semibold'>
                  Open and transparent
                </div>
                <div className='relative left-2 text-18px text-gray-500'>
                  Use open and transparent processes to complete work seek and
                  incorporate public comments on NCCoE publications 
            
                </div>
              </article>
            </div>
          </aside>
        </aside>
      </section>
    </>
  );
};

export default OurVision;