import React from 'react';
import image from '../../../resources/images/home/authentication/Info-Sec-Multi-Factor-Authentication 1.jpg';
import image1 from '../../../resources/images/home/authentication/managedAuthentication.jpg';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';




const Authentication = () => {
  let Navigate = useNavigate();
  let  Authentication = () => {
    Navigate(-1);
  };
  return (
    <div className='min-h-screen md:px-10'>
      <section className=' text-white py-20'>
      <div className='container mx-auto '>
     
      <div className="flex justify-start items-center  text-base py-5 ">
      <div>
        <button onClick={Authentication} className=" text-black ">
          Home
          <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
        </button>
      </div>
      <div>
        <button className="  text-black">Authentication </button>
      </div>
    </div>
      
        <h2 className=' flex justify-center items-center text-black text-5xl font-bold mb-4 p-5 '>
        The Definitive Guide to Authentication</h2>
        <p className= ' flex justify-center items-center text-black py-2 font-semibold'>Authentication is the process of verifying a user or device before allowing access to a system or resources.</p>
        <p className= ' flex justify-center items-center text-black'>Everything you need to know about Authentication in one place</p>
        <p className='text-lg text-black grid justify-start items-start py-5' >
          <img src={image } className=' border-2  rounded-3xl'/></p>
         <p  className='text-lg text-black grid justify-start items-start py-5'> 
         Cyber attacks are a critical threat to organizations today. As more people work remotely and cloud computing becomes the norm across industries, the threat landscape has expanded exponentially in recent years. As a result, 94% of enterprise organizations have experienced a data breach—and 79% were breached in the last two years, according to a recent study by the Identity Defined Security Alliance (IDEA).Additionally, research by Cybersecurity Insiders found that 90% of survey respondents experienced phishing attacks in 2020, and another 29% experienced credential stuffing and brute force attacks—resulting in significant help desk costs from password resets.
        </p>
      </div>
      <h2 className='flex justify-start text-black text-5xl font-bold mb-4 p-5'>
      What is Authentication?
      </h2>
      <p className='  text-black font-semibold p-5'> 
      Authentication is the process of verifying a user or device before allowing access to a system or resources.
      </p>
      <section className=' grid justify-center items-center '>
      <img src={image1 } className=' flex justify-center  h-96 border-2  rounded-3xl'/>
      </section>
      <p  className='text-lg text-black grid justify-start items-start p-5'> 
      In other words, authentication means confirming that a user is who they say they are. This ensures only those with authorized credentials gain access to secure systems. When a user attempts to access information on a network, they must provide secret credentials to prove their identity. Authentication allows you to grant access to the right user at the right time with confidence. But this doesn’t occur in isolation.
        </p>
         <section className='text-black text-xl p-5 ' >
              <div className='text-black text-xl flex justify-start items-start  '>
              Authentication is part of a three-step process for gaining access to digital resources:
              </div>
              <div className=' flex justify-start items-start font-bold text-xl py-4'>Identification—Who are you?</div>
              <hr className='bg-red-500 h-1 w-40 '></hr>
              <div className='text-xl text-black py-5'>
              The most common danger vector for a security compromise is email gateways. Hackers create intricate phishing campaigns using recipients.
              </div>
              <div className='  font-bold text-xl py-6'>
                Authentication—Prove it.</div>
                <hr className='bg-red-500 h-1 w-1/3'></hr>
            
              <div className='relative bottom-4 text-xl text-black py-8'>
              Network traffic is divided into several categories by software-defined segmentation, which also facilitates the enforcement of security regulations. Ideally, endpoint identity—rather than just IP 
              </div>
              <div className=' font-bold text-xl py-6'>
              Authorization—Do you have permission?
             </div>
             <hr className=' bg-red-500 h-1 w-4/6'></hr>
             <div className='relative bottom-4 text-xl text-black py-8'>
             Your network should not be accessible to every user. You need to identify every user and every device in order to keep out any attackers. 
            </div>
          
          </section>

    </section>
   
    </div>
  );
};

export default Authentication;
