import React from 'react';
import img1 from "../../resources/images/home/footerLinks/Data Solutions Insights/Insights1.webp"
import img2 from "../../resources/images/home/footerLinks/Data Solutions Insights/bg3img.jpg"
import img3 from "../../resources/images/home/footerLinks/Data Solutions Insights/bg4img.jpg"




const Insights = () => {

  return (

    <div className='text-lg p-10'>
      <div className='' >
        <button onClick={Insights} className=" text-black  p-10 font-semibold">
          Home

        </button>
        <img
          className='w-full h-[600px] '
          src={img1}
          alt='' />
        <div className='container mx-auto  relative bottom-80 px-10 text-white'>
          <h1 className='text-4xl font-bold '>Data Solutions Insights</h1>
          <h2 className='text-xl'>
            Executives&apos; trusted source for the latest trends, best practices,<br /> research, and insights about the innovation and technology<br /> forces shaping the future of business.
          </h2>
        </div>
      </div>
      <div className=' '>
        <div className='' >
          <p className='text-3xl font-semibold '>
            Improve Employee Productivity
            and Wellbeing <br />with Data Solutions Insights  </p>
        </div>
        <section className='flex flex-col md:flex-row items-center '>

          <img
            src={img2}
            alt='Demo'
            className=' rounded shadow-lg h-96  '
          />
          <div className='p-5'>
            <p className='text-xl  '>
              We already know that  Data Solutions  is an employee experience platform that brings together communications, knowledge, learning, resources, and insights in the workflow. Powered by Data Solutions 365 and experienced through Data Solutions Teams. Data Solutions  Insights is the latest installment. Insights fosters a culture that helps people and businesses thrive with data-driven, privacy-protected insights and recommendations to improve productivity and wellbeing. In the words of Jared Spalato,
              “Data Solutions does not treat work as a &apos;place’ but looks at ways for ‘harnessing human ingenuity to connect employees in an ever-changing world.”
            </p>

          </div>
        </section>

        <section className='flex flex-col md:flex-row items-center  '>

          <div className='p-5'>

            <p className='text-3xl font-bold '>What Does Data Solutions
              Insights Partner Have to Offer You?<br />
            </p>
            <p className='text-xl '> 76% of the remote working employees in the US complained of burnout, 70% reported higher work stress, and more than half agreed to have elevated levels of anxiety. Therefore, creating a culture where people and businesses can thrive together is of utmost importance. Data Solutions Insights inspires organizations to put employee wellbeing at the center of company culture. Data Solutions  Insights uses   Data Solutions Teams, Outlook, and other   Data Solutions 365 apps, services data and signals. Additionally, it can access data from an existing ecosystem of collaboration tools and services like Zoom, Slack and Workday.
              Furthermore, Identify the hot spots in the data provided by Viva Insights. Analysis of this data will help leaders identify burnout.
            </p>


          </div>

          <img
            src={img3}
            alt='Demo'
            className='h-96 rounded shadow-lg  '
          // className='w-full md:w-1/2 rounded shadow-lg '

          />
        </section>
        <div className=' '>
          <p className='text-3xl font-semibold mb-5'>
            What Does Data Solutions Why Do We Need Data Solutions Insights?
          </p>
          <p className='text-2lg  '>
            Workers are not machines. This might seem an obvious statement, yet business technology has ignored this fact for a long time. Whether it is your email, CRM, or even a spreadsheet, IT tools are designed with an exclusive focus on productivity. They are about helping people do work as efficiently as possible.<br />
            While productivity is a good thing, most IT platforms allow you to keep working for as long as you can. However, it forgets that human beings need to stop and sleep. This is where Data Solutions Insights comes in.
          </p>

        </div>
        <div className=''>
          <h1 className='text-3xl font-semibold  '>
            What Does Data Solutions Insights Features
          </h1>
          <p>
            When you turn Data Solutions Insights on, individual employees will notice several features which aim to enhance their wellbeing. These include:
            <li >Focus Time :allows the individual to block our focus time in their diary where they are not disturbed by alerts and notifications and cannot be invited to meetings. So they can use this for creative thinking.</li>
            <li>Personal Insights and Wellbeing: Viva will begin to learn about the individual and help them understand their workday better. It will use artificial intelligence to provide a picture of how they use their time – in terms of things like writing emails, hours spent on tasks, or time in meetings.
            </li>
            <li>Recommendations: Data Solutions Insights will also begin to make personal recommendations to the individual to support their productivity. For example, it might notice you are working on one specific project and therefore provide suggestions for documents 
            </li>

          </p>
        </div>

        <div className='container mx-auto mt-5 text-white p-8 pb-10  bg-[rgb(3,36,61)]'>
          <p className='text-4xl font-semibold '>
            Contact Us About Data Solutions Insights Services
          </p>
          <div className='pt-5' >
            <p >
              Explore how implementing Data Solutions Insights can help your midsize to enterprise-size business perform its best. Your team members are one of your company’s most important investments. So, learn how Data Solutions can transform your performance as an employer. Contact us regarding Data Solutions Insights Consultants by clicking on the live chat button or giving us as at Data Solutions Insights Partner a call at 310-694-3844 today.

            </p>
            <p>
              As a Data Solutions Partner, we can become your Modern Workplace consultant. We can go into detail with you on all the features that Microsoft’s office suite provides, and we can design a system that meets your specific requirements. If needed, we can also meet DoD compliance and the regulatory financial requirements that may be a necessity in your business.
            </p>
            <p >Interested? If so, contact us today and learn more about the software features that can transform your business into a modern workplace.</p>
          </div>
        </div>


      </div>
    </div>

  );
};
export default Insights;