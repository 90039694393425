import React from 'react';
import image from '../../../resources/images/home/fireWall/firewall 1.png';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';
const Firewall = () => {
  let Navigate = useNavigate();

  let fire = () => {
    Navigate(-1);
  };
  return (
   <div className='min-h-screen '>
      <section className=' text-white py-20 p-10 md:px-10'>
      
      <span className="flex justify-start items-center   text-base py-5 ">
          <div>
            <button onClick={fire} className=" text-black ">
              Home
              <FontAwesomeIcon icon={faArrowRight} className='px-2  ' />
            </button>
          </div>
          <div>
            <button className="  text-black">firewall</button>
          </div>
        </span>
     
      <i> <h2 className='flex justify-start text-black text-5xl font-bold mb-4 px-3 '>
      Fire wall
      </h2></i>
          <hr className='bg-red-500 h-1 w-2/4 '></hr>
        <div className='container mx-auto grid justify-center '>
          <section className=' text-black pt-10'>
            <div className='container mx-auto p-4 '>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <img src={image} alt='vision' className='h-80 '  />
                <div className='flex justify-center items-center  text-xl '>
                 Network security is defined as the activity created to protect the integrity of your network and data. Every company or organization that handles a large amount of data, has a degree of solutions against many cyber threats. 
                 Any action intended to safeguard the integrity and usefulness of your data and network is known as network security. This is a broad, all-encompassing phrase that covers software and hardware solutions, as well as procedures, guidelines, and setups for network usage, accessibility, and general threat protection.
                </div>
              </div>
            </div>
          </section>
          <section >
          <div className='container mx-auto text-start grid justify-start items-start  grid-col gap-5 border-2 shadow-2xl rounded-3xl p-10 bg-slate-300'>
         <i> <div className='text-base font-extrabold text-black '>Firewall Network Communication Concepts to Know</div></i>
          <ol className='grid justify-start items-start text-base text-black list-disc px-3'>
          <li className='py-3 '><span className='font-bold text-xl px-1  '>Rule set:</span> 
           A list of rules the firewall compares with the incoming packet.</li>
          <li className='py-3 '><span className='font-bold text-xl px-1 '>Packet:</span>
           A data structure that contains, among other data, the sender and recipient’s IP address.</li>
          <li className='py-3 '><span className='font-bold text-xl px-1 '>IP Address:</span>
          Similar to a physical address, an IP address helps to uniquely identify a user. </li>
          <li className='py-3 '><span className='font-bold text-xl px-1 '>Port Number:</span>
          If an IP address is like a physical address, then a port number is akin to an apartment number. </li>
          <li className='py-3 '><span className='font-bold text-xl px-1 '>Protocol:</span>
          A protocol is like the language two devices are using to communicate. Protocols define the rules of how data is sent and received so that both the device sending a packet and the device receiving a packet can understand it. </li>
          </ol>
        </div>
          </section>
          <section className='text-black text-xl  ' >
             <i> <p className='text-black text-3xl flex justify-start items-center py-5 font-bold  '>
              Advantages of a Firewall</p></i>
              <hr className='bg-red-500 h-1 w-2/4 '></hr>
              <ol className='grid justify-start items-start px-8 list-disc gap-3 text-base'>
                <li>Firewalls create a layer of protection against unauthorized access to your network.</li>
                <li>Firewalls help organizations manage their bandwidth usage</li>
                <li>Firewalls help you monitor your network traffic so you can identify and respond to threats quickly.</li>
                <li>Firewalls prevent viruses and malware from infecting systems and spreading throughout the network.</li>
                
              </ol>
          </section>

          <section className='text-black text-xl  ' >
          <div className='font-bold text-xl py-4'> Network Firewalls</div>
          <div className='text-xl text-black'>
          A network firewall is the most common type of firewall. It inspects and manages both incoming and outgoing communications from the network. For example, a company may place a network firewall to block incoming and outgoing communications from the internet. Organizations may also place network firewalls within their network between what are called network segments. These types of firewalls allow the organization to control communications between different groups of devices. There are different types of network firewalls. 
          </div>
          <div className='font-bold text-xl py-6'>
          Organizational Control 
          </div>
          <div className='relative bottom-4 text-xl text-black'>
          Firewalls also give an organization control over their network traffic. This means it’s easier for organizations to manage their bandwidth use and prioritize what applications they use regularly. For example, you can set rules to ensure that business-critical applications get the resources they need while limiting the amount of bandwidth used by non-essential applications like social media or video streaming. Additionally, firewalls provide real-time visibility into your network traffic, thereby allowing you to detect and respond to potential security threats quickly.
          </div>
         
      
      </section>

        </div>
      </section>
    </div>
  );
};

export default Firewall;
