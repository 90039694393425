import React from 'react';
import image4 from '../../../resources/images/home/virtualPrivate/what-is-a-vpn-q75 vpn1.jpg';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';


const VirtualPrivateNetwork = () => {
  let Navigate = useNavigate();

  let  Virtual= () => {
    Navigate(-1);
  };
  return (
    <div className='min-h-screen p-5 md:px-10'>
      <section className=' text-white py-20'>
        <div className='container mx-auto '>
        
        <div className="flex justify-start items-center px-8  text-base py-5 ">
        <div>
          <button onClick={Virtual} className=" text-black ">
            Home
            <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
          </button>
        </div>
        <div>
          <button className="  text-black">Virtual Private Network</button>
        </div>
      </div>
   
          <h2 className='text-black text-6xl font-bold mb-4 py-5 '>
          Virtual Private Network (VPN)
          <hr className='bg-red-500 h-1 w-3/5 '></hr>
          </h2>
          <p className='text-lg text-black grid justify-center items-center ' >
            <img src={image4 } className=' h-96 border-2  rounded-3xl'/></p>
           <p  className='text-lg text-black grid justify-start items-start py-5'> VPN stands for "Virtual Private Network" and describes the opportunity to establish a protected network connection when using public networks. VPNs encrypt your internet traffic and disguise your online identity. This makes it more difficult for third parties to track your activities online and steal data. The encryption takes place in real time.A VPN connection disguises your data traffic online and protects it from external access. Unencrypted data can be viewed by anyone who has network access and wants to see it. With a VPN, hackers and cyber criminals can’t decipher this data.
          </p>
        </div>
      </section>
      <i><h2 className='flex justify-start text-black text-4xl font-bold mb-4 p-5'>
      What are the benefits of a VPN connection?    
      </h2></i>
      <section className='text-black text-xl px-6 ' >
              <div className='text-black text-xl flex justify-start items-start  '>
                There are several types of network security through which we can make our network more secure, Your network and data are shielded from breaches, invasions, and other dangers by network security. Here below are some important types of network security:
              </div>
              <div className='font-bold text-xl py-4'> Secure encryption:</div>
              <div className='text-xl text-black'>
              The most common danger vector for a security compromise is email gateways. Hackers create intricate phishing campaigns using recipients’ personal information and social engineering techniques to trick them and direct them to malicious websites. To stop critical data from being lost, an email security programme restricts outgoing messages and stops incoming threats.
              </div>
              <div className='font-bold text-xl py-6'>
              Disguising your whereabouts : 
              </div>
              <div className='relative bottom-4 text-xl text-black'>
              Network traffic is divided into several categories by software-defined segmentation, which also facilitates the enforcement of security regulations. Ideally, endpoint identity—rather than just IP addresses—is the basis for the classifications. To ensure that the appropriate amount of access is granted to the appropriate individuals and that suspicious devices are controlled and remediated, access permissions can be assigned based on role, location, and other factors.
              </div>
              <div className='font-bold text-xl py-6'>
              Access to regional content:
             </div>
             <div className='relative bottom-4 text-xl text-black'>
             Your network should not be accessible to every user. You need to identify every user and every device in order to keep out any attackers. You can then put your security policies into effect. Noncompliant endpoint devices might either have their access restricted or blocked. Network access control (NAC) is this process.
            </div>
          
         
          
      </section>

    </div>
  );
};

export default VirtualPrivateNetwork;
