import React from 'react';
import img2 from "../../resources/images/partners/partnerLinks/governance/dataGovernance.webp"


const Governance = () => {
  return (
    <div className=' text-lg p-16 '>
      <div className='h-16'></div>
      <div className='pb-4'>
        <button onClick={Governance} className=" text-black  font-semibold">
          Products & Products
        </button>
      </div>
      <div className=''>
        <div className= 'container mx-auto text-white pl-4 h-72 bg-[rgb(13,13,83)] md:rounded-br-full flex flex-col justify-center '>
          <p className='md:text-6xl font-bold  '>
            Data Access Security
          </p>
          <p className='md:text-2xl font-semibold '> 
            Discover, govern, and secure real-time access to sensitive data.<br /> Enhance the power of identity security with enriched context<br /> and insights on critical data access.</p>
        </div>

        <section className='flex flex-col md:flex-row items-center mt-5 '>
          <div>
            <p className='text-4xl font-bold'>
              Say hello to integrated data governance
            </p>
            <p className='text-xl py-5 '>
              Data Access Security extends your identity security program by identifying your sensitive, regulated, and critical data assets within your environment. Minimize sensitive data exposure risks with automated data discovery, classification, and comprehensive access analysis.

              See who has access to your sensitive data and how it&apos;s being used with real-time activity monitoring. Increase productivity with automated governance workflows and customizable data access policies.

              Get detailed data access insights to proactively lock down sensitive data, reduce your attack surface, and enable zero-trust security.
            </p>
          </div>
          <img
            src={img2}
            alt='Demo'
            className='shadow-lg md:w-1/2 rounded-3xl'
          />
        </section>
        <div className='my-10'>
          <p className='text-5xl font-bold'>
            Govern and protect your sensitive data
          </p>
          <p className='  text-2xl py-3'>
            Employees are creating, downloading, extracting, copying, and sharing data across an ever-growing digital ecosystem. As a result, the potential attack surface has expanded. The risks: data breach, data loss, and lasting reputation&apos;s damage.
          </p>
          <p className=' text-xl'>
            Data Access Security helps organizations keep business-critical data safe.

          </p>
        </div>
        <section className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10'>

          <div className='p-5 bg-white shadow rounded'>
            <p className='text-2xl font-semibold mb-3'>
              Discover and classify sensitive data

            </p>
            <p>
              Understand your unstructured data risks. Prioritize the most sensitive data and lock it down to minimize your attack surface.


            </p>
          </div>
          <div className='p-5 bg-white shadow rounded'>
            <p className='text-2xl font-semibold'>
              Enable risk-based data access decisions</p>
            <p>
              Enable smarter governance and expedite reviews with enhanced context on sensitive data access for entitlements and roles.
            </p>
          </div>
          <div className='p-5 bg-white shadow rounded'>
            <p className='text-2xl font-semibold'>
              Enhance your identity and data security posture

            </p>
            <p>
              Uncover hidden data risk and monitor security progress through shared dashboards and integrated reporting.</p>
          </div>
        </section>
        <div className='my-10'>
          <p className='text-4xl font-bold pb-5'>
            Start your identity security journey today

          </p>
          <p className='text-xl'>
            Data Solution&apos;s Identity Security Cloud solution enables organizations to manage and secure real-time access to critical data and applications for every enterprise identity with an intelligent and unified approach, unlike other identity governance solutions on the market today.
          </p>
        </div>
      </div>

      <div className='my-10 bg-gray-600 h-80 w-full p-2 flex justify-evenly items-center flex-col text-white'>
        <p className='text-5xl font-bold'>
          GET STARTED
          </p>
        <p className='md:text-5xl font-bold '>
          Ready to take control of your
          critical data?
        </p>
        <p className='text-xl'>
          Get a demo to see how Data Access Security can help, or contact our team to discuss your unique security needs.
        </p>
      </div>
    </div>
 );
};
export default Governance;