import React from 'react';
import image from '../../../resources/images/home/intrusionPrevention/Intrusion Prevention System 1.jpg';
import image1 from '../../../resources/images/home/intrusionPrevention/icon-gradient-deploy-232x300px ips 4.png';
import image2 from '../../../resources/images/home/intrusionPrevention/icon-gradient-molecular-232x300px ips 3.png';
import image3 from '../../../resources/images/home/intrusionPrevention/icon-gradient-prevent-232x300px ips 2.png';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';


const IntrusionPreventionSystem = () => {
  let Navigate = useNavigate();

  let Prevention  = () => {
    Navigate(-1);
  };
  return (
    <div className='min-h-screen md:px-10'>
      <section className=' text-white py-20'>
      
        <div className='container mx-auto text-center'>
          
      <div className="flex justify-start items-center  text-base py-5 ">
      <div>
        <button onClick={Prevention} className=" text-black ">
          Home
          <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
        </button>
      </div>
      <div>
        <button className="  text-black"> Intrusion Prevention System</button>
      </div>
    </div>
    
        <section className=' text-black pt-10 bg-gray-400'>
        <div className='container mx-auto p-4 '>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
          
            <div className='flex justify-center items-center  text-xl '>
            <p className='grid justify-start items-start'> <span className='font-bold text-3xl py-4'>What is an Intrusion Prevention System – IPS</span>
            
             In short, an Intrusion Prevention System (IPS), also known as intrusion detection prevention system , is a technology that keeps an eye on a network for any malicious activities attempting to exploit a known vulnerability.Intrusion Prevention Systems detect or prevent attempts to exploit weaknesses in vulnerable systems or applications, protecting you in the race to exploit the latest breaking threat. Check Point IPS protections in our Next Generation Firewall are updated automatically. Whether the vulnerability was released years ago, or a few minutes ago, your organization is protected.


             </p>
            </div>
            <img src={image} alt='vision' className='h-96  border-2  rounded-3xl '  />
          </div>
        </div>
      </section>
      <section>
      <h1></h1>
      </section>
      <section>
      <section className='bg-white text-black'>
        <div className='container mx-auto text-center'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-8 py-5'>
            
            
            <div className='bg-white p-6 rounded-lg shadow-box'>
            <img src={ image3} className=' h-52 px-14 '/>
            <h3 className='text-black text-xl font-semibold mb-4'>
            Secure
            </h3>
            <p className='text-gray-600'>
            Check Point IPS delivers thousands of signature and behavioral preemptive protections
            </p>
            
           
          
          </div>
                
          <div className='bg-white p-6 rounded-lg shadow-box'>
          <img src={ image2} className=' h-52 px-14'/>
          <h3 className='text-black text-xl font-semibold mb-4'>
          Efficient
          </h3>
          <p className='text-gray-600'>
          Our acceleration technologies let you safely enable IPS. A low false positive rate saves your staff valuable time
          </p>
          
         
        
        </div>
              
        <div className='bg-white p-6 rounded-lg shadow-box'>
        <img src={ image1} className=' h-52 px-14'/>
        <h3 className='text-black text-xl font-semibold mb-4'>
        Unified
        </h3>
        <p className='text-gray-600'>
        Enable IPS on any Check Point security gateway reducing Total Cost of Ownership
        </p>
        
       
      
       </div>

          
             
            
          </div>
        </div>
      </section>
      </section>
        </div>
      </section>
    </div>
  );
};

export default IntrusionPreventionSystem;
