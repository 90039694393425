import React from 'react';
import image from '../../../../resources/images/home/homepage/regular/wed.webp';
import image1 from '../../../../resources/images/home/homepage/regular/dataProjects.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'

const RegularSecurityAssessmentsAndAudits = () => {
  let Navi= useNavigate()
  let Handleyou=()=>{
    Navi(-1)
  
  }
  return (
    <div className='min-h-screen'>
      <section className=' text-white py-20 p-5'>
        <div className='container mx-auto text-center'>
       
        <div className="flex justify-start items-center   text-base py-5 ">
        <div>
          <button onClick={Handleyou} className=" text-black ">
            Home
            <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
          </button>
        </div>
        <div>
          <button className="  text-black">Regular Security Assessments And Audits  </button>
        </div>
      </div>
        
        <section className=' bg-blue-50 text-black pt-10 border-2  rounded-3xl   '>
        <div className='flex justify-center items-center text-5xl  py-5  font-bold '>
        <div className='flex justify-center items-center text-5xl  '>
        The Importance of Regular Security Audits
      </div>
      </div>
        <div className='grid justify-start items-center  grid-cols-1 py-2'>
        <img src={image} alt='vision' className='h-72 min-w-96 border-2 shadow-2xl rounded-2xl'  />
        <p className=' grid justify-center items-center text-2xl font-semibold italic capitalize'>Assessments And Audits </p>
        </div>
        
        <div className='container mx-auto p-4 '>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <div className='flex justify-center items-center text-5xl  '>
            
          </div>
          <div className='   rounded-3xl grid justify-center items-end grid-flow-col'>
          <img src={image1} alt='vision' className='h-72 min-w-96 border-2 shadow-2xl rounded-2xl'  />
          
            </div>
            <p className='grid justify-center items-center  text-2xl font-semibold italic capitalize'>
               Data Solutions Tree, This process is different from a firewall mechanism
            </p>
        </div>
      </div>
      </section>
      <section className='text-black  p-5' >
     <p className='py-3   text-4xl'>
     Understanding Regular Security Audits
     </p>
     <p className='py-4'>
     Regular security audits involve a thorough evaluation of an organization’s information systems, covering various aspects such as physical components, applications, software, network vulnerabilities, and the human dimension.
     </p>
     <i><h1  className='py-5 text-3xl font-bold'>Comparing IT Practices and Criteria</h1></i>
     <hr className='bg-red-500 h-1 w-2/4 '></hr>
     <p  className='py-8'>
     Regular security audits involve a comparison of an organization’s actual IT practices with internal and external criteria, enabling them to identify areas for improvement and enhance their security posture. These audits play a vital role in ensuring that an organization’s information systems are aligned with industry best practices and regulatory requirements. By evaluating their IT practices against established criteria, organizations can identify any weaknesses or vulnerabilities and take appropriate measures to address them.During a security audit, internal criteria are set by the organization itself, based on their specific security objectives and requirements. This includes policies, procedures, and guidelines that govern the organization’s IT operations. External criteria, on the other hand, are established by industry standards, regulations, and frameworks. These external benchmarks provide organizations with a benchmark against which they can measure their security practices.
     </p>
     <i><h1  className='py-5 text-3xl font-bold'> Summary</h1></i>
     <hr className='bg-red-500 h-1 w-3/5 '></hr>
     <p className='py-6'>
     Regular security audits are essential for organizations to assess their cybersecurity risk environment and continuously improve their security practices. Through a comprehensive assessment of information systems and a comparison of actual IT practices with internal and external criteria, audits provide valuable insights into areas for improvement. The recommendations provided during these audits enable organizations to enhance their security posture, protect sensitive data, and effectively mitigate security risks.
</p>
<section>
<table class="border border-collapse border-black w-full">
<tr>
    <th class="border border-black px-4 py-2">Key Points</th>
    <th class="border border-black px-4 py-2">Benefits</th>
   
</tr>
<tr>
    <th class="border border-black px-4 py-2 font-normal">
    Key Points	Benefits
    Comparing IT practices with internal and external criteria</th>
    <th class="border border-black px-4 py-2 font-normal" >Identifying areas for improvement</th>
   
</tr>
<tr>
    <th class="border border-black px-4 py-2 font-normal ">Providing recommendations for enhancement</th>
    <th class="border border-black px-4 py-2 font-normal ">Strengthening security posture</th>
   
</tr>
<tr>
    <th class="border border-black px-4 py-2 font-normal ">Mitigating security risks	</th>
    <th class="border border-black px-4 py-2 font-normal ">	Protecting sensitive data</th>
   
</tr>



</table>
</section>
<section className='text-black  p-5' >
 <i><h1  className='py-5 text-3xl font-bold'>Achieving Compliance and Risk Mitigation</h1></i>
     <hr className='bg-red-500 h-1 w-2/4 '></hr>
     <p  className='py-8'>
     Regular security audits are instrumental in helping organizations achieve compliance with regulations and standards, while also supporting the development of robust risk assessment plans to mitigate security risks. These audits play a crucial role in ensuring that organizations are operating within the legal and regulatory frameworks relevant to their industry. By conducting regular security audits, organizations can identify any non-compliance issues and take proactive steps to address them, avoiding potential penalties or legal consequences.During a security audit, organizations assess their current security practices and compare them against established regulations and standards. This evaluation helps identify any gaps or weaknesses that need to be addressed. By aligning their practices with regulatory requirements, organizations can demonstrate their commitment to maintaining high levels of security and safeguarding sensitive information.
     </p>
</section>
<section>
<table class="border border-collapse border-black w-full">
<tr>
    <th class="border border-black px-4 py-2">Benefits of Regular Security Audits:</th>
    
   
</tr>
<tr>
    <th class=" flex justify-start items-start border border-black px-4 py-2 font-normal">
    Continual assessment and enhancement of security measures
    </th>
    
   
</tr>
<tr>
    <th class=" flex justify-start items-start border border-black px-4 py-2 font-normal ">Comprehensive assessment of information systems</th>
    
   
</tr>
<tr>
    <th class=" flex justify-start items-start border border-black px-4 py-2 font-normal ">Alignment with internal and external criteria	</th>
   
   
</tr>
<tr>
    <th class=" flex justify-start items-start border border-black px-4 py-2 font-normal ">	Recommendations for improvement</th>
   
   
</tr>
<tr>
    <th class=" flex justify-start items-start border border-black px-4 py-2 font-normal ">	Achievement of compliance with regulations and standards</th>
   
   
</tr>
<tr>
    <th class=" flex justify-start items-start border border-black px-4 py-2 font-normal ">	Identification and addressing of security weaknesses</th>
   
   
</tr>

<tr>
    <th class=" flex justify-start items-start border border-black px-4 py-2 font-normal ">Protection of sensitive data	</th>
   
   
</tr>

</table>
</section>

   
     </section>
        </div>
      </section>
    </div>
  );
};

export default RegularSecurityAssessmentsAndAudits;