import React from "react";
import { Link } from "react-router-dom";
import img1 from '../../resources/images/Solutions&Capabilities/siemSOC/Siem.webp';
import img2 from '../../resources/images/Solutions&Capabilities/siemSOC/soc-network.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, } from '@fortawesome/free-solid-svg-icons';

const siemSoc = () => {

    return (
        <div className="min-h-screen px-12">
            <section className=" text-black p-4 py-16">
                <div className="flex justify-start items-center px-2  text-base py-5 ">
                    <Link
                        to="/services"
                        className={` text-black  px-3 py-8
                }`}
                    >
                        Solution&Capability
                        <FontAwesomeIcon icon={faArrowRight} className="px-2  text-black" />
                        SIEMSOC
                    </Link>
                    </div>

                <div className='container mx-auto text-white text-center h-72 bg-slate-400 flex flex-col justify-center '>
                    <p className='xl:text-6xl text-3xl font-bold  '>
                        Security information&event management (SIEM) and Security operations center (SOC)
                    </p>
                </div>
                <section className='flex flex-col xl:flex-row items-center  '>
                    <div className=' '>
                        <p className='text-5xl '>
                            What is SIEM?
                        </p>
                        <p className=' text-xl py-3'>
                            Security information and event management, or SIEM, is a security solution that helps organizations recognize and address potential security threats and vulnerabilities before they have a chance to disrupt business operations.</p>
                        <p className='  text-xl py-3'>
                            SIEM systems help enterprise security teams detect user behavior anomalies and use artificial intelligence (AI) to automate many of the manual processes associated with threat detection and incident response.</p>
                        <p className='  text-xl  py-3'>
                            The original SIEM platforms were log management tools. They combined security information management (SIM) and security event management (SEM) functions. These platforms enabled real-time monitoring and analysis of security-related events.
                        </p>

                    </div>
                    <img
                        src={img1}
                        alt='Demo'
                        className='h-[500px] md:w-1/2 rounded shadow-lg'
                    />
                </section>

                <div className=''>
                    <p className='text-5xl '>
                        How does SIEM work?
                    </p>
                    <p className='  text-xl py-3'>
                        At the most basic level, all SIEM solutions perform some level of data aggregation, consolidation and sorting functions to identify threats and adhere to data compliance requirements. While some solutions vary in capability, most offer the same core set of functions: </p>
                </div>
                <div className=''>
                    <p className='text-5xl '>
                        Log management
                    </p>
                    <p className='  text-xl py-3'>
                        SIEM ingests event data from a wide range of sources across an organization’s entire IT infrastructure, including on-premises and cloud environments.</p>

                    <p className='  text-xl py-3'>
                        Event log data from users, endpoints, applications, data sources, cloud workloads and networks, as well as data from security hardware and software, such as firewalls or antivirus software, is collected, correlated and analyzed in real-time.</p>

                    <p className='  text-xl py-3'>
                        Some SIEM solutions also integrate with third-party threat intelligence feeds to correlate their internal security data against previously recognized threat signatures and profiles. Integration with real-time threat feeds enables teams to block or detect new types of attack signatures.</p>
                </div>


                <section className='flex flex-col xl:flex-row items-center py-2'>

                    <div className=''>
                        <p className='text-5xl'>
                            What is a SOC?
                        </p>
                        <p className='  text-xl py-3'>
                            A security operations center (SOC) improves an organization's threat detection, response and prevention capabilities by unifying and coordinating all cybersecurity technologies and operations.
                        </p>
                        <p className='text-xl py-3'>
                            A SOC—usually pronounced "sock" and sometimes called an information security operations center, or ISOC—is an in-house or outsourced team of IT security professionals dedicated to monitoring an organization’s entire IT infrastructure 24x7. Its mission is to detect, analyze and respond to security incidents in real-time. This orchestration of cybersecurity functions allows the SOC team to maintain vigilance over the organization’s networks, systems and applications and ensures a proactive defense posture against cyber threats.</p>
                        <p className='text-xl py-3' > The SOC also selects, operates and maintains the organization's cybersecurity technologies and continually analyzes threat data to find ways to improve the organization's security posture.
                        </p>
                        <p className='text-xl py-3 '>
                            When not on premises, a SOC is often part of outsourced managed security services (MSS) offered by a managed security service provider (MSSP). The chief benefit of operating or outsourcing a SOC is that it unifies and coordinates an organization’s security system, including its security tools, practices and response to security incidents. This usually results in improved preventative measures and security policies, faster threat detection, and faster, more effective and more cost-effective response to security threats. A SOC can also improve customer confidence, and simplify and strengthen an organization's compliance with industry, national and global privacy regulations.
                        </p>
                    </div>
                    <img
                        src={img2}
                        alt='Demo'
                        className='h-[700px] md:w-2/3 rounded shadow-lg'
                    />

                </section>
            </section>
        </div>
    
    );
};

export default siemSoc;
