import React from "react";
import image from "../../../resources/images/home/networkSecurity/Network.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRight,} from '@fortawesome/free-solid-svg-icons';

const NetworkSecurity = () => {
  let Navigate = useNavigate();
  let se=()=>{
    
    Navigate("/homePage/Network1Seg")
  }

  let nets = () => {
    Navigate(-1);
  };
  return (
    <div className="min-h-screen md:px-10">
      <section className=" text-white p-4  py-16">
        <div className="flex justify-start items-center px-8  text-base py-5 ">
          <div>
            <button onClick={nets} className=" text-black ">
              Home
              <FontAwesomeIcon icon={faArrowRight} className='px-2 ' />
            </button>
          </div>
          <div>
            <button className="  text-black ">Network security</button>
          </div>
        </div>

        <h2 className="flex justify-center text-black text-5xl font-bold mb-4 px-3">
          Network Security
        </h2>
        <div className="container mx-auto grid justify-center ">
          <section className=" text-black pt-10">
            <div className="container mx-auto p-4 ">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <img src={image} alt="vision" />
                <div className="flex justify-center items-center text-center text-xl ">
                  Network security is defined as the activity created to protect
                  the integrity of your network and data. Every company or
                  organization that handles a large amount of data, has a degree
                  of solutions against many cyber threats. Any action intended
                  to safeguard the integrity and usefulness of your data and
                  network is known as network security. This is a broad,
                  all-encompassing phrase that covers software and hardware
                  solutions, as well as procedures, guidelines, and setups for
                  network usage, accessibility, and general threat protection.
                </div>
              </div>
            </div>
          </section>
          <section className="text-black text-xl p-3 ">
            <p className="text-black text-xl flex justify-start items-center py-5 ">
              The most basic example of Network Security is password protection
              which the user of the network chooses. In recent times, Network
              Security has become the central topic of cyber security with many
              organizations inviting applications from people who have skills in
              this area. The network security solutions protect various
              vulnerabilities of the computer systems such as:
            </p>
            <ol className="grid justify-start items-start px-8 list-decimal gap-3">
              <li>Users</li>
              <li>Locations</li>
              <li>Data</li>
              <li>Devices</li>
              <li>Applications</li>
            </ol>
          </section>
          <h2 className="flex justify-start text-black text-4xl font-bold mb-4 p-3">
            Types of Network Security
          </h2>
          <section className="text-black text-xl p-3 ">
            <div className="text-black text-xl flex justify-start items-start  ">
              There are several types of network security through which we can
              make our network more secure, Your network and data are shielded
              from breaches, invasions, and other dangers by network security.
              Here below are some important types of network security:
            </div>
            <div className="font-bold text-xl py-4"> 1. Email Security</div>
            <div className="text-xl text-black">
              The most common danger vector for a security compromise is email
              gateways. Hackers create intricate phishing campaigns using
              recipients’ personal information and social engineering techniques
              to trick them and direct them to malicious websites. To stop
              critical data from being lost, an email security programme
              restricts outgoing messages and stops incoming threats.
            </div>
            <div className="font-bold text-xl py-6">
              2. Network Segmentation
            </div>
            <div className="relative bottom-4 text-xl text-black">
              Network traffic is divided into several categories by
              software-defined segmentation, which also facilitates the
              enforcement of security regulations. Ideally, endpoint
              identity—rather than just IP addresses—is the basis for the
              classifications. To ensure that the appropriate amount of access
              is granted to the appropriate individuals and that suspicious
              devices are controlled and remediated, access permissions can be
              assigned based on role, location, and other factors.
              <button  onClick={se} className="text-blue-900 underline px-2">
                more
              </button>
            </div>

            <div className="font-bold text-xl py-6">3. Access Control</div>
            <div className="relative bottom-4 text-xl text-black">
              Your network should not be accessible to every user. You need to
              identify every user and every device in order to keep out any
              attackers. You can then put your security policies into effect.
              Noncompliant endpoint devices might either have their access
              restricted or blocked. Network access control (NAC) is this
              process.
            </div>
          </section>
        </div>
      </section>
    </div>
  );
};

export default NetworkSecurity;
