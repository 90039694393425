import React from "react";
import image from "../../resources/images/partners/partnerLinks/knowledgeCenter/knowledgeCenter.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faIdCard } from "@fortawesome/free-solid-svg-icons";
import { Link} from "react-router-dom";

const knowledgeCenter = () => {
  return (
    <div className="min-h-screen md:px-10">
      <section className=" text-white py-20">
        <div className="flex justify-start items-center px-2  text-base py-5 ">
          <Link
            to="/Partners"
            className={` text-black  px-3 py-10
                }`}
          >
            Partners
            <FontAwesomeIcon icon={faArrowRight} className="px-2  text-black" />
          </Link>

          <div>
            <button className="  text-black">knowledge Center</button>
          </div>
        </div>
        <div className="container mx-auto ">
          <section className=" text-black pt-10   rounded-3xl py-16">
            <div className="container mx-auto   ">
              <div className="grid  justify-center  items-center text-6xl font-bold capitalize py-2  text-gray-800 ">
                knowledge Center
              </div>
              <p className=" flex justify-center  items-center text-2xl py-4 font-semibold text-gray-600">
                How to Get Started in Cybersecurity: A Beginner’s Roadmap
              </p>
              <p className=" flex justify-center  items-center  text-xl ">
                Learn all about how to start a career in cybersecurity: this is
                a step-by-step guide to ensure a successful career.
              </p>
              <div className="flex justify-center py-14">
                <button className=" z-10  bg-blue-600 rounded-3xl text-white ">
                  <a
                    href="/ourVision"
                    className="flex justify-center items-center  relative bg-transparent before:rounded-xl  transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-blue-400 before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100  p-2 w-36   "
                  >
                    Learn{" "}
                  </a>
                </button>
              </div>
              <div className=" flex justify-center ">
                <img src={image} alt="vision" className=" " />
              </div>
            </div>
          </section>
          <section className="flex justify-center items-center h-center ">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 my-10">
              <div className="grid grid-cols-1 gap-4 shadow-xl rounded-xl border-2 p-2">
                <h1 className="text-black flex justify-center items-center text-2xl">
                  Malware
                </h1>
                <h5 className="text-gray-600 text-center p-6 h-40">
                  Viruses: These are harmful programs that, when executed,
                  replicate themselves by modifying other computer programs and
                  inserting their own code.
                </h5>
              </div>

              <div className="grid grid-cols-1 gap-4 shadow-xl rounded-xl border-2 p-2">
                <h1 className="text-black flex justify-center items-center text-2xl">
                  Phishing
                </h1>
                <h5 className="text-gray-600 text-center p-6 h-40">
                  This threat involves cyber criminals posing as legitimate
                  institutions via email or messaging platforms to lure
                  individuals into providing sensitive data and Social Security
                  numbers.
                </h5>
              </div>

              <div className="grid grid-cols-1 gap-4 shadow-xl rounded-xl border-2 p-2 ">
                <h1 className="text-black flex justify-center items-center text-2xl">
                  Hacking
                </h1>
                <h5 className="text-gray-600  text-center p-6 h-40">
                  This refers to unauthorized intrusion into computer systems or
                  networks by individuals or to gather information, or to
                  disrupt services.
                </h5>
              </div>
            </div>
          </section>
          <section className="grid justify-center items-center py-5">
            <h1 className=" text-gray-800   text-3xl font-bold  flex justify-center items-center py-2">
              Why to Join Us?
            </h1>
            <p className="text-gray-500 flex justify-center items-center px-80 py-5">
              Join us at the Center for Cyber Security Studies & Research and be
              part of a mission-driven non-profit organization committed to
              shaping the future of cybersecurity. By becoming a member, you not
              only gain access to cutting-edge knowledge and resources but also
              contribute to a greater cause — enhancing digital safety and
              security globally. Embrace the opportunity to collaborate, learn,
              and grow with a community dedicated to making a lasting impact in
              the ever-evolving world of cybersecurity.
            </p>
            <div className="flex justify-center py-8">
              <button className=" z-10  bg-blue-600 rounded-3xl text-white ">
                <a
                  href="/ourVision"
                  className="flex justify-center items-center  relative bg-transparent before:rounded-xl  transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-yellow-200 before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100  p-2 w-36   "
                >
                  Learn{" "}
                </a>
              </button>
            </div>
          </section>
          <section className="text-black">
            <h1 className="grid justify-start items-start text-3xl p-5 text-black font-bold">
              Specializations within cybersecurity{" "}
            </h1>
            <hr className="bg-red-500 h-1 w-3/5 "></hr>
            <p className="p-5">
              Cybersecurity is a broad field with various specialized roles that
              cater to different aspects of security and protection. Choosing a
              specialization can help professionals focus their skills on
              specific areas, making them more effective and in-demand
              experts.Effective penetration testers need a robust set of
              technical skills including knowledge of networking, programming,
              and security protocols. Common tools used in penetration testing
              include, Burp Suite, and OWASP ZAP. Strong
              problem-solving skills and creativity are also essential to
              thinking like a hacker and anticipating unconventional security
              flaws.
            </p>
          </section>
          <section className=" text-white pt-10  grid justify-center items-center   py-16 bg-gray-700">
            <h1 className=" flex  justify-center items-center text-3xl capitalize py-5 font-bold">
              join and learn in world of Cyber security today!
            </h1>
            <p className="text-xl flex justify-center items-center  ">
              Join our community of Enthusiastic members today and be part of a
              network{" "}
            </p>
            <p className="text-xl flex justify-center items-center ">
              dedicated to advancing and innovating in the world
              of cybersecurity!
            </p>
            <div className="flex justify-center py-8">
              <button className=" z-10  bg-blue-600 rounded-3xl text-black ">
                <a
                  href="/homePage"
                  className="flex justify-center items-center   p-2 w-52 hover:text-white  hover:bg-blue-700 rounded-3xl italic "
                >
                  start knowing about us{" "}
                </a>
              </button>
            </div>
          </section>
          <section className="grid justify-end p-5   ">
            <button className="  bg-green-600 rounded-3xl text-black  hover:text-gray-700 fixed bottom-4 right-4 ">
              <a
                href="/contact"
                className="flex justify-center items-center  p-2 w-60 rounded-3xl  "
              >
                <FontAwesomeIcon icon={faIdCard} className="p-2" />
                How can I Help you ?{" "}
              </a>
            </button>
          </section>
        </div>
      </section>
    </div>
  );
};

export default knowledgeCenter;
