import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import dataSolutions1Logo from "../../resources/images/navbar/dataSolution.png.png";
import { faChevronDown , faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("");
  const location = useLocation();

  useEffect(() => {
    // Update the current screen name based on the location
    const pathToScreenName = {
      "/about": "About",
      "/partners": "Products & Partners",
      "/services": "Solutions & Capabilities",
      "/requestADemo": "Request a Demo",
      "/ourVision": "Our Vision",
      "/contact": "Contact Us",
    };
    setCurrentScreen(
      pathToScreenName[location.pathname] || (
        <img src={dataSolutions1Logo} className="h-12 w-40 flex" alt="vision" />
      )
    );
  }, [location.pathname]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
    // setCurrentScreen(screenName); // Update the current screen name
  };

  return (
    <div className="flex justify-center items-center">
     
      <nav className="navbar backdrop-blur-lg border-b-gray-700 ">
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex-shrink-0">
              <Link
                to="/homepage"
                className="text-black-300 px-3 py-1 rounded-sm text-xl font-semibold"
              >
                {window.innerWidth <= 768 ? (
                  currentScreen
                ) : (
                  <img
                    src={dataSolutions1Logo}
                    className="h-28 w-48 flex justify-center  pt-3.5"
                    alt="vision"
                  />
                )}
              </Link>
            </div>

            <div className="md:hidden" onClick={toggleMenu}>
              <div className="space-y-2">
                <span className="block w-8 h-0.5 bg-black"></span>
                <span className="block w-8 h-0.5 bg-black"></span>
                <span className="block w-8 h-0.5 bg-black"></span>
              </div>
            </div>
            <div className="hidden md:flex space-x-4">
              <div className="group dropdown dropdown-6 hover:text-blue-400">
                <Link
                  to="/services"
                  className={`  text-black-300 hover:text-black px-3 py-10 rounded-md text-md  font-semibold ${
                    location.pathname === "/services" ? "underline" : ""
                  }`}
                >
                  Solutions & Capabilities
                 
                </Link>
                <span><FontAwesomeIcon
                icon={faChevronDown} 
               className="px-1.5 group-hover:rotate-180 "
             /></span>
                {
                  <div
                    className={`hover-menu  z-10 dropdown_menu dropdown_menu--animated dropdown_menu-6  text-black rounded-lg  py-2`}
                  >
                    <section className="bg-slate-100   rounded-3xl grid  justify-center items-start grid-flow-col   ">
                      <div className="  z-10  p-4  ">
                        <div className=" relative bg-transparent before:rounded-xl  transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-white before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100  p-2  ">
                          <a
                            href="/services/#services_application_security"
                            className="block   py-2 0 rounded font-semibold "
                          >

                           
                            Application Security
                            
                            <p className="text-sm font-normal">
                              get know more About Application Security{" "}
                            </p>
                          </a>
                        </div>
                        <div className="relative bg-transparent before:rounded-xl  transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-white before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100 p-2  ">
                          <a
                            href="/services/#services_network_security"
                            className="block  py-2 rounded 0 font-semibold  "
                          >
                            Network Security
                           
                            <p className="text-sm font-normal">
                              get know more About Network Security{" "}
                            </p>
                          </a>
                        </div>
                        <div className="relative bg-transparent before:rounded-xl  transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-white before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100  p-2">
                          <a
                            href="/services/#services_siem_&_soc"
                            className="block   py-2  rounded 0  font-semibold"
                          >
                            SIEM & SOC
                            
                            <p className="text-sm font-normal">
                              get know more About SIEM & SOC
                            </p>
                          </a>
                        </div>
                      </div>
                      <div className=" z-10 p-4  ">
                        <div className=" relative bg-transparent before:rounded-xl  transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-white before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100 p-2 ">
                          <a
                            href="/services/#services_penetration_testing"
                            className="block  py-2  rounded 0  font-semibold"
                          >
                            Penetration Testing
                           
                            <p className="text-sm font-normal">
                              get know more About Penetration Testing{" "}
                            </p>
                          </a>
                        </div>
                        <div className=" relative bg-transparent before:rounded-xl  transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-white before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100 p-2">
                          <a
                            href="/services/#compliance_and_advisory"
                            className="block  py-2 rounded 0 font-semibold"
                          >
                            Compliance And Advisory
                           
                            <p className="text-sm font-normal">
                              get know more About Compliance And Advisory{" "}
                            </p>
                          </a>
                        </div>
                        <div className="relative bg-transparent before:rounded-xl  transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left  before:scale-x-0 before:bg-white before:transition-transform before:duration-300 before:content-[''] hover:text-black before:hover:scale-x-100 p-2 ">
                          <a
                            href="/services/#services_threat_monitory_&_protection"
                            className="block  py-2  rounded 0 font-semibold"
                          >
                            Threat Monitory & Protection
                           
                            <p className="text-sm font-normal">
                              get know more About Threat Monitory & Protection{" "}
                            </p>
                          </a>
                        </div>
                      </div>
                    </section>
                  </div>
                }
              </div>
              <div className="dropdown dropdown-6">
                <Link
                  to="/partners"
                  className={`text-black-300 hover:text-black px-3 py-5 rounded-md text-md font-semibold ${
                    location.pathname === "/partners" ? "underline" : ""
                  }`}
                >
                  Products & Partners
                </Link>
               
              </div>
              <div className="group">
                <Link
                  to="/requestADemo"
                  className={`text-black-300  hover:text-black px-3 py-5 rounded-md text-md font-semibold ${
                    location.pathname === "/requestADemo" ? "underline" : ""
                  }`}
                >
                  Request A Demo
                </Link>
              </div>

              {/* Our Vision */}
              <div className="group ">
                <Link
                  to="/ourVision"
                  className={`text-black-300  hover:text-black px-3 py-5 rounded-md text-md font-semibold ${
                    location.pathname === "/ourVision" ? "underline" : ""
                  }`}
                >
                  Our Vision
                </Link>
                
              </div>

              {/* About */}

              <div className="group">
                <Link
                  to="/about"
                  className={`text-black-300  hover:text-black px-3 py-2  rounded-3xl  text-md font-semibold ${
                    location.pathname === "/about" ? "underline" : ""
                  }`}
                  o
                >
                  About
                </Link>
                
              </div>

              <div>
                <Link
                  to="/contact"
                  className={`text-black-300  h-5  hover:text-black px-3 py-2  rounded-3xl text-md font-semibold ${
                    location.pathname === "/contact" ? "underline" : ""
                  }`}
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Responsive Navbar */}
        {isOpen && (
          <div className="md:hidden flex flex-col space-y-2 mt-4">
            <Link
              to="/services"
              className="text-black-300 hover:bg-gray-100 hover:text-black px-3 py-1 rounded-md text-md font-semibold"
              onClick={handleLinkClick}
            >
              Solutions & Capabilities
            </Link>
            <Link
              to="/partners"
              className="text-black-300 hover:bg-gray-100 hover:text-black px-3 py-1 rounded-md text-md font-semibold"
              onClick={handleLinkClick}
            >
              Products & Partners
            </Link>

            <Link
              to="/requestADemo"
              className="text-black-300 hover:bg-gray-100 hover:text-black px-3 py-1 rounded-md text-md font-semibold"
              onClick={handleLinkClick}
            >
              Request a Demo
            </Link>
            <Link
              to="/ourVision"
              className="text-black-300 hover:bg-gray-100 hover:text-black px-3 py-1 rounded-md text-md font-semibold"
              onClick={handleLinkClick}
            >
              Our Vision
            </Link>
            <Link
              to="/about"
              className="text-black-300 hover:bg-gray-100 hover:text-black px-3 py-1 rounded-md text-md font-semibold"
              onClick={handleLinkClick}
            >
              About
            </Link>
            <Link
              to="/contact"
              className="text-black-300 hover:bg-gray-100 hover:text-black px-3 py-1 rounded-md text-md font-semibold"
              onClick={handleLinkClick}
            >
              Contact Us
            </Link>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
