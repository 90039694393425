import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faXTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className='bg-white p-4 mt-auto border-t-2'>
      <div className='container mx-auto flex flex-col md:flex-row justify-between items-center'>
        <div className='text-black text-center md:text-left'>
          &copy; {new Date().getFullYear()} Pro Data Solutions & Consultants
          LLC. All rights reserved.
        </div>
        <div className='flex space-x-4 mt-4 md:mt-0'>
          <a
            target='_blank'
            href='https://www.linkedin.com/company/prodatasolutions/'
            className='text-black hover:text-gray-400'
          >
            <FontAwesomeIcon icon={faFacebook} size='2x' />
          </a>
          <a
            target='_blank'
            href='https://www.linkedin.com/company/prodatasolutions/'
            className='text-black hover:text-gray-400'
          >
            <FontAwesomeIcon icon={faXTwitter} size='2x' />
          </a>
          <a
            target='_blank'
            href='https://www.linkedin.com/company/prodatasolutions/'
            className='text-black hover:text-gray-400'
          >
            <FontAwesomeIcon icon={faLinkedin} size='2x' />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
